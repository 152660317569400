import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";
import * as requestToApi from "./FishingCRUD";
import { fishingSlice } from "./FishingSlice";

const { actions } = fishingSlice;

export const getFishingPageUI = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchFishingPageUI(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.fishingPageUIFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchFishingPageUI");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const getFishingGameList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchFishingGameList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.fishingGameListFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchFishingGameList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const clearFishingGameList = () => (dispatch) => {
  dispatch(actions.clearFishingGameList());
};

export const playGame = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .companyPlayGame(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.playCompanyGame({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: companyPlayGame");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
