import { useEffect } from "react";
import * as actions from "../redux/SlotAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { GameCategoryConst } from "../../../constants/GameCategory";
import { useState } from "react";
import {
  CheckIsNull,
  ResolveArrayNull,
  toAbsoluteUrl,
} from "../../../utils/CommonHelper";
import { GameListing } from "../../common/components/GameListing";
import { useNavigate, useLocation } from "react-router-dom";
import { GameTypeEnum } from "../../../enumeration/GameTypeEnum";
import { useRef } from "react";

export const SlotListing = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [category, setCategory] = useState(GameCategoryConst.ALL);
  const location = useLocation();
  const [companyCode, setCompanyCode] = useState(
    location.state ? location.state.companyCode : undefined
  );
  const [companyOffset, setCompanyOffset] = useState(
    location.state ? parseInt(location.state.companyOffset) : 0
  );
  const navigate = useNavigate();
  const isInitial = useRef(true);

  const slotState = useSelector(({ slot }) => slot, shallowEqual);

  useEffect(() => {
    document.body.classList.add("pages-bg", "h-100", "scroll-y-default");
    document.querySelector("html").classList.add("h-100", "scroll-y-default");

    document.body.style.backgroundImage = `url("${toAbsoluteUrl(
      "images/pagesBg/slots.png"
    )}")`;

    dispatch(actions.getSlotPageUI());

    return () => {
      document.body.style.backgroundImage = ``;
      document.body.classList.remove("pages-bg", "h-100", "scroll-y-default");
      document
        .querySelector("html")
        .classList.remove("h-100", "scroll-y-default");
      dispatch(actions.clearSlotGameList());
    };
  }, []);

  useEffect(() => {
    if (slotState.slotPageUI) {
      const company = companyCode
        ? companyCode
        : slotState.slotPageUI.companyLists[0].companycode;

      if (!companyCode) {
        setCompanyCode(company);
      }
      const params = {
        companycode: company,
        language: intl.locale,
        category,
      };
      dispatch(actions.getSlotGameList(params)).then(() => {
        if (location.state && isInitial.current) {
          document.body.scrollTo({
            top: parseInt(location.state.scrollPosition),
            behavior: "smooth",
          });
        }
        isInitial.current = false;
      });
    }
  }, [slotState.slotPageUI, companyCode, category]);

  const handlePlayGame = (gameCode, searchName) => {
    navigate(
      `/gamePlay/${companyCode}/${gameCode}/${companyOffset}/${
        document.body.scrollTop
      }/${GameTypeEnum.SLOTS}${CheckIsNull(searchName) ? "" : `/${searchName}`}`
    );
  };

  return (
    <>
      <GameListing
        pageUI={slotState.slotPageUI}
        gameList={slotState.slotGameList?.slotGameLists}
        gameCategoryList={slotState.slotGameList?.slotGameCategory}
        setCategory={setCategory}
        category={category}
        companyCode={companyCode}
        setCompanyCode={setCompanyCode}
        handlePlayGame={handlePlayGame}
        setCompanyOffset={setCompanyOffset}
        companyOffset={companyOffset}
        initialSearchName={location.state ? location.state.searchName : ""}
        isShowBtn={
          ResolveArrayNull(slotState.slotPageUI?.companyLists).length > 6
        }
      />
    </>
  );
};
