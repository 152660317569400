import { useEffect, useState } from "react";
import { useAuth } from "../../auth/components/AuthInit";
import {
  SettingNavConst,
  SettingNavTitle,
} from "../../../constants/SettingNavConst";
import { useIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../utils/CommonHelper";
import { useNavigate } from "react-router-dom";
import { Profile } from "./profile/Profile";
import { GameTransfer } from "./gameTransfer/GameTransfer";
import { Withdraw } from "./withdraw/Withdraw";
import { TransactionReport } from "./transactionReport/TransactionReport";
import { MemberTransfer } from "./memberTransfer/MemberTransfer";
import { PointShop } from "./pointShop/PointShop";
import { ExchangeCurrency } from "./exchange/ExchangeCurrency";
import { Bonus } from "./bonus/Bonus";
import { Deposit } from "./deposit/Deposit";
import { GameReport } from "./gameReport/GameReport";
import { TermsAndCondition } from "../../common/components/TermsAndCondition";
import { Downline } from "./downline/Downline";
import { useDispatch } from "react-redux";
import * as actions from "../redux/SettingAction";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import SweetAlert2 from "react-sweetalert2";
import CryptoJS from "crypto-js";

export const Setting = ({ selectedTab }) => {
  const { auth, logout } = useAuth();
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState({});

  useEffect(() => {
    document.body.classList.add("pages-bg", "h-100", "scroll-y-default");
    document.querySelector("html").classList.add("h-100", "scroll-y-default");

    document.body.style.backgroundImage = `url("${toAbsoluteUrl(
      "images/pagesBg/setting.png"
    )}")`;

    return () => {
      document.body.classList.remove("pages-bg", "h-100", "scroll-y-default");
      document.body.style.backgroundImage = ``;
      document
        .querySelector("html")
        .classList.remove("h-100", "scroll-y-default");
    };
  }, []);

  const encryptData = (originalData) => {
    return CryptoJS.AES.encrypt(originalData, "279rc#!@279rc").toString();
  };

  const fetchAgent = () => {
    dispatch(actions.getAgentUsename()).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        window.open(
          `https://agent.279rc.com/?credential=${encodeURIComponent(
            btoa(encryptData(JSON.stringify(resp)))
          )}`
        );
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  return (
    <div className="d-flex container py-4">
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div style={{ width: "25%" }}>
        <div className="card">
          <div className="card-body pb-4">
            <div className="pb-2 mb-4 border-bottom text-center">
              {auth.username}
            </div>
            {auth.bindagent === "true" && (
              <div
                className="d-flex align-items-center cursor-pointer mb-4 menu-item"
                onClick={() => fetchAgent()}
              >
                <span
                  className="menu-icon main-menu-image"
                  style={{
                    WebkitMaskImage: `url(${toAbsoluteUrl(
                      "images/setting/agent.png"
                    )})`,
                    maskImage: `url(${toAbsoluteUrl(
                      "images/setting/agent.png"
                    )})`,
                  }}
                >
                  <div />
                </span>
                <div className="ms-2 ws-nowrap">
                  {intl.formatMessage({ id: "redirectToAgent" })}
                </div>
              </div>
            )}
            {SettingNavConst.map((nav, index) => (
              <div
                className={`d-flex align-items-center cursor-pointer mb-4 menu-item ${
                  selectedTab === nav.title ? "show" : ""
                }`}
                onClick={() => {
                  navigate(nav.navigate);
                }}
                key={nav.title}
              >
                <span
                  className="menu-icon main-menu-image"
                  style={{
                    WebkitMaskImage: `url(${toAbsoluteUrl(nav.image)})`,
                    maskImage: `url(${toAbsoluteUrl(nav.image)})`,
                  }}
                >
                  <div />
                </span>
                <div className="ms-2 ws-nowrap">
                  {intl.formatMessage({ id: nav.title })}
                </div>
              </div>
            ))}
            <button
              className="btn btn-danger w-100 mt-5"
              onClick={() => logout()}
            >
              {intl.formatMessage({ id: "logout" })}
            </button>
          </div>
        </div>
      </div>
      <div className="ps-4" style={{ width: "75%" }}>
        <div className="card">
          <div className="card-body">
            {selectedTab === SettingNavTitle.PROFILE ? (
              <Profile />
            ) : selectedTab === SettingNavTitle.TRANSFER ? (
              <GameTransfer />
            ) : selectedTab === SettingNavTitle.WITHDRAW ? (
              <Withdraw />
            ) : selectedTab === SettingNavTitle.TRANSACTION_REPORT ? (
              <TransactionReport />
            ) : selectedTab === SettingNavTitle.MEMBER_TRANSFER ? (
              <MemberTransfer />
            ) : selectedTab === SettingNavTitle.POINT_SHOP ? (
              <PointShop />
            ) : selectedTab === SettingNavTitle.EXCHANGE ? (
              <ExchangeCurrency />
            ) : selectedTab === SettingNavTitle.BONUS ? (
              <Bonus />
            ) : selectedTab === SettingNavTitle.DEPOSIT ? (
              <Deposit />
            ) : selectedTab === SettingNavTitle.GAME_REPORT ? (
              <GameReport />
            ) : selectedTab === SettingNavTitle.DOWNLINE ? (
              <Downline />
            ) : selectedTab === SettingNavTitle.TERMSANDCONDITION ? (
              <>
                <h5 className="mb-4">
                  {intl.formatMessage({ id: "termsAndCondition" })}
                </h5>
                <TermsAndCondition />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
