import { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import * as actions from "../../../../redux/SettingAction";
import { CheckIsNull } from "../../../../../../utils/CommonHelper";
import Skeleton from "react-loading-skeleton";
import { DepositGatewayForm } from "./DepositGatewayForm";
import { ApiStatusTypeEnum } from "../../../../../../enumeration/ApiStatusTypeEnum";

export const DepositGatewayDialog = ({
  setSwalProps,
  companyCode,
  dismissBtnRef,
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const receiptRef = useRef();
  const intl = useIntl();

  const [depositToLastGame, setDepositToLastGame] = useState(false);
  const [selectedGateway, setSelectedGateway] = useState(0);
  const [selectedBank, setSelectedBank] = useState(0);

  const settingState = useSelector(({ setting }) => setting, shallowEqual);

  useEffect(() => {
    dispatch(actions.getGatewayDepositUI());
    dispatch(actions.getDepositWalletUI()).then((resp) => {
      resp = resp.data;
      setDepositToLastGame(!CheckIsNull(resp.companycode));
    });
  }, []);

  const saveValues = (queryParams) => {
    const pickedGateway =
      settingState.depositGatewayUI.gateWayLists[selectedGateway];

    const newQueryParams = {
      gatewaycode: pickedGateway.gatewaycode,
      code: pickedGateway.gateWaySupport[selectedBank].code,
      type: pickedGateway.gateWaySupport[selectedBank].type,
      amount: queryParams.amount,
      platform: "pc",
      companycode: !CheckIsNull(companyCode)
        ? companyCode
        : depositToLastGame &&
          !CheckIsNull(settingState.depositWalletUI.companycode)
        ? settingState.depositWalletUI.companycode
        : "",
    };

    dispatch(actions.submitGatewayDepositRequest(newQueryParams)).then(
      (resp) => {
        resp = resp.data;

        if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
          window.open(resp.paymentlink);
          formikRef.current.resetForm();
          if (dismissBtnRef) {
            dismissBtnRef.current.click();
          }
        } else {
          setSwalProps({
            show: true,
            icon: "error",
            title: intl.formatMessage({ id: "error" }),
            text: resp.message,
          });
        }
      }
    );
  };

  return (
    <>
      {settingState.depositGatewayUI && settingState.depositWalletUI ? (
        <>
          <div className="text-input-label mb-2">
            {intl.formatMessage({ id: "gateway" })}
          </div>
          <div className="d-flex mb-2">
            {settingState.depositGatewayUI.gateWayLists.map(
              (gateway, index) => (
                <div
                  key={gateway.gatewaycode}
                  className="card cursor-pointer me-4"
                >
                  <div
                    className="card-body"
                    style={{ background: "rgba(0,0,0,0.2)" }}
                    onClick={() => {
                      setSelectedGateway(index);
                    }}
                  >
                    <div
                      className={`d-flex flex-column align-items-center menu-item ${
                        selectedGateway === index ? "show" : ""
                      }`}
                    >
                      <div className="ws-nowrap">{gateway.gatewayname}</div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
          <div className="text-input-label mb-2">
            {intl.formatMessage({ id: "chooseABank" })}
          </div>
          <div className="row">
            {settingState.depositGatewayUI.gateWayLists[
              selectedGateway
            ].gateWaySupport.map((bank, index) => (
              <div key={bank.code} className="col-md-3 mb-2">
                <div
                  className={`card cursor-pointer ${
                    selectedBank === index ? "bank-active" : ""
                  }`}
                >
                  <div
                    className="card-body"
                    style={{ background: "rgba(0,0,0,0.2)" }}
                    onClick={() => {
                      setSelectedBank(index);
                    }}
                  >
                    <div
                      className={`d-flex flex-column align-items-center menu-item ${
                        selectedBank === index ? "show fw-bold" : ""
                      }`}
                    >
                      <img
                        src={bank.imageurl}
                        alt={bank.name}
                        style={{ width: "120px" }}
                      />
                      <div
                        className="d-flex align-items-center justify-content-center small"
                        style={{ height: "42px" }}
                      >
                        <div className="text-center">{bank.name}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="my-4">
            <DepositGatewayForm
              formikRef={formikRef}
              receiptRef={receiptRef}
              saveValues={saveValues}
            />
          </div>
          {settingState.depositWalletUI &&
            CheckIsNull(companyCode) &&
            !CheckIsNull(settingState.depositWalletUI.content) && (
              <div className="form-check">
                <input
                  className="form-check-input"
                  id="depositWalletUI"
                  type="checkbox"
                  checked={depositToLastGame}
                  onChange={(e) => {
                    setDepositToLastGame(e.target.checked);
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="depositWalletUI"
                  dangerouslySetInnerHTML={{
                    __html: settingState.depositWalletUI.content.replace(
                      /\*(.*?)\*/g,
                      "<span class='text-danger fw-bold'>$1</span>"
                    ),
                  }}
                />
              </div>
            )}
          <button
            type="button"
            disabled={settingState.actionsLoading}
            className="btn btn-prim fw-bold px-3 w-100 mb-4 mt-2"
            onClick={() => formikRef.current.submitForm()}
          >
            <div className="d-flex align-items-center justify-content-center">
              {settingState.actionsLoading && (
                <div
                  className="spinner-border text-dark"
                  role="status"
                  style={{ scale: "0.75" }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
              {intl.formatMessage({ id: "submit" })}
            </div>
          </button>
        </>
      ) : (
        <Skeleton className="w-100" height="500px" />
      )}
    </>
  );
};
