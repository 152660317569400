import { useEffect, useRef } from "react";
import * as actions from "../redux/LiveCasinoAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { GameCategoryConst } from "../../../constants/GameCategory";
import { useState } from "react";
import {
  CheckIsNull,
  ResolveArrayNull,
  toAbsoluteUrl,
} from "../../../utils/CommonHelper";
import { GameListing } from "../../common/components/GameListing";
import { useNavigate, useLocation } from "react-router-dom";
import { GameTypeEnum } from "../../../enumeration/GameTypeEnum";

export const LiveCasinoListing = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [category, setCategory] = useState(GameCategoryConst.ALL);
  const location = useLocation();
  const [companyCode, setCompanyCode] = useState(
    location.state ? location.state.companyCode : undefined
  );
  const [companyOffset, setCompanyOffset] = useState(
    location.state ? parseInt(location.state.companyOffset) : 0
  );
  const isInitial = useRef(true);
  const navigate = useNavigate();

  const liveCasinoState = useSelector(
    ({ liveCasino }) => liveCasino,
    shallowEqual
  );

  useEffect(() => {
    document.body.classList.add("pages-bg", "h-100", "scroll-y-default");
    document.querySelector("html").classList.add("h-100", "scroll-y-default");

    document.body.style.backgroundImage = `url("${toAbsoluteUrl(
      "images/pagesBg/liveCasino.png"
    )}")`;

    dispatch(actions.getLiveCasinoPageUI());

    return () => {
      document.body.classList.remove("pages-bg", "h-100", "scroll-y-default");
      document.body.style.backgroundImage = ``;
      document
        .querySelector("html")
        .classList.remove("h-100", "scroll-y-default");
      dispatch(actions.clearLiveCasinoGameList());
    };
  }, []);

  useEffect(() => {
    if (liveCasinoState.liveCasinoPageUI) {
      const company = companyCode
        ? companyCode
        : liveCasinoState.liveCasinoPageUI.companyLists[0].companycode;

      if (!companyCode) {
        setCompanyCode(company);
      }

      const params = {
        companycode: company,
        language: intl.locale,
        category,
      };

      dispatch(actions.getLiveCasinoGameList(params)).then(() => {
        if (location.state && isInitial.current) {
          document.body.scrollTo({
            top: parseInt(location.state.scrollPosition),
            behavior: "smooth",
          });
        }
        isInitial.current = false;
      });
    }
  }, [liveCasinoState.liveCasinoPageUI, companyCode, category]);

  const handlePlayGame = (gameCode, searchName) => {
    navigate(
      `/gamePlay/${companyCode}/${gameCode}/${companyOffset}/${
        document.body.scrollTop
      }/${GameTypeEnum.LIVE}${CheckIsNull(searchName) ? "" : `/${searchName}`}`
    );
  };

  return (
    <>
      <GameListing
        pageUI={liveCasinoState.liveCasinoPageUI}
        gameList={liveCasinoState.liveCasinoGameList?.liveGameLists}
        gameCategoryList={liveCasinoState.liveCasinoGameList?.liveGameCategory}
        setCategory={setCategory}
        category={category}
        companyCode={companyCode}
        setCompanyCode={setCompanyCode}
        handlePlayGame={handlePlayGame}
        setCompanyOffset={setCompanyOffset}
        companyOffset={companyOffset}
        initialSearchName={location.state ? location.state.searchName : ""}
        isShowBtn={
          ResolveArrayNull(liveCasinoState.liveCasinoPageUI?.companyLists)
            .length > 6
        }
      />
    </>
  );
};
