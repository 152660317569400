import { useIntl } from "react-intl";
import { Modal } from "../../../common/components/Modal";
import { EditPasswordForm } from "./EditPasswordForm";
import { useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/AppGamesAction";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";

export const EditPasswordDialog = ({ setSwalProps, companyCode }) => {
  const intl = useIntl();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const appGamesState = useSelector(({ setting }) => setting, shallowEqual);

  const saveValues = (queryParams) => {
    const query = { ...queryParams, companycode: companyCode };

    dispatch(actions.editAppGamesID(query)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "changePasswordSuccess" }),
          onConfirm: () => {
            formikRef.current.resetForm();
            dismissBtnRef.current.click();
            dispatch(actions.getAppGamesPageUI());
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  return (
    <Modal
      modalId="passwordModal"
      title={intl.formatMessage({ id: "resetGamePassword" })}
      dismissBtnRef={dismissBtnRef}
    >
      <div className="p-3 custom-border-bottom">
        <EditPasswordForm formikRef={formikRef} saveValues={saveValues} />
      </div>
      <div className="p-3 d-flex justify-content-end align-items-center">
        {appGamesState.actionsLoading && (
          <div
            className="spinner-border me-2"
            role="status"
            style={{ scale: "0.55" }}
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
        <button
          type="button"
          disabled={appGamesState.actionsLoading}
          className="btn btn-prim fw-bold px-3"
          onClick={() => formikRef.current.submitForm()}
        >
          {intl.formatMessage({ id: "submit" })}
        </button>
      </div>
    </Modal>
  );
};
