export const HistoryStatusConst = {
  REQUEST: "request",
  ONPROGRESS: "onProgress",
  APPROVE: "approve",
  REJECT: "reject",
  CANCEL: "cancel",
};

export const HistoryPaymentGatewayStatusConst = {
  PENDING: "pending",
  APPROVE: "approve",
  REJECT: "reject",
};

export const HistoryPaymentTelcoStatusConst = {
  PENDING: "pending",
  APPROVE: "approve",
  REJECT: "reject",
};

export const HistoryStatusColorConst = {
  REQUEST: "#00FFFF",
  ONPROGRESS: "#FFAC1C",
  PENDING: "#FFAC1C",
  APPROVE: "#FFA500",
  SUCCESS: "#FFA500",
  REJECT: "#EE4B2B",
  FAIL: "#EE4B2B",
  CANCEL: "#880808",
};

export const historyDurationList = [1, 7, 15, 30];
