import { useState } from "react";
import { useIntl } from "react-intl";
import {
  HistoryStatusColorConst,
  HistoryStatusConst,
  historyDurationList,
} from "../../../../../constants/HistoryConst";
import * as actions from "../../../redux/SettingAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import * as masterActions from "../../../../common/redux/MasterLayoutAction";
import { ApiStatusTypeEnum } from "../../../../../enumeration/ApiStatusTypeEnum";

export const HistoryAtm = () => {
  const intl = useIntl();
  const [dayType, setDayType] = useState(1);
  const [status, setStatus] = useState(HistoryStatusConst.REQUEST);
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();

  const settingState = useSelector(({ setting }) => setting, shallowEqual);

  useEffect(() => {
    dispatch(actions.getAtmDepositList({ days: dayType }));
  }, [dayType]);

  const cancelAtmDepositRequest = (itemid) => {
    setSwalProps({
      show: true,
      icon: "info",
      title: intl.formatMessage({ id: "alert" }),
      text: intl.formatMessage({
        id: "confirmationCancelDeposit",
      }),
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: intl.formatMessage({ id: "confirm" }),
      denyButtonText: intl.formatMessage({ id: "cancel" }),
      onConfirm: () => {
        dispatch(actions.cancelAtmDepositRequest({ selitemid: itemid })).then(
          (resp) => {
            resp = resp.data;

            if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
              setSwalProps({
                show: true,
                icon: "success",
                title: intl.formatMessage({ id: "success" }),
                text: intl.formatMessage({
                  id: "depositRequestCancelSuccessfully",
                }),
                onResolve: () => {
                  setSwalProps({});
                  dispatch(actions.getAtmDepositList({ days: dayType }));
                  dispatch(masterActions.getWalletInfo());
                },
              });
            } else {
              setSwalProps({
                show: true,
                icon: "error",
                title: intl.formatMessage({ id: "error" }),
                text: resp.message,
              });
            }
          }
        );
      },
    });
  };

  return (
    <>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div className="col-3">
        <select
          className="form-select"
          value={dayType}
          onChange={(e) => setDayType(e.target.value)}
        >
          {historyDurationList.map((day, index) => (
            <option key={index} value={day}>
              {day} {intl.formatMessage({ id: "days" })}
            </option>
          ))}
        </select>
        <label className="mb-4 ms-1 small text-muted">
          {intl.formatMessage({ id: "duration" })}
        </label>
      </div>
      <div className="d-flex mb-4">
        {Object.entries(HistoryStatusConst).map(([key, value]) => (
          <div
            className={`me-3 cursor-pointer switch px-3 rounded fw-bold py-2 custom-border ${
              status === value ? "active" : ""
            }`}
            onClick={() => setStatus(value)}
            key={key}
          >
            {intl.formatMessage({
              id: value === HistoryStatusConst.CANCEL ? "canceled" : value,
            })}
          </div>
        ))}
      </div>
      {settingState.depositAtmList ? (
        <>
          {settingState.listLoading ? (
            <div className="d-flex align-items-center justify-content-center py-5">
              <div
                className="spinner-border"
                role="status"
                style={{ scale: "1.2" }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div style={{ minHeight: "600px" }}>
              {settingState.depositAtmList.topupManualList.filter(
                (deposit) =>
                  deposit.status.toLowerCase() === status.toLowerCase()
              ).length > 0 ? (
                settingState.depositAtmList.topupManualList
                  .filter(
                    (deposit) =>
                      deposit.status.toLowerCase() === status.toLowerCase()
                  )
                  .map((deposit, index) => (
                    <div className="card mb-2" key={index}>
                      <div
                        className="card-body rounded"
                        style={{
                          background: "rgba(0,0,0,0.2)",
                        }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="fw-bold fs-5">
                            {deposit.topupamount}
                          </div>
                          <div
                            className={`py-1 px-2 rounded small fw-bold text-dark`}
                            style={{
                              fontSize: "11px",
                              background:
                                HistoryStatusColorConst[deposit.status],
                            }}
                          >
                            {deposit.status}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            <div className="small">
                              {intl.formatMessage({ id: "bankName" })} :{" "}
                              {deposit.bankname}
                            </div>
                            <div className="small">
                              {intl.formatMessage({ id: "accountName" })} :{" "}
                              {deposit.accountname}
                            </div>
                            <div className="small">
                              {intl.formatMessage({ id: "accountNumber" })} :{" "}
                              {deposit.accountnum}
                            </div>
                            <div className="text-muted fst-italic small">
                              {intl.formatMessage({ id: "requestedDate" })} :{" "}
                              {deposit.requestdate}
                            </div>
                            {(deposit.status.toLowerCase() ===
                              HistoryStatusConst.APPROVE.toLowerCase() ||
                              deposit.status.toLowerCase() ===
                                HistoryStatusConst.REJECT.toLowerCase()) && (
                              <div className="text-muted fst-italic small">
                                {intl.formatMessage({ id: "respondDate" })} :{" "}
                                {deposit.responsedate}
                              </div>
                            )}
                          </div>
                          {deposit.status.toLowerCase() ===
                          HistoryStatusConst.REQUEST.toLowerCase() ? (
                            <div className="align-self-end">
                              <button
                                className="btn btn-danger cursor-pointer text-dark px-4 py-1 fw-bold mt-2"
                                style={{ fontSize: "13px" }}
                                onClick={() =>
                                  cancelAtmDepositRequest(deposit.itemid)
                                }
                              >
                                {intl.formatMessage({ id: "cancel" })}
                              </button>
                            </div>
                          ) : deposit.status.toLowerCase() ===
                            HistoryStatusConst.APPROVE.toLowerCase() ? (
                            <div className="align-self-end">
                              <div
                                className="position-relative cursor-pointer custom-border rounded px-2 me-1"
                                onClick={() => {
                                  setSwalProps({
                                    show: true,
                                    showCloseButton: true,
                                    showConfirmButton: false,
                                    html: `<div><h4 class='d-flex align-items-center justify-content-start mb-4'>${intl.formatMessage(
                                      {
                                        id: "prove",
                                      }
                                    )}</h4><img src='${
                                      deposit.receiptimage
                                    }' class="w-100"/></div>`,
                                  });
                                }}
                              >
                                <div
                                  className="fw-bold position-absolute top-0"
                                  style={{ fontSize: "13px" }}
                                >
                                  {intl.formatMessage({ id: "prove" })}
                                </div>
                                <i
                                  className="bi bi-image fs-1 p-0"
                                  title={intl.formatMessage({ id: "prove" })}
                                />
                              </div>
                            </div>
                          ) : deposit.status.toLowerCase() ===
                            HistoryStatusConst.REJECT.toLowerCase() ? (
                            <div className="col-6 d-flex flex-column jusitfy-content-center align-items-end align-self-end">
                              <div className="text-muted small">
                                {intl.formatMessage({ id: "rejectReason" })}
                              </div>
                              <div>{deposit.message}</div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ))
              ) : (
                <div className="d-flex py-5 align-items-center justify-content-center">
                  <div className="text-muted d-flex align-items-center justify-content-center flex-column">
                    <i className="bi bi-search fs-1 mb-3" />
                    <div>{intl.formatMessage({ id: "noRecordFound" })}</div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <Skeleton className="w-100" height="600px" />
      )}
    </>
  );
};
