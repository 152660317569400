import { useEffect } from "react";
import * as actions from "../redux/AppGamesAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  CheckIsNull,
  CopyToClipboard,
  ResolveArrayNull,
  toAbsoluteUrl,
} from "../../../utils/CommonHelper";
import { CompanyList } from "../../common/components/GameListing";
import Skeleton from "react-loading-skeleton";
import { useIntl } from "react-intl";
import { Overlay } from "../../common/components/Overlay";
import SweetAlert2 from "react-sweetalert2";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { EditPasswordDialog } from "./editPassword/EditPasswordDialog";

export const AppGamesListing = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [companyCode, setCompanyCode] = useState();
  const [offset, setOffset] = useState(0);
  const [swalProps, setSwalProps] = useState({});

  const appGamesState = useSelector(({ appGames }) => appGames, shallowEqual);

  useEffect(() => {
    document.body.classList.add("pages-bg", "h-100", "scroll-y-default");
    document.querySelector("html").classList.add("h-100", "scroll-y-default");

    document.body.style.backgroundImage = `url("${toAbsoluteUrl(
      "images/pagesBg/appGames.png"
    )}")`;

    dispatch(actions.getAppGamesPageUI());

    return () => {
      document.body.classList.remove("pages-bg", "h-100", "scroll-y-default");
      document.body.style.backgroundImage = ``;
      document
        .querySelector("html")
        .classList.remove("h-100", "scroll-y-default");
    };
  }, []);

  useEffect(() => {
    if (appGamesState.appGamesPageUI) {
      const [firstCompany] = appGamesState.appGamesPageUI.appcompanyLists;

      setCompanyCode(firstCompany.companycode);
    }
  }, [appGamesState.appGamesPageUI]);

  const createGameId = () => {
    const queryParams = { companycode: companyCode };

    dispatch(actions.addAppGamesID(queryParams)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "gameIdCreateSuccessfully" }),
          onResolve: () => {
            setSwalProps({});
            dispatch(actions.getAppGamesPageUI());
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  return (
    <div className="container py-4 game-list">
      {appGamesState.actionsLoading && <Overlay />}
      <SweetAlert2
        customClass="custom-swal"
        backdrop={false}
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      {swalProps.show && <Overlay zIndex={1} />}
      <EditPasswordDialog
        companyCode={companyCode}
        setSwalProps={setSwalProps}
      />
      <CompanyList
        companyList={appGamesState.appGamesPageUI?.appcompanyLists}
        setCompanyCode={setCompanyCode}
        offset={offset}
        companyCode={companyCode}
        setOffset={setOffset}
        isShowBtn={
          ResolveArrayNull(appGamesState.appGamesPageUI?.appcompanyLists)
            .length > 6
        }
      />
      {appGamesState.appGamesPageUI && companyCode ? (
        appGamesState.appGamesPageUI.appcompanyLists
          .filter((company) => company.companycode === companyCode)
          .map((company, index) => (
            <div className="card" key={index}>
              <div className="card-body">
                <div className="d-flex align-items-stretch">
                  <div className="col-5">
                    <div className="card h-100">
                      <div
                        className="card-body h-100 rounded d-flex flex-column"
                        style={{ background: "#2f2f2f" }}
                      >
                        <div className="d-flex justify-content-center w-100">
                          <img
                            alt={companyCode}
                            src={company.applogourl}
                            style={{ width: "100px" }}
                          />
                        </div>
                        {CheckIsNull(company.gameusername) ? (
                          <div className="text-center">
                            <div className="mb-5">
                              {intl.formatMessage({
                                id: "createGameIdDescription",
                              })}
                            </div>
                            <button
                              type="button"
                              className="btn btn-prim-light px-4 fs-3"
                              onClick={() => createGameId()}
                            >
                              {intl.formatMessage({ id: "createGameId" })}
                            </button>
                          </div>
                        ) : (
                          <>
                            <div className="mb-4">
                              <label className="mb-2 text-input-label">
                                {intl.formatMessage({ id: "username" })}
                              </label>
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  readOnly={true}
                                  value={company.gameusername}
                                />
                                <i
                                  className="fa fa-copy position-absolute top-50 end-0 translate-middle cursor-pointer"
                                  onClick={() =>
                                    CopyToClipboard(
                                      company.gameusername,
                                      setSwalProps,
                                      intl
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="mb-4">
                              <label className="mb-2 text-input-label">
                                {intl.formatMessage({ id: "password" })}
                              </label>
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  readOnly={true}
                                  value={company.gamepassword}
                                />
                                <i
                                  className="fa fa-copy position-absolute top-50 end-0 translate-middle cursor-pointer"
                                  onClick={() =>
                                    CopyToClipboard(
                                      company.gamepassword,
                                      setSwalProps,
                                      intl
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <button
                              type="button"
                              className="btn btn-prim-light w-100 mt-4"
                              data-bs-toggle="modal"
                              data-bs-target="#passwordModal"
                            >
                              {intl.formatMessage({ id: "changePassword" })}
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-7 px-4">
                    <div className="text-center">
                      <img
                        alt={companyCode}
                        src={company.appimageurl}
                        style={{ height: "350px" }}
                      />
                    </div>
                    <a
                      href={company.appdownloadurl}
                      className="btn btn-prim-light w-100 mt-4"
                      target="_blank"
                    >
                      {intl.formatMessage({ id: "apkDownload" })}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))
      ) : (
        <Skeleton className="w-100" height="600px" />
      )}
    </div>
  );
};
