import { Field, Form, Formik } from "formik";
import { useIntl } from "react-intl";
import { Input } from "../../../../common/components/control/InputField";
import { addBankSchema } from "../../yupSchema/addBankSchema";
import { useState } from "react";
import { useEffect } from "react";
import { CheckIsNull } from "../../../../../utils/CommonHelper";

export const AddBankForm = ({ formikRef, saveValues, bankList, fullName }) => {
  const intl = useIntl();
  const [bankName, setBankName] = useState("");

  useEffect(() => {
    if (bankList.length > 0) {
      setBankName(bankList[0].bankname);
    }
  }, [bankList]);

  return (
    <Formik
      initialValues={{
        fullName: fullName,
        accountNum: "",
        otherBankName: "",
      }}
      innerRef={formikRef}
      validationSchema={addBankSchema(intl)}
      enableReinitialize={true}
      validate={(values, formikHelpers) => {
        const errors = { otherBankName: "" };
        if (bankName === "Other" && CheckIsNull(values.otherBankName)) {
          errors.otherBankName = intl.formatMessage({
            id: "otherBankNameRequired",
          });
          return errors;
        }
      }}
      onSubmit={(values, formikHelpers) => {
        const queryParams = {
          bankname: bankName === "Other" ? values.otherBankName : bankName,
          accountnum: values.accountNum,
          fullname: values.fullName,
        };

        saveValues(queryParams);
      }}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        touched,
        errors,
        setFieldError,
      }) => (
        <Form>
          <div className="mb-2">
            <label className="mb-2 text-input-label">
              {intl.formatMessage({ id: "bankName" })}
            </label>
            <select
              className="form-select"
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
            >
              {bankList.map((bank, index) => (
                <option key={index} value={bank.bankname}>
                  {bank.bankname}
                </option>
              ))}
            </select>
          </div>

          {bankName === "Other" && (
            <div className="mb-2">
              <Field
                name="otherBankName"
                component={Input}
                placeholder={intl.formatMessage({ id: "otherBankName" })}
                label={intl.formatMessage({ id: "otherBankName" })}
                autoComplete="off"
                type="text"
                isUpperCaseOnly={true}
              />
            </div>
          )}

          <div className="mb-2">
            <Field
              name="fullName"
              component={Input}
              placeholder={intl.formatMessage({ id: "fullName" })}
              label={intl.formatMessage({ id: "fullName" })}
              autoComplete="off"
              type="text"
              isUpperCaseOnly={true}
              readOnly={!CheckIsNull(fullName)}
            />
          </div>
          <div className="mb-2">
            <Field
              name="accountNum"
              component={Input}
              placeholder={intl.formatMessage({ id: "accountNumber" })}
              label={intl.formatMessage({ id: "accountNumber" })}
              autoComplete="off"
              type="number"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
