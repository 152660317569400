import * as Yup from "yup";

export const forgotPasswordStep1Schema = (intl, isPhone) => {
  const additionalVerification = !isPhone
    ? {
        email: Yup.string()
          .nullable(false)
          .required(intl.formatMessage({ id: "emailRequired" })),
      }
    : {};

  return Yup.object().shape({
    verifyCode: Yup.string()
      .nullable(false)
      .required(intl.formatMessage({ id: "verifyCodeRequired" })),
    ...additionalVerification,
  });
};

export const forgotPasswordStep3Schema = (intl) => {
  return Yup.object().shape({
    newPassword: Yup.string()
      .nullable(false)
      .required(intl.formatMessage({ id: "newPasswordRequired" })),
    confirmPassword: Yup.string()
      .nullable(false)
      .required(intl.formatMessage({ id: "confirmPasswordRequired" })),
  });
};
