/* eslint-disable jsx-a11y/anchor-is-valid */
import { Navigate, Route, Routes } from "react-router-dom";
import { createContext } from "react";
import { LotteryListing } from "../../modules/lottery/component/LotteryListing";
import { LotteryNavTitle } from "../../constants/LotteryNavConst";

const LotteryContext = createContext();

const LotteryWrapper = () => {
  const value = {};

  return (
    <LotteryContext.Provider value={value}>
      <Routes>
        <Route
          path="/result"
          element={<LotteryListing selectedTab={LotteryNavTitle.RESULT} />}
        />
        <Route
          path="/bet"
          element={<LotteryListing selectedTab={LotteryNavTitle.BET} />}
        />
        <Route
          path="/slip"
          element={<LotteryListing selectedTab={LotteryNavTitle.SLIP} />}
        />
        <Route
          path="/payout"
          element={<LotteryListing selectedTab={LotteryNavTitle.PAYOUT} />}
        />
        <Route
          path="/report"
          element={<LotteryListing selectedTab={LotteryNavTitle.REPORT} />}
        />
        <Route path="/*" element={<Navigate to="result" />} />
      </Routes>
    </LotteryContext.Provider>
  );
};

export { LotteryWrapper };
