import { registerLocale } from "react-datepicker";
import { useIntl } from "react-intl";
import { Navigate, Route, Routes } from "react-router-dom";
import { getDateLocale } from "../utils/DateLocale";
import { HomeWrapper } from "../pages/home/HomeWrapper";
import { MasterLayout } from "../modules/common/components/MasterLayout";
import { SlotWrapper } from "../pages/slot/SlotWrapper";
import { SettingWrapper } from "../pages/setting/SettingWrapper";
import { LiveCasinoWrapper } from "../pages/liveCasino/LiveCasinoWrapper";
import { FishingWrapper } from "../pages/fishing/FishingWrapper";
import { AppGamesWrapper } from "../pages/appGames/AppGamesWrapper";
import { GamePlayWrapper } from "../pages/gamePlay/GamePlayWrapper";
import { LotteryWrapper } from "../pages/lottery/LotteryWrapper";
import { PromotionWrapper } from "../pages/promotion/PromotionWrapper";
import { SportWrapper } from "../pages/sport/SportWrapper";
import { AppDownloadWrapper } from "../pages/appDownload/AppDownloadWrapper";

const PrivateRoutes = () => {
  const intl = useIntl();
  let locale = intl.locale;

  registerLocale(locale, getDateLocale(locale));

  return (
    <>
      <Routes>
        <Route element={<MasterLayout />}>
          <Route path="home/*" element={<HomeWrapper />} />
          <Route path="slot/*" element={<SlotWrapper />} />
          <Route path="liveCasino/*" element={<LiveCasinoWrapper />} />
          <Route path="fishing/*" element={<FishingWrapper />} />
          <Route path="appsGame/*" element={<AppGamesWrapper />} />
          <Route path="setting/*" element={<SettingWrapper />} />
          <Route path="gamePlay/*" element={<GamePlayWrapper />} />
          <Route path="lottery/*" element={<LotteryWrapper />} />
          <Route path="promotion/*" element={<PromotionWrapper />} />
          <Route path="sport/*" element={<SportWrapper />} />
          <Route path="appDownload/*" element={<AppDownloadWrapper />} />
          <Route path="*" element={<Navigate to={"/home"} />} />
        </Route>
      </Routes>
    </>
  );
};

export { PrivateRoutes };
