import { useState } from "react";
import { useIntl } from "react-intl";
import {
  PaymentMethod,
  PaymentMethodNavConst,
} from "../../../../../constants/DepositNavConst";
import { toAbsoluteUrl } from "../../../../../utils/CommonHelper";
import { HistoryAtm } from "./HistoryAtm";
import { HistoryPaymentGateway } from "./HistoryPaymentGateway";
import { HistoryPaymentTelco } from "./HistoryPaymentTelco";

export const DepositHistory = () => {
  const intl = useIntl();
  const [paymentMethod, setPaymentMethod] = useState(PaymentMethod.ATM);

  return (
    <>
      <div className="d-flex mb-3 custom-border-bottom pb-3">
        {PaymentMethodNavConst.map((nav, index) => (
          <div key={nav.title} className="card cursor-pointer me-4">
            <div
              className="card-body"
              style={{ background: "rgba(0,0,0,0.2)" }}
              onClick={() => {
                setPaymentMethod(nav.title);
              }}
            >
              <div
                className={`d-flex align-items-center menu-item ${
                  paymentMethod === nav.title ? "show" : ""
                }`}
              >
                {nav.title === PaymentMethod.TELCO ? (
                  <img
                    src={toAbsoluteUrl(nav.image)}
                    style={{ width: "40px", height: "40px" }}
                  />
                ) : (
                  <span
                    className="menu-icon main-menu-image"
                    style={{
                      WebkitMaskImage: `url(${toAbsoluteUrl(nav.image)})`,
                      maskImage: `url(${toAbsoluteUrl(nav.image)})`,
                    }}
                  >
                    <div className="lg" />
                  </span>
                )}
                <div className="ms-2 ws-nowrap">
                  {intl.formatMessage({ id: nav.title })}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {paymentMethod === PaymentMethod.ATM ? (
        <HistoryAtm />
      ) : paymentMethod === PaymentMethod.TELCO ? (
        <HistoryPaymentTelco />
      ) : (
        <HistoryPaymentGateway />
      )}
    </>
  );
};
