import { Field, Form, Formik } from "formik";
import { switchCurrencySchema } from "../../yupSchema/switchCurrencySchema";
import { useIntl } from "react-intl";
import { Input } from "../../../../common/components/control/InputField";
import { IsAlphaNumeric } from "../../../../../utils/CommonHelper";

export const ChangeRegionForm = ({ formikRef, saveValues, region }) => {
  const intl = useIntl();

  return (
    <Formik
      initialValues={{
        confirmPassword: "",
      }}
      innerRef={formikRef}
      validationSchema={switchCurrencySchema(intl)}
      enableReinitialize={true}
      onSubmit={(values, formikHelpers) => {
        let queryParams = {
          password: values.confirmPassword,
          selcurrencycode: region,
        };
        saveValues(queryParams);
      }}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        touched,
        errors,
        setFieldError,
      }) => (
        <Form>
          <div className="mb-2">
            <Field
              name="confirmPassword"
              component={Input}
              placeholder={intl.formatMessage({ id: "password" })}
              label={intl.formatMessage({ id: "password" })}
              autoComplete="off"
              type="password"
              onKeyDown={(e) => {
                if (!IsAlphaNumeric(e)) {
                  e.preventDefault();
                }
              }}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
