import { useEffect, useState } from "react";
import * as actions from "../../redux/SettingAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { useAuth } from "../../../auth/components/AuthInit";
import QRCode from "react-qr-code";
import { LanguageDDLConst } from "../../../../constants/LanguageLocale";
import {
  CheckIsNull,
  CopyToClipboard,
  toAbsoluteUrl,
} from "../../../../utils/CommonHelper";
import { EditPasswordDialog } from "./editPassword/EditPasswordDialog";
import SweetAlert2 from "react-sweetalert2";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";
import { Overlay } from "../../../common/components/Overlay";
import { AddBankDialog } from "./addBank/AddBankDialog";
import Marquee from "react-fast-marquee";
import { ChangeRegionDialog } from "./changeRegion/ChangeRegionDialog";
import { setLanguage } from "../../../../i18n/Metronici18n";

export const Profile = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const settingState = useSelector(({ setting }) => setting, shallowEqual);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [editCurrency, setEditCurrency] = useState();
  const [isFetchingCurrency, setIsFetchingCurrency] = useState(true);
  const [currencyLists, setCurrencyLists] = useState([]);
  const [isExpand, setIsExpand] = useState(true);
  const [swalProps, setSwalProps] = useState({});
  const { auth, saveAuth } = useAuth();

  useEffect(() => {
    dispatch(actions.getProfile());
    dispatch(actions.getCountryBankList());
    dispatch(actions.getCurrencyList({ language: intl.locale })).then(
      (resp) => {
        resp = resp.data;
        setCurrencyLists(resp.currencyLists);
        const currencyCode = resp.currencyLists.find(
          (currency) => currency.currencycode === auth.currencycode
        );
        if (currencyCode) {
          setSelectedCurrency(currencyCode);
          setEditCurrency(currencyCode);
        } else {
          setSelectedCurrency(resp.currencyLists[0]);
          setEditCurrency(resp.currencyLists[0]);
        }
        setIsFetchingCurrency(false);
      }
    );
  }, []);

  useEffect(() => {
    setSelectedLanguage(
      LanguageDDLConst.find((language) => language.language === auth.language)
    );
  }, [auth]);

  useEffect(() => {
    if (selectedLanguage && selectedLanguage.language !== auth.language) {
      const queryParams = {
        language: selectedLanguage.language,
      };

      dispatch(actions.updateLanguage(queryParams)).then((resp) => {
        resp = resp.data;
        if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
          setSwalProps({
            show: true,
            icon: "success",
            title: intl.formatMessage({ id: "success" }),
            text: intl.formatMessage({ id: "changeLanguageSuccess" }),
            onResolve: () => {
              setSwalProps({});
              setLanguage(selectedLanguage.language);
              dispatch(actions.getProfile());
              saveAuth({ ...auth, language: selectedLanguage.language });
            },
          });
        } else {
          setSwalProps({
            show: true,
            icon: "error",
            title: intl.formatMessage({ id: "error" }),
            text: resp.message,
            onResolve: () => {
              setSwalProps({});
              setSelectedLanguage(
                LanguageDDLConst.filter(
                  (language) =>
                    language.language === settingState.profile.language
                )[0]
              );
            },
          });
        }
      });
    }
  }, [selectedLanguage]);

  const NewBankCard = () => (
    <div
      className="col-3 me-4 custom-border rounded px-3 py-4 cursor-pointer"
      style={{ background: "rgba(255,255,255,0.02)" }}
      data-bs-toggle="modal"
      data-bs-target="#addBankModal"
    >
      <div className="d-flex justify-content-center align-items-center mb-3">
        <i className="bi bi-plus-circle fs-4 me-3 mb-2 text-muted" />
        <div className="fs-5">{intl.formatMessage({ id: "addBank" })}</div>
      </div>
      <div className="text-center smaller text-muted">
        {intl.formatMessage({ id: "addBankDescription" })}
      </div>
    </div>
  );

  const handleRemoveBank = (itemid) => {
    const queryParams = {
      itemid,
    };
    dispatch(actions.deleteBankAccount(queryParams)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "deleteBankSuccessfully" }),
          onResolve: () => {
            setSwalProps({});
            dispatch(actions.getProfile());
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  const BankCard = ({ bankAccount }) => (
    <div
      className="col-3 me-4 custom-border rounded px-3 py-4"
      style={{ background: "rgba(255,255,255,0.02)" }}
    >
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div className="fw-bold text-truncate" title={bankAccount.bankname}>
          {bankAccount.bankname}
        </div>
        <div>
          <i
            className="bi bi-trash text-danger cursor-pointer"
            onClick={() => {
              setSwalProps({
                show: true,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: intl.formatMessage({ id: "confirm" }),
                denyButtonText: intl.formatMessage({ id: "cancel" }),
                icon: "info",
                title: intl.formatMessage({ id: "alert" }),
                text: intl.formatMessage({ id: "confirmRemoveBank" }),
                onConfirm: () => {
                  setSwalProps({});
                  handleRemoveBank(bankAccount.itemid);
                },
              });
            }}
          />
        </div>
      </div>
      <div className="text-truncate">{bankAccount.accountnum}</div>
    </div>
  );

  const NewBankCardList = () => {
    if (settingState.profile) {
      let indents = [];
      for (
        var i = 0;
        i < 3 - settingState.profile.bankAccountLists.length;
        i++
      ) {
        indents.push(<NewBankCard key={i} />);
      }
      return <>{indents}</>;
    } else {
      return <></>;
    }
  };

  return (
    <>
      <SweetAlert2
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
        customClass="custom-swal"
      />
      <ChangeRegionDialog
        setSwalProps={setSwalProps}
        region={editCurrency ? editCurrency.currencycode : ""}
      />
      <div className="d-flex mb-4 align-items-center justify-content-between">
        <h5>{intl.formatMessage({ id: "wallet" })}</h5>
        <div className="col-3">
          {!isFetchingCurrency ? (
            <>
              <div className="dropdown">
                <button
                  className={`form-control text-start d-flex align-items-center ${
                    selectedCurrency ? "" : "text-placeholder"
                  }`}
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-text={intl.formatMessage({ id: "currency" })}
                >
                  {selectedCurrency ? (
                    <>
                      <img
                        src={selectedCurrency.imageurl}
                        alt={selectedCurrency.currencycode}
                        className="me-4 currency-icon"
                      />
                      <div>{selectedCurrency.currencyname}</div>
                    </>
                  ) : (
                    intl.formatMessage({ id: "currency" })
                  )}
                </button>
                <div className="dropdown-menu w-100">
                  {currencyLists
                    .filter(
                      (currency) => currency.currencycode !== auth.currencycode
                    )
                    .map((currency) => (
                      <div
                        className={`dropdown-item d-flex align-items-center py-2 cursor-pointer ${
                          selectedCurrency &&
                          selectedCurrency.currencycode ===
                            currency.currencycode
                            ? "active"
                            : ""
                        }`}
                        data-bs-toggle="modal"
                        data-bs-target="#regionModal"
                        key={currency.currencycode}
                        onClick={() => {
                          setEditCurrency(currency);
                          document
                            .querySelector(".dropdown-menu")
                            .classList.remove("show");
                        }}
                      >
                        <img
                          src={currency.imageurl}
                          alt={currency.currencycode}
                          className="me-4 currency-icon"
                        />
                        <div>{currency.currencyname}</div>
                      </div>
                    ))}
                </div>
              </div>
            </>
          ) : (
            <Skeleton className="w-100" height="35px" />
          )}
        </div>
      </div>
      {settingState.actionsLoading && <Overlay />}
      {settingState.profile ? (
        <>
          <EditPasswordDialog setSwalProps={setSwalProps} />
          <AddBankDialog
            setSwalProps={setSwalProps}
            fullName={settingState.profile.fullname}
          />
          <div className="d-flex">
            <div className="col-4 d-flex justify-content-start">
              <div className="col-11 rounded custom-border p-3 text-start">
                <div className="mb-3 text-muted">
                  {intl.formatMessage({ id: "balance" })}
                </div>
                <div>
                  {auth.currencycode} {settingState.profile.balance}
                </div>
              </div>
            </div>
            <div className="col-4 d-flex justify-content-center">
              <div className="col-11 rounded custom-border p-3 text-start">
                <div className="mb-3 text-muted">
                  {intl.formatMessage({ id: "point" })}
                </div>
                <div>{settingState.profile.points}</div>
              </div>
            </div>
            <div className="col-4 d-flex justify-content-end">
              <div className="col-11 rounded custom-border p-3 text-start">
                <div className="mb-3 text-muted">
                  {intl.formatMessage({ id: "bonus" })}
                </div>
                <div>{settingState.profile.bonus}</div>
              </div>
            </div>
          </div>

          <h5 className="my-4">{intl.formatMessage({ id: "information" })}</h5>
          <div className="d-flex mb-4">
            <div className="col-6 pe-3">
              <div className="w-100 rounded custom-border p-3 text-start">
                <div className="mb-3 text-muted">
                  {intl.formatMessage({ id: "username" })}
                </div>
                <div>{settingState.profile.username}</div>
              </div>
            </div>
            <div className="col-6 ps-3">
              <div className="w-100 rounded custom-border p-3 text-start">
                <div className="mb-3 text-muted">
                  {intl.formatMessage({
                    id:
                      settingState.profile.registerby === "telno"
                        ? "phoneNo"
                        : "email",
                  })}
                </div>
                <div>{settingState.profile.telnoemail}</div>
              </div>
            </div>
          </div>
          <div className="d-flex mb-4">
            <div className="col-6 pe-3">
              <div className="w-100 rounded custom-border p-3 text-start">
                <div className="d-flex justify-content-between mb-3 align-items-center">
                  <div className="text-muted">
                    {intl.formatMessage({ id: "password" })}
                  </div>
                  <i
                    className="bi bi-pen cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#passwordModal"
                  />
                </div>
                <div>*****</div>
              </div>
            </div>
            <div className="col-6 ps-3">
              <div className="w-100 rounded custom-border p-3 text-start">
                <div className="mb-3 text-muted">
                  {intl.formatMessage({
                    id: "registerDate",
                  })}
                </div>
                <div>{settingState.profile.registerdate}</div>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div className="col-6 pe-3">
              <div className="w-100 rounded custom-border d-flex justify-content-between align-items-center pe-3">
                <div className="p-3 col-9">
                  <div className="mb-3 text-muted">
                    {intl.formatMessage({
                      id: "referralCode",
                    })}
                  </div>
                  <div className="d-flex align-items-center pb-2 mb-1 custom-border-bottom">
                    <div>{settingState.profile.referralcode}</div>
                    <i
                      className="ms-2 fa fa-copy cursor-pointer"
                      onClick={() =>
                        CopyToClipboard(
                          settingState.profile.referralcode,
                          setSwalProps,
                          intl
                        )
                      }
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <Marquee direction="right" className="flex-fill">
                      <div className="pe-3">
                        https://web.279rc.com/register/
                        {settingState.profile.referralcode}
                      </div>
                    </Marquee>
                    <i
                      className="ms-2 fa fa-copy cursor-pointer"
                      onClick={() =>
                        CopyToClipboard(
                          `https://web.279rc.com/register/${settingState.profile.referralcode}`,
                          setSwalProps,
                          intl
                        )
                      }
                    />
                  </div>
                </div>
                <div>
                  <QRCode
                    value={settingState.profile.referralcode}
                    size={90}
                    bgColor="#1c1e23"
                    fgColor="#fff"
                  />
                </div>
              </div>
            </div>
          </div>
          <h5 className="my-4">{intl.formatMessage({ id: "language" })}</h5>
          <div className="dropdown">
            <button
              className={`form-control text-start d-flex align-items-center py-3 ${
                selectedLanguage ? "" : "text-placeholder"
              }`}
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-text={intl.formatMessage({ id: "language" })}
            >
              {selectedLanguage ? (
                <>
                  <img
                    src={toAbsoluteUrl(
                      `images/language/${selectedLanguage.language}.png`
                    )}
                    alt={intl.formatMessage({ id: selectedLanguage.title })}
                    className="me-4 language-icon"
                  />
                  <div>
                    {intl.formatMessage({ id: selectedLanguage.title })}
                  </div>
                </>
              ) : (
                intl.formatMessage({ id: "language" })
              )}
            </button>
            <div className="dropdown-menu w-100">
              {LanguageDDLConst.map((languageDDL) => (
                <div
                  className={`dropdown-item d-flex align-items-center py-2 cursor-pointer ${
                    selectedLanguage &&
                    selectedLanguage.language === languageDDL.language
                      ? "active"
                      : ""
                  }`}
                  key={languageDDL.language}
                  onClick={() => {
                    document
                      .querySelector(".dropdown-menu")
                      .classList.remove("show");
                    setSelectedLanguage(languageDDL);
                  }}
                >
                  <img
                    src={toAbsoluteUrl(
                      `images/language/${languageDDL.language}.png`
                    )}
                    alt={intl.formatMessage({ id: languageDDL.title })}
                    className="me-4 language-icon"
                  />
                  <div>{intl.formatMessage({ id: languageDDL.title })}</div>
                </div>
              ))}
            </div>
          </div>
          <h5
            className={`mt-4 ${
              CheckIsNull(settingState.profile.fullname) ? "mb-4" : "mb-3"
            } cursor-pointer d-flex align-items-center justify-content-between custom-border-bottom pb-3`}
            data-bs-toggle="collapse"
            data-bs-target="#collapseExample"
            aria-expanded="true"
            aria-controls="collapseExample"
            onClick={() => setIsExpand((prev) => !prev)}
          >
            <div className="d-flex align-items-end">
              <div>{intl.formatMessage({ id: "transferBankManagement" })}</div>
              <div className="ms-4 text-muted fs-6 fw-light">
                {intl.formatMessage({ id: "numberOfBankCards" })} (
                {settingState.profile.bankAccountLists.length}/3)
              </div>
            </div>
            <i className={`fa fa-angle-${isExpand ? "up" : "down"}`} />
          </h5>
          {!CheckIsNull(settingState.profile.fullname) && (
            <div className="mb-3 ps-2 d-flex">
              <div className="me-2 text-muted">
                {intl.formatMessage({ id: "fullNameBounded" })} :{" "}
              </div>
              <div className="fw-semibold">{settingState.profile.fullname}</div>
            </div>
          )}
          <div className="collapse show" id="collapseExample">
            <div className="d-flex">
              {settingState.profile.bankAccountLists.map(
                (bankAccount, index) => (
                  <BankCard key={index} bankAccount={bankAccount} />
                )
              )}
              <NewBankCardList />
            </div>
          </div>
        </>
      ) : (
        <Skeleton className="w-100" height="600px" />
      )}
    </>
  );
};
