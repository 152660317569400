import { useRef } from "react";
import { Modal } from "../../../../common/components/Modal";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/SettingAction";
import { ApiStatusTypeEnum } from "../../../../../enumeration/ApiStatusTypeEnum";
import { AddBankForm } from "./AddBankForm";
import { ResolveArrayNull } from "../../../../../utils/CommonHelper";

export const AddBankDialog = ({ setSwalProps, fullName }) => {
  const intl = useIntl();
  const dismissBtnRef = useRef();
  const formikRef = useRef();
  const settingState = useSelector(({ setting }) => setting, shallowEqual);

  const dispatch = useDispatch();

  const saveValues = (queryParams) => {
    dispatch(actions.insertBankAccount(queryParams)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "addBankSuccessfully" }),
          onConfirm: () => {
            formikRef.current.resetForm();
            dismissBtnRef.current.click();
            dispatch(actions.getProfile());
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  return (
    <Modal
      modalId="addBankModal"
      title={intl.formatMessage({ id: "addBank" })}
      dismissBtnRef={dismissBtnRef}
    >
      <div className="p-3 custom-border-bottom">
        <AddBankForm
          formikRef={formikRef}
          saveValues={saveValues}
          fullName={fullName}
          bankList={ResolveArrayNull(
            settingState.countryBankList?.countryBankLists
          )}
        />
      </div>
      <div className="p-3 d-flex justify-content-end align-items-center">
        {settingState.actionsLoading && (
          <div
            className="spinner-border me-2"
            role="status"
            style={{ scale: "0.55" }}
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
        <button
          type="button"
          disabled={settingState.actionsLoading}
          className="btn btn-prim fw-bold px-3"
          onClick={() => formikRef.current.submitForm()}
        >
          {intl.formatMessage({ id: "submit" })}
        </button>
      </div>
    </Modal>
  );
};
