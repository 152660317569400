import { createSlice } from "@reduxjs/toolkit";
import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";

const settingState = {
  profile: null,
  pointShops: null,
  manageResponse: null,
  multiWalletInfo: null,
  multiWalletRateList: null,
  currencyList: null,
  countryBankList: null,
  gameCompanyTransferUI: null,
  companyGameBalance: null,
  gameCompanyTransferMenu: null,
  transactionReport: null,
  withdrawUI: null,
  withdrawList: null,
  depositAtmUI: null,
  depositWalletUI: null,
  depositAtmList: null,
  depositGatewayUI: null,
  depositTelcoUI: null,
  depositGatewayList: null,
  depositTelcoList: null,
  bonusList: null,
  gameReport: null,
  downline: null,
  agentUsername: null,
  error: null,
  listLoading: false,
  actionsLoading: false,
  searchLoading: false,
  isRefresh: false,
};

export const settingSlice = createSlice({
  name: "setting",
  initialState: settingState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = false;
      }
    },
    throwError: (state, action) => {
      state.error = action.payload.data.error;
    },
    startCall: (state, action) => {
      state.isRefresh = false;
      state.error = null;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = true;
      }
    },
    clearError: (state) => {
      state.error = null;
    },
    profileFetched: (state, action) => {
      state.listLoading = false;
      state.profile = action.payload.data ?? {};
    },
    editPassword: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    editLanguage: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    currencyListFetched: (state, action) => {
      state.listLoading = false;
      state.currencyList = action.payload.data ?? {};
    },
    countryBankListFetched: (state, action) => {
      state.listLoading = false;
      state.countryBankList = action.payload.data ?? {};
    },
    createBankAccount: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    editCurrencyCode: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    deleteBankAccount: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    gameCompanyTransferUIFetched: (state, action) => {
      state.listLoading = false;
      state.gameCompanyTransferUI = action.payload.data ?? {};
    },
    gameBalanceToWalletTransfered: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    companyGameBalanceFetched: (state, action) => {
      state.searchLoading = false;
      state.companyGameBalance = action.payload.data ?? {};
    },
    companyGameDeposit: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    companyGameWithdraw: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    transactionReportFetched: (state, action) => {
      state.listLoading = false;
      state.transactionReport = action.payload.data ?? {};
    },
    gameReportFetched: (state, action) => {
      state.listLoading = false;
      state.gameReport = action.payload.data ?? {};
    },
    downlineFetched: (state, action) => {
      state.listLoading = false;
      state.downline = action.payload.data ?? {};
    },
    withdrawUIFetched: (state, action) => {
      state.listLoading = false;
      state.withdrawUI = action.payload.data ?? {};
    },
    withdrawListFetched: (state, action) => {
      state.listLoading = false;
      state.withdrawList = action.payload.data ?? {};
    },
    withdrawRequestSubmitted: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    withdrawRequestCancelled: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    depositAtmUIFetched: (state, action) => {
      state.listLoading = false;
      state.depositAtmUI = action.payload.data ?? {};
    },
    depositWalletUIFetched: (state, action) => {
      state.listLoading = false;
      state.depositWalletUI = action.payload.data ?? {};
    },
    depositAtmListFetched: (state, action) => {
      state.listLoading = false;
      state.depositAtmList = action.payload.data ?? {};
    },
    depositAtmRequestSubmitted: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    depositAtmRequestCancelled: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    depositGatewayUIFetched: (state, action) => {
      state.listLoading = false;
      state.depositGatewayUI = action.payload.data ?? {};
    },
    depositTelcoUIFetched: (state, action) => {
      state.listLoading = false;
      state.depositTelcoUI = action.payload.data ?? {};
    },
    depositGatewayListFetched: (state, action) => {
      state.listLoading = false;
      state.depositGatewayList = action.payload.data ?? {};
    },
    depositTelcoListFetched: (state, action) => {
      state.listLoading = false;
      state.depositTelcoList = action.payload.data ?? {};
    },
    depositGatewayRequestSubmitted: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    depositTelcoRequestSubmitted: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    memberTransfered: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    pointShopsFetched: (state, action) => {
      state.listLoading = false;
      state.pointShops = action.payload.data ?? {};
    },
    shopPointsToBalanceTransfered: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    bonusListFetched: (state, action) => {
      state.listLoading = false;
      state.bonusList = action.payload.data ?? {};
    },
    bonusToBalanceTransfered: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    multiWalletInfoFetched: (state, action) => {
      state.listLoading = false;
      state.multiWalletInfo = action.payload.data ?? {};
    },
    multiWalletRateListFetched: (state, action) => {
      state.listLoading = false;
      state.multiWalletRateList = action.payload.data ?? {};
    },
    currencyExchanged: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    agentUsernameFetched: (state, action) => {
      state.actionsLoading = false;
      state.agentUsername = action.payload.data ?? {};
    },
  },
});
