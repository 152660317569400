import { useEffect } from "react";
import * as actions from "../redux/SportAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { GameCategoryConst } from "../../../constants/GameCategory";
import { useState } from "react";
import {
  CheckIsNull,
  ResolveArrayNull,
  toAbsoluteUrl,
} from "../../../utils/CommonHelper";
import { CompanyList, GameListing } from "../../common/components/GameListing";
import { useNavigate, useLocation } from "react-router-dom";
import { GameTypeEnum } from "../../../enumeration/GameTypeEnum";
import { useRef } from "react";
import Skeleton from "react-loading-skeleton";

export const SportListing = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [category, setCategory] = useState(GameCategoryConst.ALL);
  const location = useLocation();
  const [companyCode, setCompanyCode] = useState(
    location.state ? location.state.companyCode : undefined
  );
  const [companyOffset, setCompanyOffset] = useState(
    location.state ? parseInt(location.state.companyOffset) : 0
  );
  const [game, setGame] = useState(null);
  const navigate = useNavigate();
  const isInitial = useRef(true);

  const sportState = useSelector(({ sport }) => sport, shallowEqual);

  useEffect(() => {
    document.body.classList.add("pages-bg", "h-100", "scroll-y-default");
    document.querySelector("html").classList.add("h-100", "scroll-y-default");

    document.body.style.backgroundImage = `url("${toAbsoluteUrl(
      "images/pagesBg/setting.png"
    )}")`;

    dispatch(actions.getSportPageUI());

    return () => {
      document.body.style.backgroundImage = ``;
      document.body.classList.remove("pages-bg", "h-100", "scroll-y-default");
      document
        .querySelector("html")
        .classList.remove("h-100", "scroll-y-default");
      dispatch(actions.clearSportGameList());
    };
  }, []);

  useEffect(() => {
    if (sportState.sportPageUI) {
      const company = companyCode
        ? companyCode
        : sportState.sportPageUI.companyLists[0].companycode;

      if (!companyCode) {
        setCompanyCode(company);
      }
      const params = {
        companycode: company,
        language: intl.locale,
        category,
      };
      dispatch(actions.getSportGameList(params)).then(() => {
        if (location.state && isInitial.current) {
          document.body.scrollTo({
            top: parseInt(location.state.scrollPosition),
            behavior: "smooth",
          });
        }
        isInitial.current = false;
      });
    }
  }, [sportState.sportPageUI, companyCode, category]);

  useEffect(() => {
    if (sportState.sportGameList) {
      setGame(sportState.sportGameList.sportGameLists[0]);
    }
  }, [sportState.sportGameList]);

  const handlePlayGame = (gameCode, searchName) => {
    navigate(
      `/gamePlay/${companyCode}/${gameCode}/${companyOffset}/${
        document.body.scrollTop
      }/${GameTypeEnum.SPORT}${CheckIsNull(searchName) ? "" : `/${searchName}`}`
    );
  };

  return (
    <>
      <div className="container py-4 game-list">
        <CompanyList
          companyList={sportState.sportPageUI?.companyLists}
          setCompanyCode={setCompanyCode}
          offset={companyOffset}
          companyCode={companyCode}
          setOffset={setCompanyOffset}
          isShowBtn={
            ResolveArrayNull(sportState.sportPageUI?.companyLists).length > 6
          }
        />
        {game ? (
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-stretch">
                <div className="col-5">
                  <div className="card h-100">
                    <div
                      className="card-body h-100 rounded d-flex flex-column"
                      style={{ background: "#2f2f2f" }}
                    >
                      <div className="d-flex justify-content-center w-100">
                        <img
                          alt={companyCode}
                          src={game.logoimageurl}
                          style={{ width: "100px" }}
                        />
                      </div>
                      <div className="text-center">
                        <div className="my-3 fw-bold fs-5">
                          {intl.formatMessage({
                            id: "sportsbookTitle",
                          })}
                        </div>
                        <div className="mb-5">
                          {intl.formatMessage({
                            id: `${companyCode}_description`,
                          })}
                        </div>
                        <button
                          type="button"
                          className="btn btn-prim-light px-4 fs-4 fw-bold w-100"
                          onClick={() => handlePlayGame(game.gamecode)}
                        >
                          {intl.formatMessage({ id: "playNow" }).toUpperCase()}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-7 px-4">
                  <div className="text-center">
                    <img
                      alt={companyCode}
                      src={game.bigimageurl}
                      style={{ height: "350px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Skeleton className="w-100" height="600px" />
        )}
      </div>
    </>
  );
};
