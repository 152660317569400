import { useEffect, useState } from "react";
import * as actions from "../redux/HomeAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Marquee from "react-fast-marquee";
import Skeleton from "react-loading-skeleton";
import { Slide } from "react-slideshow-image";
import { CheckIsNull, toAbsoluteUrl } from "../../../utils/CommonHelper";
import { useAuth } from "../../auth/components/AuthInit";
import { fetchIPAddress, fetchPromoUrl } from "../../auth/redux/LoginCRUD";
import { I18N_CONFIG_KEY } from "../../../i18n/Metronici18n";
import SweetAlert2 from "react-sweetalert2";

export const HomeListing = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});

  const { promoShow, setPromoShow } = useAuth();

  const homeState = useSelector(({ home }) => home, shallowEqual);

  useEffect(() => {
    document.body.style.backgroundImage = "";
    dispatch(actions.getHomePageUI());
    if (!promoShow) {
      fetchIPAddress().then((res) => {
        const queryParams = `openfrom=home&language=${
          localStorage.getItem(I18N_CONFIG_KEY) ? intl.locale : "en"
        }&platform=pc&ipaddress=${res.ip}`;

        fetchPromoUrl(queryParams).then((res) => {
          if (!CheckIsNull(res.promourl)) {
            setSwalProps({
              show: true,
              showCloseButton: true,
              showConfirmButton: false,
              closeButtonHtml: `<div><button class="btn btn-danger rounded-circle p-0 d-flex align-items-center justify-content-center" style="width:30px;height:30px;"><div style="padding-bottom:2px"><i class="bi bi-x fs-4"/></div></button></div>`,
              html: `<div style="height:720px" class="overflow-hidden"><iframe src="${res.promourl}" class="w-100 h-100 overflow-hidden" frameBorder="0" scrolling="no"/></div>`,
            });
          }
        });
      });
      setPromoShow(true);
    }
  }, []);

  const IntroductionLayout = ({ children }) => (
    <div
      className="pages-bg"
      style={{
        backgroundImage: `url(${toAbsoluteUrl(
          "/images/home/background/introductionBG.png"
        )})`,
        height: "400px",
      }}
    >
      <div className="d-flex container h-100">{children}</div>
    </div>
  );

  const AdvantageLayout = ({ children }) => (
    <div
      className="pages-bg mb-5"
      style={{
        backgroundImage: `url(${toAbsoluteUrl(
          "/images/home/background/advantageBG.png"
        )})`,
        height: "400px",
      }}
    >
      <div className="d-flex container h-100">{children}</div>
    </div>
  );

  const TargetCustomerLayout = ({ children }) => (
    <div
      className="pages-bg mb-5"
      style={{
        backgroundImage: `url(${toAbsoluteUrl(
          "/images/home/background/targetCustomerBG.png"
        )})`,
        height: "400px",
      }}
    >
      <div className="container h-100 text-center p-4">{children}</div>
    </div>
  );

  return (
    <>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      {homeState.homePageUI ? (
        <Slide
          duration={2000}
          slidesToScroll={1}
          arrows={false}
          cssClass="shadow-lg mb-4"
        >
          {homeState.homePageUI.topBannerLists.map((topBanner, index) => (
            <img key={index} src={topBanner.imagename} className="w-100" />
          ))}
        </Slide>
      ) : (
        <Skeleton className="w-100 mb-4" style={{ height: "500px" }} />
      )}
      <div className="d-flex mb-5 align-items-center container">
        <i className="fa fa-bullhorn text-prim fs-4 me-2" />
        <Marquee direction="right" className="flex-fill fs-4">
          {homeState.homePageUI
            ? homeState.homePageUI.announcement
            : intl.formatMessage({ id: "loading" })}
        </Marquee>
      </div>
      <div className="fs-4 fw-bold container mb-4">
        {intl.formatMessage({ id: "introduction" })}
      </div>
      <>
        <IntroductionLayout>
          <div className="col-5 p-4">
            <img
              className="h-100"
              src={toAbsoluteUrl("/images/home/icon/home_1.png")}
            />
          </div>
          <div className="col-7 p-4 ps-0 fs-5 d-flex align-items-center">
            {intl.formatMessage({ id: "introDescription_1" })}
          </div>
        </IntroductionLayout>
        <IntroductionLayout>
          <div className="col-7 p-4 pe-0 fs-5 d-flex align-items-center">
            {intl.formatMessage({ id: "introDescription_2" })}
          </div>
          <div className="col-5 p-4 text-end">
            <img
              className="h-100"
              src={toAbsoluteUrl("/images/home/icon/home_2.png")}
            />
          </div>
        </IntroductionLayout>
        <IntroductionLayout>
          <div className="col-5 p-4">
            <img
              className="h-100"
              src={toAbsoluteUrl("/images/home/icon/home_3.png")}
            />
          </div>
          <div className="col-7 p-4 ps-0 fs-5 d-flex align-items-center">
            {intl.formatMessage({ id: "introDescription_3" })}
          </div>
        </IntroductionLayout>
        <IntroductionLayout>
          <div className="col-7 p-4 pe-0 fs-5 d-flex align-items-center">
            {intl.formatMessage({ id: "introDescription_4" })}
          </div>
          <div className="col-5 p-4 text-end">
            <img
              className="h-100"
              src={toAbsoluteUrl("/images/home/icon/home_4.png")}
            />
          </div>
        </IntroductionLayout>
      </>
      <div className="fs-4 fw-bold container mb-4">
        {intl.formatMessage({ id: "advantage" })}
      </div>
      <>
        <AdvantageLayout>
          <div className="col-4 p-4">
            <img
              className="h-100"
              src={toAbsoluteUrl("/images/home/icon/home_5.png")}
            />
          </div>
          <div className="col-8 d-flex flex-column p-4 ps-0 fs-5">
            <div className="mb-4 fw-bold">
              {intl.formatMessage({ id: "advantageTitle_1" })}
            </div>
            <div className="mb-5">
              {intl.formatMessage({ id: "advantageDescription_1" })}
            </div>
            <div className="d-flex flex-fill" style={{ height: "180px" }}>
              <img
                className="h-100 me-4"
                src={toAbsoluteUrl("/images/home/icon/home_5_sub_1.png")}
              />
              <img
                className="h-100 me-4"
                src={toAbsoluteUrl("/images/home/icon/home_5_sub_2.png")}
              />
              <img
                className="h-100 me-4"
                src={toAbsoluteUrl("/images/home/icon/home_5_sub_3.png")}
              />
            </div>
          </div>
        </AdvantageLayout>
        <AdvantageLayout>
          <div className="col-4 p-4">
            <img
              className="h-100"
              src={toAbsoluteUrl("/images/home/icon/home_6.png")}
            />
          </div>
          <div className="col-8 d-flex flex-column p-4 ps-0 fs-5">
            <div className="mb-4 fw-bold">
              {intl.formatMessage({ id: "advantageTitle_2" })}
            </div>
            <div className="mb-5">
              {intl.formatMessage({ id: "advantageDescription_2" })}
            </div>
            <div className="d-flex flex-fill" style={{ height: "180px" }}>
              <img
                className="h-100 me-4"
                src={toAbsoluteUrl("/images/home/icon/home_6_sub_1.png")}
              />
              <img
                className="h-100 me-4"
                src={toAbsoluteUrl("/images/home/icon/home_6_sub_2.png")}
              />
            </div>
          </div>
        </AdvantageLayout>
        <AdvantageLayout>
          <div className="col-4 p-4">
            <img
              className="h-100"
              src={toAbsoluteUrl("/images/home/icon/home_7.png")}
            />
          </div>
          <div className="col-8 d-flex flex-column p-4 ps-0 fs-5">
            <div className="mb-4 fw-bold">
              {intl.formatMessage({ id: "advantageTitle_3" })}
            </div>
            <div className="mb-5">
              {intl.formatMessage({ id: "advantageDescription_3" })}
            </div>
            <div className="d-flex flex-fill" style={{ height: "180px" }}>
              <img
                className="h-100 me-4"
                src={toAbsoluteUrl("/images/home/icon/home_7_sub_1.png")}
              />
              <img
                className="h-100 me-4"
                src={toAbsoluteUrl("/images/home/icon/home_7_sub_2.png")}
              />
              <img
                className="h-100 me-4"
                src={toAbsoluteUrl("/images/home/icon/home_7_sub_3.png")}
              />
            </div>
          </div>
        </AdvantageLayout>
        <AdvantageLayout>
          <div className="col-4 p-5">
            <img
              className="w-100"
              src={toAbsoluteUrl("/images/home/icon/home_8.png")}
            />
          </div>
          <div className="col-8 d-flex flex-column p-4 ps-0 fs-5">
            <div className="mb-4 fw-bold">
              {intl.formatMessage({ id: "advantageTitle_4" })}
            </div>
            <div className="mb-5">
              {intl.formatMessage({ id: "advantageDescription_4" })}
            </div>
            <div className="d-flex flex-fill" style={{ height: "180px" }}>
              <img
                className="h-100 me-4"
                src={toAbsoluteUrl("/images/home/icon/home_8_sub_1.png")}
              />
              <img
                className="h-100 me-4"
                src={toAbsoluteUrl("/images/home/icon/home_8_sub_2.png")}
              />
              <img
                className="h-100 me-4"
                src={toAbsoluteUrl("/images/home/icon/home_8_sub_3.png")}
              />
            </div>
          </div>
        </AdvantageLayout>
      </>
      <div className="fs-4 fw-bold container mb-4">
        {intl.formatMessage({ id: "targetCustomers" })}
      </div>
      <>
        <TargetCustomerLayout>
          <img
            style={{ width: "43%" }}
            src={toAbsoluteUrl("/images/home/icon/home_9.png")}
          />
          <div className="w-100 text-start fs-5 mt-3">
            {intl.formatMessage({ id: "targetCustomersDescription_1" })}
          </div>
        </TargetCustomerLayout>
        <TargetCustomerLayout>
          <img
            style={{ width: "60%" }}
            src={toAbsoluteUrl("/images/home/icon/home_10.png")}
          />
          <div className="w-100 text-start fs-5 mt-3">
            {intl.formatMessage({ id: "targetCustomersDescription_2" })}
          </div>
        </TargetCustomerLayout>
      </>
    </>
  );
};
