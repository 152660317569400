import { useState, useRef } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../auth/components/AuthInit";
import { useEffect } from "react";
import * as actions from "../../redux/SettingAction";
import { Overlay } from "../../../common/components/Overlay";
import * as masterActions from "../../../common/redux/MasterLayoutAction";
import SweetAlert2 from "react-sweetalert2";
import Skeleton from "react-loading-skeleton";
import { ExchangeCurrencyForm } from "./ExchangeCurrencyForm";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";

export const ExchangeCurrency = () => {
  const [swalProps, setSwalProps] = useState({});
  const { auth } = useAuth();
  const intl = useIntl();
  const dispatch = useDispatch();
  const settingState = useSelector(({ setting }) => setting, shallowEqual);
  const formikRef = useRef();
  const exchangeCurrency = useRef("");
  const [selectedCurrency, setSelectedCurrency] = useState(auth.currencycode);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    dispatch(actions.getMultiWalletInfo());
  }, []);

  useEffect(() => {
    dispatch(
      actions.getMultiWalletRateList({ selcurrencycode: selectedCurrency })
    );
  }, [selectedCurrency]);

  const saveValues = (queryParams) => {
    const newQuery = {
      ...queryParams,
      fromcurrencycode: selectedCurrency,
      tocurrencycode: exchangeCurrency.current,
    };

    dispatch(actions.exchangingCurrency(newQuery)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "exchangeSuccessfully" }),
          onResolve: () => {
            setSwalProps({});
            dispatch(actions.getMultiWalletInfo());
            dispatch(masterActions.getWalletInfo());
            formikRef.current.resetForm();
            setAmount(0);
          },
        });
      } else {
        formikRef.current.setFieldError(
          "amount",
          intl.formatMessage({ id: "amountMustMoreThan5" })
        );
      }
    });
  };

  const selectedWalletInfo = settingState.multiWalletInfo
    ? settingState.multiWalletInfo.multiWalletInfoLists.filter(
        (multiWalletInfo) => multiWalletInfo.currencycode === selectedCurrency
      )
    : [];

  const unselectedWalletInfo = settingState.multiWalletInfo
    ? settingState.multiWalletInfo.multiWalletInfoLists.filter(
        (multiWalletInfo) => multiWalletInfo.currencycode !== selectedCurrency
      )
    : [];

  return (
    <>
      {settingState.actionsLoading && <Overlay />}
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <h5 className="p-0 mb-4">{intl.formatMessage({ id: "exchange" })}</h5>
      {settingState.multiWalletInfo && settingState.multiWalletRateList ? (
        <div style={{ minHeight: "600px" }}>
          {selectedWalletInfo.map((multiWalletInfo, index) => (
            <div className="custom-border p-3 rounded d-flex mb-4" key={index}>
              <div className="col-5">
                <div className="dropdown">
                  <div
                    className="d-flex align-items-center cursor-pointer me-4"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="d-flex align-items-center me-4">
                      <img
                        src={multiWalletInfo.imageurl}
                        alt={multiWalletInfo.currencycode}
                        style={{ width: "50px", height: "50px" }}
                      />
                    </div>
                    <div className="flex-fill">
                      <div className="mb-2 text-muted">
                        {multiWalletInfo.currencycode}
                      </div>
                      <div>{multiWalletInfo.currencyname}</div>
                    </div>
                  </div>
                  <div className="dropdown-menu w-100">
                    {unselectedWalletInfo.map((currency) => (
                      <div
                        className="dropdown-item d-flex align-items-center py-2 cursor-pointer"
                        key={currency.currencycode}
                        onClick={() => {
                          setSelectedCurrency(currency.currencycode);
                          document
                            .querySelector(".dropdown-menu")
                            .classList.remove("show");
                        }}
                      >
                        <img
                          src={currency.imageurl}
                          alt={currency.currencycode}
                          className="me-4 currency-icon"
                        />
                        <div>{currency.currencyname}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex-fill">
                <ExchangeCurrencyForm
                  formikRef={formikRef}
                  saveValues={saveValues}
                  setAmount={setAmount}
                  mainWallet={multiWalletInfo.balance}
                  currencyCode={multiWalletInfo.currencycode}
                />
              </div>
            </div>
          ))}
          {unselectedWalletInfo.map((multiWalletInfo, index) => (
            <div className="custom-border p-3 rounded d-flex mb-4" key={index}>
              <div className="d-flex align-items-center col-5">
                <div className="d-flex align-items-center me-4">
                  <img
                    src={multiWalletInfo.imageurl}
                    alt={multiWalletInfo.currencycode}
                    style={{ width: "50px", height: "50px" }}
                  />
                </div>
                <div className="flex-fill">
                  <div className="mb-2 text-muted">
                    {multiWalletInfo.currencycode}
                  </div>
                  <div>{multiWalletInfo.currencyname}</div>
                </div>
              </div>
              <div className="flex-fill">
                <div className="mb-2 text-muted">
                  {intl.formatMessage({ id: "available" })}
                </div>
                <div className="fw-semibold">{multiWalletInfo.balance}</div>
              </div>
              <div className="flex-fill">
                <div className="mb-2 text-muted">
                  {intl.formatMessage({ id: "equals" })}
                </div>
                <div className="fw-semibold">
                  {settingState.multiWalletRateList.multiWalletRateList
                    .filter(
                      (multiWalletRate) =>
                        multiWalletRate.currencycode ===
                        multiWalletInfo.currencycode
                    )
                    .map((multiWalletRate) => (
                      <span key={index}>
                        {(parseFloat(multiWalletRate.rate) * amount).toFixed(2)}
                      </span>
                    ))}
                </div>
              </div>
              <div className="d-flex align-items-center pe-4">
                <i
                  className="bi bi-arrow-left-right fs-3 cursor-pointer icon-exchange"
                  title={intl.formatMessage(
                    { id: "exchangeTo" },
                    { n: multiWalletInfo.currencycode }
                  )}
                  onClick={() => {
                    exchangeCurrency.current = multiWalletInfo.currencycode;
                    formikRef.current.submitForm();
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Skeleton className="w-100" height="600px" />
      )}
    </>
  );
};
