import { useEffect, useRef } from "react";
import { Modal } from "../../../../common/components/Modal";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/SettingAction";
import * as masterActions from "../../../../common/redux/MasterLayoutAction";
import { ApiStatusTypeEnum } from "../../../../../enumeration/ApiStatusTypeEnum";
import { TransferForm } from "./TransferForm";
import { useState } from "react";

export const TransferDialog = ({
  setSwalProps,
  company,
  mainWallet,
  setIsUpdateBalance,
}) => {
  const intl = useIntl();
  const [isDeposit, setIsDeposit] = useState(true);
  const [gameBalance, setGameBalance] = useState("");
  const dismissBtnRef = useRef();
  const formikRef = useRef();
  const settingState = useSelector(({ setting }) => setting, shallowEqual);
  const switchClass = "col-6 cursor-pointer p-2 text-center fw-semibold switch";

  const dispatch = useDispatch();

  const saveValues = (queryParams) => {
    const newQuery = { ...queryParams, companycode: company.companycode };

    const transferAction = isDeposit
      ? actions.companyGameBalanceTopUp(newQuery)
      : actions.companyGameBalanceWithdraw(newQuery);

    dispatch(transferAction).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "transferSuccessfully" }),
          onConfirm: () => {
            formikRef.current.resetForm();
            dismissBtnRef.current.click();
            dispatch(actions.getGameCompanyTransferUI());
            dispatch(masterActions.getWalletInfo());
            setIsUpdateBalance((prev) => prev + 1);
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  useEffect(() => {
    if (company) {
      dispatch(
        actions.getCompanyGameBalance({ companycode: company.companycode })
      ).then((resp) => {
        formikRef.current.resetForm();
        setIsDeposit(true);
        resp = resp.data;
        setGameBalance(resp.balance);
      });
    }
  }, [company]);

  return (
    <Modal
      modalId="transferModal"
      title={company ? company.companyname : null}
      dismissBtnRef={dismissBtnRef}
    >
      <div className="p-3 custom-border-bottom">
        <div className="d-flex mb-3 custom-border rounded overflow-hidden">
          <div
            className={`${switchClass} ${isDeposit ? "active" : ""}`}
            onClick={() => {
              setIsDeposit(true);
              formikRef.current.resetForm();
            }}
          >
            {intl.formatMessage({ id: "deposit" })}
          </div>
          <div
            className={`${switchClass} ${!isDeposit ? "active" : ""}`}
            onClick={() => {
              setIsDeposit(false);
              formikRef.current.resetForm();
            }}
          >
            {intl.formatMessage({ id: "withdraw" })}
          </div>
        </div>
        <TransferForm
          formikRef={formikRef}
          saveValues={saveValues}
          mainWallet={mainWallet}
          gameBalance={gameBalance}
          companyRate={company?.rate ?? ""}
          isDeposit={isDeposit}
        />
      </div>
      <div className="p-3 d-flex justify-content-end align-items-center">
        {settingState.actionsLoading && (
          <div
            className="spinner-border me-2"
            role="status"
            style={{ scale: "0.55" }}
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
        <button
          type="button"
          disabled={settingState.actionsLoading}
          className="btn btn-prim fw-bold px-3"
          onClick={() => formikRef.current.submitForm()}
        >
          {intl.formatMessage({ id: "submit" })}
        </button>
      </div>
    </Modal>
  );
};
