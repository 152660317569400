export const GameTypeConst = {
  SLOTS: "slots",
  LIVE: "liveCasino",
  FISH: "fishing",
  LOTTERY: "lottery",
  SPORTS: "sports",
};

export const GameTypeAPIConst = {
  SLOTS: "slot",
  LIVE: "live",
  FISH: "fishing",
  LOTTERY: "lottery",
  SPORTS: "sports",
};

export const GameListConst = {
  SLOTS: "slotCompanyLists",
  LIVE: "liveCompanyLists",
  FISH: "fishingCompanyLists",
  LOTTERY: "lotteryCompanyLists",
  SPORTS: "sportCompanyLists",
};

export const GameTypeLandingConst = {
  1: "/slot",
  2: "/liveCasino",
  3: "/fishing",
  4: "/sport",
};

export const GameTypeEnumConst = {
  1: "slot",
  2: "live",
  3: "fishing",
  4: "sport",
};
