import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/SettingAction";
import Skeleton from "react-loading-skeleton";
import { CheckIsNull } from "../../../../utils/CommonHelper";

export const TransactionReport = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [dateType, setDateType] = useState(0);

  const settingState = useSelector(({ setting }) => setting, shallowEqual);

  useEffect(() => {
    dispatch(actions.getTransactionReport({ datetype: dateType }));
  }, [dateType]);

  return (
    <>
      <h5 className="mb-4">
        {intl.formatMessage({ id: "transactionReport" })}
      </h5>
      {settingState.transactionReport ? (
        <>
          <div className="col-3">
            <select
              className="form-select"
              value={dateType}
              onChange={(e) => setDateType(e.target.value)}
            >
              {settingState.transactionReport.reportDateLists.map(
                (reportDate, index) => (
                  <option key={index} value={reportDate.datetype}>
                    {reportDate.description}
                  </option>
                )
              )}
            </select>
          </div>
          <label className="mb-4 ms-1 small text-muted">
            {intl.formatMessage({ id: "date" })}
          </label>

          {settingState.listLoading ? (
            <div className="d-flex align-items-center justify-content-center py-5">
              <div
                className="spinner-border"
                role="status"
                style={{ scale: "1.2" }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div style={{ minHeight: "600px" }}>
              {settingState.transactionReport.memberReports.length > 0 ? (
                <div>
                  {settingState.transactionReport.memberReports.map(
                    (report, index) => (
                      <div className="card" key={index}>
                        <div
                          className="card-body rounded"
                          style={{ background: "rgba(0,0,0,0.2)" }}
                        >
                          <div className="d-flex justify-content-between">
                            <div>{report.message}</div>
                            <div>
                              <div
                                className={`mb-2 text-end fw-bold ${
                                  CheckIsNull(report.balance)
                                    ? ""
                                    : parseFloat(report.balance) >= 0
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {report.balance}
                              </div>
                              <div
                                className="text-muted fst-italic"
                                style={{ fontSize: "0.75rem" }}
                              >
                                {report.date} {report.time}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              ) : (
                <div className="d-flex py-5 align-items-center justify-content-center">
                  <div className="text-muted d-flex align-items-center justify-content-center flex-column">
                    <i className="bi bi-search fs-1 mb-3" />
                    <div>{intl.formatMessage({ id: "noRecordFound" })}</div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          <Skeleton height="50px" className="col-3 mb-4" />
          <Skeleton height="500px" className="w-100" />
        </>
      )}
    </>
  );
};
