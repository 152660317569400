import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";
import * as requestToApi from "./SlotCRUD";
import { slotSlice } from "./SlotSlice";

const { actions } = slotSlice;

export const getSlotPageUI = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchSlotPageUI(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.slotPageUIFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchSlotPageUI");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const getSlotGameList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchSlotGameList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.slotGameListFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchSlotGameList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const clearSlotGameList = () => (dispatch) => {
  dispatch(actions.clearSlotGameList());
};

export const playGame = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .companyPlayGame(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.playCompanyGame({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: companyPlayGame");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
