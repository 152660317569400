import { useIntl } from "react-intl";
import { useEffect, useRef, useState } from "react";
import {
  fetchIPAddress,
  fetchLoginSlide,
  fetchPromoUrl,
} from "../redux/LoginCRUD";
import { SlideTab } from "./loginTab/SlideTab";
import SweetAlert2 from "react-sweetalert2";
import { LoginTab } from "../enum/LoginTab";
import { RegisterMethodTab } from "./loginTab/RegisterMethodTab";
import { RegisterTab } from "./loginTab/RegisterTab";
import { LoginFormTab } from "./loginTab/LoginFormTab";
import { ForgotPasswordTab } from "./loginTab/ForgotPasswordTab";
import { useAuth } from "./AuthInit";
import { LoginPhoneAuthTab } from "./loginTab/LoginPhoneAuthTab";
import { GoogleRegisterTab } from "./loginTab/GoogleRegisterTab";
import { CheckIsNull, toAbsoluteUrl } from "../../../utils/CommonHelper";
import { I18N_CONFIG_KEY } from "../../../i18n/Metronici18n";
import { useParams } from "react-router-dom";

export const LoginRegisterDialog = ({ initialTab }) => {
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});
  const [loginTab, setLoginTab] = useState(
    initialTab ? initialTab : LoginTab.LOGIN
  );
  const [slideList, setSlideList] = useState([]);
  const [tempAuth, setTempAuth] = useState();

  const { logout, promoShow, setPromoShow } = useAuth();

  const { credential } = useParams();

  useEffect(() => {
    fetchLoginSlide(intl.locale).then((response) => {
      setSlideList(response.bannerLists);
    });
  }, []);

  useEffect(() => {
    if (
      !promoShow &&
      (loginTab === LoginTab.LOGIN || loginTab === LoginTab.REGISTER_OPTION)
    ) {
      fetchIPAddress().then((res) => {
        const queryParams = `openfrom=${
          loginTab === LoginTab.LOGIN ? "login" : "register"
        }&language=${
          localStorage.getItem(I18N_CONFIG_KEY) ? intl.locale : "en"
        }&platform=pc&ipaddress=${res.ip}`;

        fetchPromoUrl(queryParams).then((res) => {
          if (!CheckIsNull(res.promourl)) {
            setSwalProps({
              show: true,
              showCloseButton: true,
              showConfirmButton: false,
              closeButtonHtml: `<div><button class="btn btn-danger rounded-circle p-0 d-flex align-items-center justify-content-center" style="width:30px;height:30px;"><div style="padding-bottom:2px"><i class="bi bi-x fs-4"/></div></button></div>`,
              html: `<div style="height:720px" class="overflow-hidden"><iframe src="${res.promourl}" class="w-100 h-100 overflow-hidden" frameBorder="0" scrolling="no"/></div>`,
            });
          }
        });
      });
      setPromoShow(true);
    }
  }, [loginTab]);

  return (
    <div
      className="position-absolute top-0 bottom-0 w-100 p-5"
      style={{
        background: `url('${toAbsoluteUrl("images/bg.jpg")}')`,
        backgroundSize: "cover",
      }}
    >
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div className="d-flex h-100">
        <div className="col-4 card me-4 h-100">
          <div className="card-body scroll-y position-relative">
            {loginTab !== LoginTab.LOGIN && (
              <i
                className="fa fa-arrow-left position-absolute fs-4 cursor-pointer"
                onClick={() => {
                  const backTab =
                    loginTab === LoginTab.REGISTER_PART_1 ||
                    loginTab === LoginTab.GOOGLE_REGISTER
                      ? LoginTab.REGISTER_OPTION
                      : loginTab === LoginTab.REGISTER_PART_2
                      ? LoginTab.REGISTER_PART_1
                      : loginTab === LoginTab.FORGOT_PASSWORD_PART_2
                      ? LoginTab.FORGOT_PASSWORD_PART_1
                      : loginTab === LoginTab.LOGIN_PHONE_AUTH_STEP_2
                      ? LoginTab.LOGIN_PHONE_AUTH_STEP_1
                      : LoginTab.LOGIN;
                  if (
                    loginTab === LoginTab.REGISTER_PART_3 ||
                    loginTab === LoginTab.FORGOT_PASSWORD_PART_3 ||
                    loginTab === LoginTab.LOGIN_PHONE_AUTH_STEP_2
                  ) {
                    setSwalProps({
                      show: true,
                      icon: "info",
                      title: intl.formatMessage({ id: "alert" }),
                      text: intl.formatMessage({
                        id: "backErrorDescription",
                      }),
                      showCancelButton: true,
                      showConfirmButton: true,
                      confirmButtonText: intl.formatMessage({ id: "confirm" }),
                      denyButtonText: intl.formatMessage({ id: "cancel" }),
                      onConfirm: () => {
                        logout();
                        setLoginTab(
                          loginTab === LoginTab.REGISTER_PART_3
                            ? LoginTab.REGISTER_OPTION
                            : LoginTab.LOGIN
                        );
                      },
                    });
                  } else {
                    setLoginTab(backTab);
                  }
                }}
              />
            )}

            <div className="text-center my-5">
              <img
                src={toAbsoluteUrl("images/logo.png")}
                alt="logo"
                style={{ width: "80%" }}
              />
            </div>
            <div className="d-flex justify-content-center">
              <div className="col-10">
                {loginTab === LoginTab.LOGIN ? (
                  <LoginFormTab
                    setSwalProps={setSwalProps}
                    setLoginTab={setLoginTab}
                    setTempAuth={setTempAuth}
                    credential={credential}
                  />
                ) : loginTab === LoginTab.REGISTER_OPTION ? (
                  <RegisterMethodTab setLoginTab={setLoginTab} />
                ) : loginTab === LoginTab.REGISTER_PART_1 ||
                  loginTab === LoginTab.REGISTER_PART_2 ||
                  loginTab === LoginTab.REGISTER_PART_3 ? (
                  <RegisterTab
                    setLoginTab={setLoginTab}
                    setSwalProps={setSwalProps}
                    loginTab={loginTab}
                  />
                ) : loginTab === LoginTab.FORGOT_PASSWORD_PART_1 ||
                  loginTab === LoginTab.FORGOT_PASSWORD_PART_2 ||
                  loginTab === LoginTab.FORGOT_PASSWORD_PART_3 ? (
                  <ForgotPasswordTab
                    setLoginTab={setLoginTab}
                    setSwalProps={setSwalProps}
                    loginTab={loginTab}
                  />
                ) : loginTab === LoginTab.LOGIN_PHONE_AUTH_STEP_1 ||
                  loginTab === LoginTab.LOGIN_PHONE_AUTH_STEP_2 ? (
                  <LoginPhoneAuthTab
                    setLoginTab={setLoginTab}
                    setSwalProps={setSwalProps}
                    loginTab={loginTab}
                    tempAuth={tempAuth}
                  />
                ) : loginTab === LoginTab.GOOGLE_REGISTER ? (
                  <GoogleRegisterTab
                    setLoginTab={setLoginTab}
                    setSwalProps={setSwalProps}
                    loginTab={loginTab}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="flex-fill card h-100">
          <div className="card-body scroll-y">
            <SlideTab slideList={slideList} />
          </div>
        </div>
      </div>
    </div>
  );
};
