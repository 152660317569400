export const SettingNavTitle = {
  PROFILE: "profile",
  TERMSANDCONDITION: "termsAndCondition",
  DEPOSIT: "deposit",
  WITHDRAW: "withdraw",
  TRANSFER: "transfer",
  MEMBER_TRANSFER: "memberTransfer",
  POINT_SHOP: "pointShop",
  EXCHANGE: "exchange",
  TRANSACTION_REPORT: "transactionReport",
  GAME_REPORT: "gameReport",
  DOWNLINE: "downline",
  INBOX: "inbox",
  BONUS: "bonus",
};

export const SettingNavConst = [
  {
    title: SettingNavTitle.PROFILE,
    image: "images/setting/profile.png",
    navigate: "/setting/profile",
  },
  {
    title: SettingNavTitle.TERMSANDCONDITION,
    image: "images/setting/termsAndCondition.png",
    navigate: "/setting/termsAndCondition",
  },
  {
    title: SettingNavTitle.DEPOSIT,
    image: "images/setting/deposit.png",
    navigate: "/setting/deposit",
  },
  {
    title: SettingNavTitle.WITHDRAW,
    image: "images/setting/withdraw.png",
    navigate: "/setting/withdraw",
  },
  {
    title: SettingNavTitle.TRANSFER,
    image: "images/setting/gameTransfer.png",
    navigate: "/setting/gameTransfer",
  },
  {
    title: SettingNavTitle.MEMBER_TRANSFER,
    image: "images/setting/memberTransfer.png",
    navigate: "/setting/memberTransfer",
  },
  {
    title: SettingNavTitle.POINT_SHOP,
    image: "images/setting/pointShop.png",
    navigate: "/setting/pointShop",
  },
  {
    title: SettingNavTitle.BONUS,
    image: "images/setting/bonus.png",
    navigate: "/setting/bonus",
  },
  {
    title: SettingNavTitle.EXCHANGE,
    image: "images/setting/exchange.png",
    navigate: "/setting/exchange",
  },
  {
    title: SettingNavTitle.TRANSACTION_REPORT,
    image: "images/setting/transactionReport.png",
    navigate: "/setting/transactionReport",
  },
  {
    title: SettingNavTitle.GAME_REPORT,
    image: "images/setting/gameReport.png",
    navigate: "/setting/gameReport",
  },
  {
    title: SettingNavTitle.DOWNLINE,
    image: "images/setting/downline.png",
    navigate: "/setting/downline",
  },
  {
    title: SettingNavTitle.INBOX,
    image: "images/setting/inbox.png",
    navigate: "/setting/inbox",
  },
];
