import { authPost } from "../../common/redux/ApiClient";

const baseUrl = "http://34.87.51.38/ok99memberApi/api";

export function fetchSportPageUI(params) {
  return authPost(`${baseUrl}/MemberPc2/PcSportPageUI`, params);
}

export function fetchSportGameList(params) {
  return authPost(`${baseUrl}/MemberPc2/PcSportGameList`, params);
}

export function companyPlayGame(params) {
  return authPost(`${baseUrl}/Member3/CompanyPlayGame`, params);
}
