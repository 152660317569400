import { combineReducers } from "redux";
import { homeSlice } from "../modules/home/redux/HomeSlice";
import { slotSlice } from "../modules/slot/redux/SlotSlice";
import { masterLayoutSlice } from "../modules/common/redux/MasterLayoutSlice";
import { settingSlice } from "../modules/setting/redux/SettingSlice";
import { liveCasinoSlice } from "../modules/liveCasino/redux/LiveCasinoSlice";
import { fishingSlice } from "../modules/fishing/redux/FishingSlice";
import { appGamesSlice } from "../modules/appGames/redux/AppGamesSlice";
import { gamePlaySlice } from "../modules/gamePlay/redux/GamePlaySlice";
import { lotterySlice } from "../modules/lottery/redux/LotterySlice";
import { promotionSlice } from "../modules/promotion/redux/PromotionSlice";
import { sportSlice } from "../modules/sport/redux/SportSlice";

export const rootReducer = combineReducers({
  // get from slice file
  masterLayout: masterLayoutSlice.reducer,
  home: homeSlice.reducer,
  slot: slotSlice.reducer,
  setting: settingSlice.reducer,
  liveCasino: liveCasinoSlice.reducer,
  fishing: fishingSlice.reducer,
  appGames: appGamesSlice.reducer,
  gamePlay: gamePlaySlice.reducer,
  lottery: lotterySlice.reducer,
  promotion: promotionSlice.reducer,
  sport: sportSlice.reducer,
});
