import { useIntl } from "react-intl";
import { LoginTab } from "../../enum/LoginTab";
import { Field, Form, Formik } from "formik";
import {
  ErrorContainer,
  Input,
  PhoneInputField,
} from "../../../common/components/control/InputField";
import { useEffect, useRef, useState } from "react";
import {
  fetchCurrencyList,
  fetchIPAddress,
  fetchVerificationCode,
  getCurrentCountryCode,
  getOTP,
  registerStep2,
  registerStep3,
} from "../../redux/LoginCRUD";
import Skeleton from "react-loading-skeleton";
import { CheckIsNull, IsAlphaNumeric } from "../../../../utils/CommonHelper";
import {
  RegisterStep1Schema,
  RegisterStep3Schema,
} from "../../yupSchema/registerSchema";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";
import OTPInput from "react-otp-input";
import { useAuth } from "../AuthInit";
import { useParams } from "react-router-dom";

export const RegisterTab = ({ setLoginTab, setSwalProps, loginTab }) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingCurrency, setIsFetchingCurrency] = useState(true);
  const [isShowOtpError, setIsShowOTPError] = useState(false);
  const [isShowPhoneNoError, setIsShowPhoneNoError] = useState(false);
  const [isShowCurrencyError, setIsShowCurrencyError] = useState(false);
  const [verifyImage, setVerifyImageUrl] = useState("");
  const [otp, setOtp] = useState("");
  const [otpErrorDescription, setOtpErrorDescription] = useState("");
  const [phoneNoErrorDescription, setPhoneNoErrorDescription] = useState("");
  const [registerDetail, setRegisterDetail] = useState();
  const [currencyLists, setCurrencyLists] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [phoneCode, setPhoneCode] = useState("my");
  const { saveAuth, setCurrentUser } = useAuth();
  const codeNum = useRef("");
  const formikRef = useRef();
  const dateNow = Date.now();
  const longitude = useRef("");
  const latitude = useRef("");
  const ipAddress = useRef("");
  const { refferalCode } = useParams();
  const steps = [
    LoginTab.REGISTER_PART_1,
    LoginTab.REGISTER_PART_2,
    LoginTab.REGISTER_PART_3,
  ];

  const getVerificationCode = () => {
    setVerifyImageUrl("");
    codeNum.current = "";
    fetchVerificationCode().then((resp) => {
      setVerifyImageUrl(resp.imageurl);
      codeNum.current = resp.codenum;
    });
  };

  useEffect(() => {
    getVerificationCode();
    fetchCurrencyList({ language: intl.locale }).then((resp) => {
      setCurrencyLists(resp.currencyLists);
      fetchIPAddress().then((res) => {
        ipAddress.current = res.ip;
        getCurrentCountryCode(res.ip).then((re) => {
          const currencyCode = resp.currencyLists.find(
            (currency) => currency.currencycode === re.currency
          );
          setPhoneCode(re.country.toLowerCase());
          if (currencyCode) {
            setSelectedCurrency(currencyCode);
          } else {
            setSelectedCurrency(resp.currencyLists[0]);
          }
          setIsFetchingCurrency(false);
          setIsLoading(false);
        });
      });
    });
  }, []);

  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(function (position) {
  //     longitude.current = position.coords.longitude;
  //     latitude.current = position.coords.latitude;
  //   });
  // }, []);

  return (
    <>
      <h3 className="mb-5 text-center">
        {intl.formatMessage({ id: "registerWithPhoneNumber" })}
      </h3>
      <div className="d-flex justify-content-center mb-4">
        <div className="d-flex align-items-center justify-content-between steps w-50">
          {steps.map((step, index) => (
            <div
              key={step}
              className={`fw-bold step rounded-circle d-flex align-items-center justify-content-center ${
                step === loginTab ? "active" : ""
              }`}
            >
              {index + 1}
            </div>
          ))}
        </div>
      </div>
      {loginTab === LoginTab.REGISTER_PART_1 ? (
        <Formik
          initialValues={{
            phoneNo: registerDetail ? registerDetail.telno : "",
            referralCode: refferalCode
              ? refferalCode
              : registerDetail
              ? registerDetail.referrerid
              : "",
            verifyCode: "",
          }}
          innerRef={formikRef}
          validationSchema={RegisterStep1Schema(intl)}
          enableReinitialize={true}
          onSubmit={(values, formikHelpers) => {
            if (codeNum.current === values.verifyCode) {
              setIsLoading(true);
              const queryParams = {
                telno: values.phoneNo,
                referrerid: values.referralCode,
                uniqueid: dateNow,
                language: intl.locale,
              };

              getOTP(queryParams).then((resp) => {
                setIsLoading(false);
                getVerificationCode();
                formikHelpers.setFieldValue("verifyCode", "");
                if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
                  setRegisterDetail(queryParams);
                  setLoginTab(LoginTab.REGISTER_PART_2);
                } else {
                  setIsShowPhoneNoError(true);
                  setPhoneNoErrorDescription(resp.message);
                }
              });
            } else {
              formikHelpers.setFieldError(
                "verifyCode",
                intl.formatMessage({ id: "incorrectVerificationCode" })
              );
            }
          }}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            touched,
            errors,
            setFieldError,
            setFieldTouched,
          }) => (
            <Form>
              <div className="mb-2">
                <label className="mb-2 text-input-label">
                  {intl.formatMessage({ id: "phoneNo" })}
                </label>
                {!isFetchingCurrency ? (
                  <PhoneInputField
                    values={values}
                    setFieldValue={setFieldValue}
                    setIsShowPhoneNoError={setIsShowPhoneNoError}
                    isShowPhoneNoError={isShowPhoneNoError}
                    phoneNoErrorDescription={phoneNoErrorDescription}
                    setPhoneNoErrorDescription={setPhoneNoErrorDescription}
                    name="phoneNo"
                    country={phoneCode}
                  />
                ) : (
                  <Skeleton className="w-100" height="35px" />
                )}
              </div>
              <div className="mb-4">
                <Field
                  name="referralCode"
                  component={Input}
                  placeholder={intl.formatMessage({ id: "referralCode" })}
                  label={intl.formatMessage({ id: "referralCode" })}
                  autoComplete="off"
                  readOnly={refferalCode ? true : false}
                />
              </div>
              <div className="mb-2 d-flex align-items-center">
                {CheckIsNull(verifyImage) ? (
                  <Skeleton className="col-4" style={{ height: "60px" }} />
                ) : (
                  <>
                    <img
                      src={verifyImage}
                      alt="verifyImage"
                      className="col-4 me-4"
                      style={{ height: "60px" }}
                    />
                    <i
                      className="fa fa-refresh cursor-pointer"
                      onClick={() => getVerificationCode()}
                    />
                  </>
                )}
              </div>
              <div className="mb-2">
                <Field
                  name="verifyCode"
                  component={Input}
                  placeholder={intl.formatMessage({ id: "verifyCode" })}
                  autoComplete="off"
                  readOnly={isLoading}
                />
              </div>
              <div className="mt-4 mb-2">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="btn btn-prim fw-bold w-100"
                >
                  <div className="d-flex align-items-center justify-content-center">
                    {isLoading && (
                      <div
                        className="spinner-border text-dark"
                        role="status"
                        style={{ scale: "0.75" }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                    {intl.formatMessage({ id: "requestOTP" })}
                  </div>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      ) : loginTab === LoginTab.REGISTER_PART_2 ? (
        <>
          <div className="mb-4 text-center">
            {intl.formatMessage(
              { id: "otpHasSended" },
              { n: registerDetail.telno }
            )}
          </div>
          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={5}
            containerStyle="justify-content-center"
            shouldAutoFocus={true}
            renderSeparator={<div className="mx-2 mb-3"></div>}
            renderInput={(props) => (
              <input {...props} className="form-control otp-field" />
            )}
          />
          <div className="d-flex justify-content-center mt-2">
            <ErrorContainer
              isShow={isShowOtpError}
              errorMsg={otpErrorDescription}
            />
          </div>
          <button
            type="submit"
            onClick={() => {
              if (!CheckIsNull(otp)) {
                setIsShowOTPError(false);
                setIsLoading(true);
                registerStep2({ ...registerDetail, otpcode: otp }).then(
                  (resp) => {
                    setIsLoading(false);
                    setOtp("");
                    if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
                      setLoginTab(LoginTab.REGISTER_PART_3);
                    } else {
                      setIsShowOTPError(true);
                      setOtpErrorDescription(resp.message);
                    }
                  }
                );
              } else {
                setIsShowOTPError(true);
                setOtpErrorDescription(
                  intl.formatMessage({ id: "otpRequired" })
                );
              }
            }}
            disabled={isLoading}
            className="btn btn-prim fw-bold w-100 mt-4"
          >
            <div className="d-flex align-items-center justify-content-center">
              {isLoading && (
                <div
                  className="spinner-border text-dark"
                  role="status"
                  style={{ scale: "0.75" }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
              {intl.formatMessage({ id: "submit" })}
            </div>
          </button>
        </>
      ) : (
        <Formik
          initialValues={{
            username: "",
            userPassword: "",
          }}
          innerRef={formikRef}
          validationSchema={RegisterStep3Schema(intl)}
          enableReinitialize={true}
          onSubmit={(values, formikHelpers) => {
            if (selectedCurrency) {
              let queryParams = {
                ...registerDetail,
                fcmnotificationid: "",
                username: values.username,
                password: values.userPassword,
                currencycode: selectedCurrency.currencycode,
                latitude: latitude.current,
                longitude: longitude.current,
                ipaddress: ipAddress.current,
              };
              setIsShowCurrencyError(false);
              setIsLoading(true);
              registerStep3(queryParams).then((resp) => {
                setIsLoading(false);
                if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
                  setSwalProps({
                    show: true,
                    icon: "success",
                    title: intl.formatMessage({ id: "success" }),
                    text: intl.formatMessage({ id: "registerSuccess" }),
                    onConfirm: () => {
                      saveAuth(resp);
                      setCurrentUser(resp);
                      setLoginTab(LoginTab.LOGIN);
                    },
                  });
                } else {
                  formikHelpers.setFieldError("username", resp.message);
                }
              });
            } else {
              setIsShowCurrencyError(true);
            }
          }}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            touched,
            errors,
            setFieldError,
          }) => (
            <Form>
              <div className="mb-2">
                <Field
                  name="username"
                  component={Input}
                  placeholder={intl.formatMessage({ id: "username" })}
                  label={intl.formatMessage({ id: "username" })}
                  autoComplete="off"
                  onKeyDown={(e) => {
                    if (!IsAlphaNumeric(e)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <div className="mb-2">
                <Field
                  name="userPassword"
                  component={Input}
                  placeholder={intl.formatMessage({ id: "password" })}
                  label={intl.formatMessage({ id: "password" })}
                  autoComplete="off"
                  type="password"
                  onKeyDown={(e) => {
                    if (!IsAlphaNumeric(e)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <div className="mb-4">
                <label className="mb-2 text-input-label">
                  {intl.formatMessage({ id: "currency" })}
                </label>
                <div className="dropdown">
                  <button
                    className={`form-control text-start d-flex align-items-center ${
                      selectedCurrency ? "" : "text-placeholder"
                    }`}
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-text={intl.formatMessage({ id: "currency" })}
                  >
                    {selectedCurrency ? (
                      <>
                        <img
                          src={selectedCurrency.imageurl}
                          alt={selectedCurrency.currencycode}
                          className="me-4 currency-icon"
                        />
                        <div>{selectedCurrency.currencyname}</div>
                      </>
                    ) : (
                      intl.formatMessage({ id: "currency" })
                    )}
                  </button>
                  <div className="dropdown-menu w-100">
                    {currencyLists.map((currency) => (
                      <div
                        className={`dropdown-item d-flex align-items-center py-2 cursor-pointer ${
                          selectedCurrency &&
                          selectedCurrency.currencycode ===
                            currency.currencycode
                            ? "active"
                            : ""
                        }`}
                        key={currency.currencycode}
                        onClick={() => {
                          document
                            .querySelector(".dropdown-menu")
                            .classList.remove("show");
                          setSelectedCurrency(currency);
                        }}
                      >
                        <img
                          src={currency.imageurl}
                          alt={currency.currencycode}
                          className="me-4 currency-icon"
                        />
                        <div>{currency.currencyname}</div>
                      </div>
                    ))}
                  </div>
                </div>
                <ErrorContainer
                  isShow={isShowCurrencyError}
                  errorMsg={intl.formatMessage({ id: "currencyRequired" })}
                />
              </div>
              <div className="mt-4 mb-2">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="btn btn-prim fw-bold w-100"
                >
                  <div className="d-flex align-items-center justify-content-center">
                    {isLoading && (
                      <div
                        className="spinner-border text-dark"
                        role="status"
                        style={{ scale: "0.75" }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                    {intl.formatMessage({ id: "submit" })}
                  </div>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
