import moment from "moment";

export const GetBeforeDate = (days) => {
  var date = new Date();

  return date.setDate(date.getDate() - days);
};

export const DateToDateString = (date) => {
  return moment(date).format("YYYY-MM-DD");
};
