import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import SweetAlert2 from "react-sweetalert2";
import { toAbsoluteUrl } from "../../../utils/CommonHelper";

const PlatformType = {
  ANDROID: "Android",
  IOS: "IOS",
};

export const AppDownloadListing = () => {
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});
  const appsDetailRef = useRef();

  useEffect(() => {
    document.body.classList.add("pages-bg", "h-100", "scroll-y-default");
    document.querySelector("html").classList.add("h-100", "scroll-y-default");

    document.body.style.backgroundImage = `url("${toAbsoluteUrl(
      "images/pagesBg/appDownload.png"
    )}")`;

    axios
      .get(
        `${window.location.protocol}//dl.dropboxusercontent.com/s/mix1kmb6sqeauh5rtxhet/appversion.txt?rlkey=6111u0tenun467i59kcpxo51m&dl=0`
      )
      .then((response) => {
        appsDetailRef.current = response.data;
      })
      .catch((error) => {
        appsDetailRef.current = undefined;
      });

    return () => {
      document.body.style.backgroundImage = ``;
      document.body.classList.remove("pages-bg", "h-100", "scroll-y-default");
      document
        .querySelector("html")
        .classList.remove("h-100", "scroll-y-default");
    };
  }, []);

  const DownloadPlatform = ({ platform }) => {
    return (
      <div
        className="d-flex flex-column align-items-center justify-content-center custom-border mx-1 flex-fill py-3 cursor-pointer rounded"
        style={{ background: "#242424" }}
        onClick={() => {
          if (platform === PlatformType.ANDROID) {
            if (appsDetailRef.current) {
              const a = document.createElement("a");
              const url = appsDetailRef.current.androidappurl;
              a.href = url;
              a.download = url.split("/").pop();
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            } else {
              setSwalProps({
                show: true,
                icon: "error",
                title: intl.formatMessage({ id: "error" }),
                text: intl.formatMessage({ id: "errorOccurContactAdmin" }),
              });
            }
          } else {
            setSwalProps({
              show: true,
              icon: "info",
              title: intl.formatMessage({ id: "alert" }),
              text: intl.formatMessage({ id: "comingSoon" }),
            });
          }
        }}
      >
        <img
          src={toAbsoluteUrl(
            `images/appDownload/${platform.toLowerCase()}.png`
          )}
          alt={platform}
          style={{ width: "50px" }}
        />
        <div className="mt-3">{platform}</div>
      </div>
    );
  };

  return (
    <div className="container">
      <SweetAlert2
        customClass="custom-swal"
        backdrop={false}
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div className="d-flex align-items-center">
        <div className="w-50 h-100">
          <div className="d-flex mb-3">
            {Object.entries(PlatformType).map(([key, value]) => (
              <DownloadPlatform platform={value} key={key} />
            ))}
          </div>
          <h3
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "downloadOurNew" }),
            }}
          />
          <h5 className="text-muted">
            {intl.formatMessage({ id: "downloadAppDescription" })}
          </h5>
        </div>
        <div className="w-50 h-100">
          <img
            src={toAbsoluteUrl("images/appDownload/appsImage.png")}
            className="w-100"
          />
        </div>
      </div>
    </div>
  );
};
