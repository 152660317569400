import { useIntl } from "react-intl";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/components/AuthInit";
import { NavBarConst } from "../../../constants/NavBarConst";
import { createContext, useEffect, useState } from "react";
import * as actions from "../redux/MasterLayoutAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../utils/CommonHelper";
import { LanguageDDLConst } from "../../../constants/LanguageLocale";
import SweetAlert2 from "react-sweetalert2";
import { Overlay } from "./Overlay";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { setLanguage } from "../../../i18n/Metronici18n";

const MasterLayoutContext = createContext();

export const SettingIcon = ({ url, icon, iconActive, title, navigate }) => {
  const location = useLocation();
  const path = location.pathname;
  const intl = useIntl();

  return (
    <img
      className="navbar-icon me-2 cursor-pointer"
      src={toAbsoluteUrl(path === url ? iconActive : icon)}
      onClick={() => {
        if (navigate) {
          navigate(url);
        }
      }}
      onMouseOver={(e) => {
        e.currentTarget.src = toAbsoluteUrl(iconActive);
      }}
      onMouseLeave={(e) => {
        if (path !== url) e.currentTarget.src = toAbsoluteUrl(icon);
      }}
      alt={intl.formatMessage({ id: title })}
      title={intl.formatMessage({ id: title })}
    />
  );
};

export const MasterLayout = () => {
  const intl = useIntl();
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();
  const { auth, saveAuth } = useAuth();
  const [swalProps, setSwalProps] = useState({});
  const masterLayoutState = useSelector(
    ({ masterLayout }) => masterLayout,
    shallowEqual
  );

  const [isRefreshClick, setIsRefreshClick] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    LanguageDDLConst.find(
      (languageDDL) => languageDDL.language === auth.language
    )
  );
  const dispatch = useDispatch();
  const value = {};

  useEffect(() => {
    dispatch(actions.getWalletInfo());
  }, []);

  useEffect(() => {
    setSelectedLanguage(
      LanguageDDLConst.find(
        (languageDDL) => languageDDL.language === auth.language
      )
    );
  }, [auth]);

  useEffect(() => {
    if (selectedLanguage && selectedLanguage.language !== auth.language) {
      const queryParams = {
        language: selectedLanguage.language,
      };

      dispatch(actions.updateLanguage(queryParams)).then((resp) => {
        resp = resp.data;
        if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
          setSwalProps({
            show: true,
            icon: "success",
            title: intl.formatMessage({ id: "success" }),
            text: intl.formatMessage({ id: "changeLanguageSuccess" }),
            onResolve: () => {
              setLanguage(selectedLanguage.language);
              setSwalProps({});
              saveAuth({ ...auth, language: selectedLanguage.language });
            },
          });
        } else {
          setSwalProps({
            show: true,
            icon: "error",
            title: intl.formatMessage({ id: "error" }),
            text: resp.message,
            onResolve: () => {
              setSwalProps({});
              setSelectedLanguage(
                LanguageDDLConst.find(
                  (languageDDL) => languageDDL.language === auth.language
                )
              );
            },
          });
        }
      });
    }
  }, [selectedLanguage]);

  return (
    <MasterLayoutContext.Provider value={value}>
      <SweetAlert2
        onResolve={() => {
          setSwalProps({});
        }}
        backdrop={false}
        {...swalProps}
        customClass="custom-swal"
      />
      {swalProps.show && <Overlay zIndex={1} />}
      <div className="rc279-header">
        <div className="px-4 py-3">
          <div className="d-flex mt-3 justify-content-between align-items-center">
            <div className="d-flex">
              <img
                src={toAbsoluteUrl("images/logo.png")}
                alt="logo"
                style={{ width: "150px" }}
                className="me-4"
              />
              {Object.entries(NavBarConst).map(([key, value]) => (
                <div
                  className={`navbar-item cursor-pointer pb-2 ${
                    path === value ? "active" : ""
                  }`}
                  onClick={() => {
                    navigate(value);
                  }}
                  key={key}
                >
                  {intl.formatMessage({ id: key })}
                </div>
              ))}
            </div>
            <div className="d-flex align-items-center">
              <SettingIcon
                url="/setting/deposit"
                icon="images/icon/topup/topup.png"
                iconActive="images/icon/topup/topup_select.png"
                title="deposit"
                navigate={navigate}
              />
              <SettingIcon
                url="/setting/withdraw"
                icon="images/icon/withdraw/withdraw.png"
                iconActive="images/icon/withdraw/withdraw_select.png"
                title="withdraw"
                navigate={navigate}
              />
              <SettingIcon
                url="/setting/gameTransfer"
                icon="images/icon/transfer/gameTransfer.png"
                iconActive="images/icon/transfer/gameTransfer_select.png"
                title="gameTransfer"
                navigate={navigate}
              />
              <div className="d-flex">
                <div className="triangle-bottomright" />
                <div className="text-end ps-2 navbar-wallet d-flex align-items-center">
                  <div>
                    <div className="ws-nowrap">
                      {auth.username ? auth.username.toUpperCase() : null}
                    </div>
                    <div className="fw-bold ws-nowrap d-flex align-items-start">
                      <div className="text-start">
                        <div
                          className="me-1"
                          style={{ borderBottom: "1px solid black" }}
                        >
                          {auth.currencycode}{" "}
                          {masterLayoutState.walletInfo
                            ? masterLayoutState.walletInfo.balance
                            : intl.formatMessage({ id: "loading" })}
                        </div>
                        <div className="me-1" style={{ fontSize: "11px" }}>
                          {intl.formatMessage({ id: "game" })} :{" "}
                          {masterLayoutState.walletInfo
                            ? masterLayoutState.walletInfo.gamebalance
                            : intl.formatMessage({ id: "loading" })}
                        </div>
                      </div>
                      <i
                        style={{ marginTop: "0.3rem" }}
                        className={`fa fa-refresh cursor-pointer ${
                          isRefreshClick ? "spinning-animation" : ""
                        }`}
                        onClick={(e) => {
                          dispatch(actions.getWalletInfo());
                          setIsRefreshClick(true);

                          setTimeout(() => {
                            setIsRefreshClick(false);
                          }, 1000);
                        }}
                      />
                    </div>
                  </div>

                  <img
                    className="navbar-icon mx-2 cursor-pointer"
                    src={toAbsoluteUrl("images/icon/profile/user.png")}
                    style={{ height: "45px", width: "45px" }}
                    onClick={() => {
                      navigate("/setting/profile");
                    }}
                  />
                </div>
              </div>
              <div className="ms-3">
                <div className="dropdown">
                  <button
                    className="border-0"
                    style={{ background: "transparent" }}
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-text={intl.formatMessage({ id: "language" })}
                  >
                    <img
                      src={toAbsoluteUrl(
                        `images/language/${selectedLanguage.language}.png`
                      )}
                      alt={intl.formatMessage({
                        id: selectedLanguage.title,
                      })}
                      className="language-icon"
                    />
                  </button>
                  <div className="dropdown-menu w-100">
                    {LanguageDDLConst.map((languageDDL) => (
                      <div
                        className={`dropdown-item d-flex align-items-center py-2 cursor-pointer ${
                          selectedLanguage &&
                          selectedLanguage.language === languageDDL.language
                            ? "active"
                            : ""
                        }`}
                        key={languageDDL.language}
                        onClick={() => {
                          document
                            .querySelector(".dropdown-menu")
                            .classList.remove("show");
                          setSelectedLanguage(languageDDL);
                        }}
                      >
                        <img
                          src={toAbsoluteUrl(
                            `images/language/${languageDDL.language}.png`
                          )}
                          alt={intl.formatMessage({ id: languageDDL.title })}
                          className="me-4 language-icon"
                        />
                        <div>
                          {intl.formatMessage({ id: languageDDL.title })}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rc279-content">
        <Outlet />
        <img
          src={toAbsoluteUrl("images/icon/master/appDownloadIcon.png")}
          className="cursor-pointer position-absolute rounded-circle"
          onClick={() => navigate("/appDownload")}
          style={{
            width: "50px",
            height: "50px",
            bottom: "9rem",
            right: "2.3rem",
            border: "2px solid #ffa500",
          }}
        />
      </div>
    </MasterLayoutContext.Provider>
  );
};
