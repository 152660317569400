import { Field, Form, Formik } from "formik";
import { LoginTab } from "../../enum/LoginTab";
import { useRef, useState, useEffect } from "react";
import {
  forgotPasswordStep1Schema,
  forgotPasswordStep3Schema,
} from "../../yupSchema/forgotPasswordSchema";
import {
  fetchVerificationCode,
  forgotPasswordEmailStep1,
  forgotPasswordEmailStep2,
  forgotPasswordEmailStep3,
  forgotPasswordStep1,
  forgotPasswordStep2,
  forgotPasswordStep3,
} from "../../redux/LoginCRUD";
import OTPInput from "react-otp-input";
import { useIntl } from "react-intl";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";
import {
  ErrorContainer,
  Input,
  PhoneInputField,
} from "../../../common/components/control/InputField";
import { CheckIsNull, IsAlphaNumeric } from "../../../../utils/CommonHelper";
import Skeleton from "react-loading-skeleton";

export const ForgotPasswordTab = ({ setLoginTab, setSwalProps, loginTab }) => {
  const dateNow = Date.now();
  const formikRef = useRef();
  const codeNum = useRef("");
  const intl = useIntl();
  const [isPhone, setIsPhone] = useState(true);
  const [forgotPasswordDetail, setForgotPasswordDetail] = useState();
  const [isShowPhoneNoError, setIsShowPhoneNoError] = useState(false);
  const [verifyImage, setVerifyImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [isShowOtpError, setIsShowOTPError] = useState(false);
  const [otpErrorDescription, setOtpErrorDescription] = useState("");
  const [phoneNoErrorDescription, setPhoneNoErrorDescription] = useState("");
  const steps = [
    LoginTab.FORGOT_PASSWORD_PART_1,
    LoginTab.FORGOT_PASSWORD_PART_2,
    LoginTab.FORGOT_PASSWORD_PART_3,
  ];

  const getVerificationCode = () => {
    setVerifyImageUrl("");
    codeNum.current = "";
    fetchVerificationCode().then((resp) => {
      setVerifyImageUrl(resp.imageurl);
      codeNum.current = resp.codenum;
    });
  };

  useEffect(() => {
    getVerificationCode();
  }, []);

  const handleChangeMethod = (isEmail) => {
    if (loginTab === LoginTab.FORGOT_PASSWORD_PART_1 && isPhone === isEmail) {
      setIsPhone(!isEmail);
      setForgotPasswordDetail(undefined);
      setIsShowPhoneNoError(false);
      setIsShowOTPError(false);
      setOtpErrorDescription("");
      setPhoneNoErrorDescription("");
      formikRef.current.resetForm();
    }
  };

  return (
    <>
      <h3 className="mb-5 text-center">
        {intl.formatMessage({ id: "forgotPassword" })}
      </h3>
      <div className="d-flex rounded w-100 border mb-4">
        <div
          className={`border-end w-50 p-2 text-center ${
            loginTab === LoginTab.FORGOT_PASSWORD_PART_1 ? "cursor-pointer" : ""
          } ${isPhone ? "bg-prim fw-bold text-dark" : ""}`}
          onClick={() => handleChangeMethod(false)}
        >
          {intl.formatMessage({ id: "phoneNo" })}
        </div>
        <div
          className={`w-50 p-2 text-center ${
            loginTab === LoginTab.FORGOT_PASSWORD_PART_1 ? "cursor-pointer" : ""
          } ${!isPhone ? "bg-prim fw-bold text-dark" : ""}`}
          onClick={() => handleChangeMethod(true)}
        >
          {intl.formatMessage({ id: "email" })}
        </div>
      </div>
      <div className="d-flex justify-content-center mb-3">
        <div className="d-flex align-items-center justify-content-between steps w-50">
          {steps.map((step, index) => (
            <div
              key={step}
              className={`fw-bold step rounded-circle d-flex align-items-center justify-content-center ${
                step === loginTab ? "active" : ""
              }`}
            >
              {index + 1}
            </div>
          ))}
        </div>
      </div>
      {loginTab === LoginTab.FORGOT_PASSWORD_PART_1 ? (
        <Formik
          initialValues={{
            phoneNo: isPhone
              ? forgotPasswordDetail
                ? forgotPasswordDetail.telno
                : ""
              : undefined,
            email: !isPhone
              ? forgotPasswordDetail
                ? forgotPasswordDetail.email
                : ""
              : undefined,
            verifyCode: "",
          }}
          innerRef={formikRef}
          validationSchema={forgotPasswordStep1Schema(intl, isPhone)}
          enableReinitialize={true}
          onSubmit={(values, formikHelpers) => {
            if (codeNum.current === values.verifyCode) {
              setIsLoading(true);
              const queryParams = {
                telno: isPhone ? values.phoneNo : undefined,
                email: !isPhone ? values.email : undefined,
                uniqueid: dateNow,
                language: intl.locale,
              };

              setForgotPasswordDetail(queryParams);

              const step1 = isPhone
                ? forgotPasswordStep1
                : forgotPasswordEmailStep1;

              step1(queryParams).then((resp) => {
                setIsLoading(false);
                getVerificationCode();
                formikHelpers.setFieldValue("verifyCode", "");
                if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
                  setLoginTab(LoginTab.FORGOT_PASSWORD_PART_2);
                } else {
                  if (isPhone) {
                    setIsShowPhoneNoError(true);
                    setPhoneNoErrorDescription(resp.message);
                  } else {
                    formikHelpers.setFieldError("email", resp.message);
                  }
                }
              });
            } else {
              formikHelpers.setFieldError(
                "verifyCode",
                intl.formatMessage({ id: "incorrectVerificationCode" })
              );
            }
          }}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            touched,
            errors,
            setFieldError,
            setFieldTouched,
          }) => (
            <Form>
              <div className="mb-2">
                <label className="mb-2 text-input-label">
                  {intl.formatMessage({ id: isPhone ? "phoneNo" : "email" })}
                </label>
                {isPhone ? (
                  <PhoneInputField
                    values={values}
                    setFieldValue={setFieldValue}
                    setIsShowPhoneNoError={setIsShowPhoneNoError}
                    isShowPhoneNoError={isShowPhoneNoError}
                    phoneNoErrorDescription={phoneNoErrorDescription}
                    setPhoneNoErrorDescription={setPhoneNoErrorDescription}
                    name="phoneNo"
                  />
                ) : (
                  <Field
                    name="email"
                    component={Input}
                    placeholder={intl.formatMessage({ id: "email" })}
                    autoComplete="off"
                    readOnly={isLoading}
                  />
                )}
              </div>
              <div className="mb-2 d-flex align-items-center">
                {CheckIsNull(verifyImage) ? (
                  <Skeleton className="col-4" style={{ height: "60px" }} />
                ) : (
                  <>
                    <img
                      src={verifyImage}
                      alt="verifyImage"
                      className="col-4 me-4"
                      style={{ height: "60px" }}
                    />
                    <i
                      className="fa fa-refresh cursor-pointer"
                      onClick={() => getVerificationCode()}
                    />
                  </>
                )}
              </div>
              <div className="mb-2">
                <Field
                  name="verifyCode"
                  component={Input}
                  placeholder={intl.formatMessage({ id: "verifyCode" })}
                  autoComplete="off"
                  readOnly={isLoading}
                />
              </div>
              <div className="mt-4 mb-2">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="btn btn-prim fw-bold w-100"
                >
                  <div className="d-flex align-items-center justify-content-center">
                    {isLoading && (
                      <div
                        className="spinner-border text-dark"
                        role="status"
                        style={{ scale: "0.75" }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                    {intl.formatMessage({ id: "requestOTP" })}
                  </div>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      ) : loginTab === LoginTab.FORGOT_PASSWORD_PART_2 ? (
        <>
          <div className="mb-4 text-center">
            {intl.formatMessage(
              { id: isPhone ? "otpHasSended" : "otpHasSendedEmail" },
              {
                n: isPhone
                  ? forgotPasswordDetail.telno
                  : forgotPasswordDetail.email,
              }
            )}
          </div>
          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={5}
            containerStyle="justify-content-center"
            shouldAutoFocus={true}
            renderSeparator={<div className="mx-2 mb-3"></div>}
            renderInput={(props) => (
              <input {...props} className="form-control otp-field" />
            )}
          />
          <div className="d-flex justify-content-center mt-2">
            <ErrorContainer
              isShow={isShowOtpError}
              errorMsg={otpErrorDescription}
            />
          </div>
          <button
            type="submit"
            onClick={() => {
              if (!CheckIsNull(otp)) {
                setIsShowOTPError(false);
                setIsLoading(true);

                const step2 = isPhone
                  ? forgotPasswordStep2
                  : forgotPasswordEmailStep2;

                step2({
                  ...forgotPasswordDetail,
                  otpcode: otp,
                }).then((resp) => {
                  setIsLoading(false);
                  setOtp("");
                  if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
                    setLoginTab(LoginTab.FORGOT_PASSWORD_PART_3);
                  } else {
                    setIsShowOTPError(true);
                    setOtpErrorDescription(resp.message);
                  }
                });
              } else {
                setIsShowOTPError(true);
                setOtpErrorDescription(
                  intl.formatMessage({ id: "otpRequired" })
                );
              }
            }}
            disabled={isLoading}
            className="btn btn-prim fw-bold w-100 mt-4"
          >
            <div className="d-flex align-items-center justify-content-center">
              {isLoading && (
                <div
                  className="spinner-border text-dark"
                  role="status"
                  style={{ scale: "0.75" }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
              {intl.formatMessage({ id: "submit" })}
            </div>
          </button>
        </>
      ) : (
        <Formik
          initialValues={{
            newPassword: "",
            confirmPassword: "",
          }}
          innerRef={formikRef}
          validationSchema={forgotPasswordStep3Schema(intl)}
          enableReinitialize={true}
          onSubmit={(values, formikHelpers) => {
            if (values.newPassword === values.confirmPassword) {
              let queryParams = {
                ...forgotPasswordDetail,
                password: values.newPassword,
              };
              setIsLoading(true);

              const step3 = isPhone
                ? forgotPasswordStep3
                : forgotPasswordEmailStep3;

              step3(queryParams).then((resp) => {
                setIsLoading(false);
                if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
                  setSwalProps({
                    show: true,
                    icon: "success",
                    title: intl.formatMessage({ id: "success" }),
                    text: intl.formatMessage({ id: "resetSuccess" }),
                  });
                  setLoginTab(LoginTab.LOGIN);
                } else {
                  formikHelpers.setFieldError("confirmPassword", resp.message);
                }
              });
            } else {
              formikHelpers.setFieldError(
                "confirmPassword",
                intl.formatMessage({ id: "invalidConfirmPassword" })
              );
            }
          }}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            touched,
            errors,
            setFieldError,
          }) => (
            <Form>
              <div className="mb-2">
                <Field
                  name="newPassword"
                  component={Input}
                  placeholder={intl.formatMessage({ id: "newPassword" })}
                  label={intl.formatMessage({ id: "newPassword" })}
                  autoComplete="off"
                  type="password"
                  onKeyDown={(e) => {
                    if (!IsAlphaNumeric(e)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <div className="mb-2">
                <Field
                  name="confirmPassword"
                  component={Input}
                  placeholder={intl.formatMessage({ id: "confirmPassword" })}
                  label={intl.formatMessage({ id: "confirmPassword" })}
                  autoComplete="off"
                  type="password"
                  onKeyDown={(e) => {
                    if (!IsAlphaNumeric(e)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <div className="mt-4 mb-2">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="btn btn-prim fw-bold w-100"
                >
                  <div className="d-flex align-items-center justify-content-center">
                    {isLoading && (
                      <div
                        className="spinner-border text-dark"
                        role="status"
                        style={{ scale: "0.75" }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                    {intl.formatMessage({ id: "submit" })}
                  </div>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
