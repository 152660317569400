import { useEffect } from "react";
import { useIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../utils/CommonHelper";
import {
  LotteryNavConst,
  LotteryNavTitle,
} from "../../../constants/LotteryNavConst";
import { LotteryResultListing } from "./lotteryResult/LotteryResultListing";
import { LotteryBetDialog } from "./lotteryBet/LotteryBetDialog";
import { useNavigate } from "react-router-dom";
import { LotteryPayout } from "./lotteryPayout/LotteryPayout";
import { LotteryHistory } from "./lotteryHistory/LotteryHistory";
import { LotteryList } from "./lotteryList/LotteryList";

export const LotteryListing = ({ selectedTab }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add("pages-bg", "h-100", "scroll-y-default");
    document.querySelector("html").classList.add("h-100", "scroll-y-default");

    document.body.style.backgroundImage = `url("${toAbsoluteUrl(
      "images/pagesBg/lottery.png"
    )}")`;

    return () => {
      document.body.classList.remove("pages-bg", "h-100", "scroll-y-default");
      document.body.style.backgroundImage = ``;
      document
        .querySelector("html")
        .classList.remove("h-100", "scroll-y-default");
    };
  }, []);

  return (
    <>
      <div className="container my-5">
        <div className="d-flex mb-4 mx-5 px-5">
          {LotteryNavConst.map((nav, index) => (
            <div
              className={` lottery-nav ${
                selectedTab === nav.title ? "active fw-bold" : ""
              } text-center p-3 cursor-pointer`}
              onClick={() => {
                navigate(nav.navigate);
              }}
              style={{ width: "20%" }}
              key={index}
            >
              {intl.formatMessage({ id: nav.title })}
            </div>
          ))}
        </div>
        <div className="card">
          <div className="card-body">
            {selectedTab === LotteryNavTitle.RESULT ? (
              <LotteryResultListing />
            ) : selectedTab === LotteryNavTitle.BET ? (
              <LotteryBetDialog />
            ) : selectedTab === LotteryNavTitle.PAYOUT ? (
              <LotteryPayout />
            ) : selectedTab === LotteryNavTitle.REPORT ? (
              <LotteryHistory />
            ) : selectedTab === LotteryNavTitle.SLIP ? (
              <LotteryList />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
