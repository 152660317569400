import { authPost } from "../../common/redux/ApiClient";

const baseUrl = "http://34.87.51.38/ok99memberApi/api";

export function fetchFishingPageUI(params) {
  return authPost(`${baseUrl}/MemberPc2/PcFishingPageUI`, params);
}

export function fetchFishingGameList(params) {
  return authPost(`${baseUrl}/MemberPc2/PcFishingGameList`, params);
}

export function companyPlayGame(params) {
  return authPost(`${baseUrl}/Member3/CompanyPlayGame`, params);
}
