import { useIntl } from "react-intl";

export const TermsAndCondition = () => {
  const intl = useIntl();

  const lineBreakText = (text) => {
    return intl
      .formatMessage({ id: text })
      .replaceAll(".", ".<br>")
      .replaceAll("。", "。<br>");
  };

  const NumberingText = ({ text, numbering }) => (
    <div className="d-flex mb-2 mt-3 fw-bold">
      <div className="me-1" style={{ width: "20px" }}>
        {numbering}.
      </div>
      <div>{intl.formatMessage({ id: text })}</div>
    </div>
  );

  return (
    <>
      <div className="mb-1">
        {intl.formatMessage({ id: "userAgreementDescription_1" })}
      </div>
      <NumberingText text="userAgreementTitle_1" numbering="1" />
      <div
        dangerouslySetInnerHTML={{
          __html: lineBreakText("userAgreementDescription_2"),
        }}
      />
      <NumberingText text="userAgreementTitle_2" numbering="2" />
      <div
        dangerouslySetInnerHTML={{
          __html: lineBreakText("userAgreementDescription_3"),
        }}
      />
      <NumberingText text="userAgreementTitle_3" numbering="3" />
      <div
        dangerouslySetInnerHTML={{
          __html: lineBreakText("userAgreementDescription_4"),
        }}
      />
      <ul>
        <li>{intl.formatMessage({ id: "userAgreementSubDescription_1" })}</li>
        <li>{intl.formatMessage({ id: "userAgreementSubDescription_2" })}</li>
        <li>{intl.formatMessage({ id: "userAgreementSubDescription_3" })}</li>
      </ul>
      <div
        dangerouslySetInnerHTML={{
          __html: lineBreakText("userAgreementDescription_5"),
        }}
      />
      <ul>
        <li>{intl.formatMessage({ id: "userAgreementSubDescription_5" })}</li>
        <li>{intl.formatMessage({ id: "userAgreementSubDescription_6" })}</li>
        <li>{intl.formatMessage({ id: "userAgreementSubDescription_7" })}</li>
      </ul>
      <NumberingText text="userAgreementTitle_4" numbering="4" />
      <div
        dangerouslySetInnerHTML={{
          __html: lineBreakText("userAgreementDescription_6"),
        }}
      />
      <NumberingText text="userAgreementTitle_5" numbering="5" />
      <div
        dangerouslySetInnerHTML={{
          __html: lineBreakText("userAgreementDescription_7"),
        }}
      />
      <NumberingText text="userAgreementTitle_6" numbering="6" />
      <div
        dangerouslySetInnerHTML={{
          __html: lineBreakText("userAgreementDescription_8"),
        }}
      />
    </>
  );
};
