import * as Yup from "yup";

export const GoogleRegisterSchema = (intl) => {
  return Yup.object().shape({
    referralCode: Yup.string()
      .nullable(false)
      .required(intl.formatMessage({ id: "referralCodeRequired" })),
  });
};

export const RegisterStep1Schema = (intl) => {
  return Yup.object().shape({
    referralCode: Yup.string()
      .nullable(false)
      .required(intl.formatMessage({ id: "referralCodeRequired" })),
    verifyCode: Yup.string()
      .nullable(false)
      .required(intl.formatMessage({ id: "verifyCodeRequired" })),
  });
};

export const RegisterStep3Schema = (intl) => {
  return Yup.object().shape({
    username: Yup.string()
      .nullable(false)
      .required(intl.formatMessage({ id: "usernameRequired" })),
    userPassword: Yup.string()
      .nullable(false)
      .required(intl.formatMessage({ id: "passwordRequired" })),
  });
};
