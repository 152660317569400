import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/SettingAction";
import Skeleton from "react-loading-skeleton";
import { CheckIsNull } from "../../../../utils/CommonHelper";

export const Downline = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const settingState = useSelector(({ setting }) => setting, shallowEqual);

  useEffect(() => {
    dispatch(actions.getDownline());
  }, []);

  return (
    <>
      <h5 className="mb-4">{intl.formatMessage({ id: "downline" })}</h5>
      {settingState.downline ? (
        <>
          {settingState.listLoading ? (
            <div className="d-flex align-items-center justify-content-center py-5">
              <div
                className="spinner-border"
                role="status"
                style={{ scale: "1.2" }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div style={{ minHeight: "600px" }}>
              {settingState.downline.downlineLists.length > 0 ? (
                <div>
                  {settingState.downline.downlineLists.map(
                    (downline, index) => (
                      <div className="card mb-3" key={index}>
                        <div
                          className="card-body rounded"
                          style={{ background: "rgba(0,0,0,0.2)" }}
                        >
                          <div className="fw-bold fs-5">
                            {downline.username}
                          </div>
                          <div className="d-flex mt-1">
                            {downline.regtelemail && (
                              <>
                                <div className="me-1 text-muted">
                                  {intl.formatMessage({
                                    id: isNaN(parseInt(downline.regtelemail))
                                      ? "email"
                                      : "phoneNo",
                                  })}{" "}
                                  :
                                </div>
                                <div>{downline.regtelemail}</div>
                              </>
                            )}
                            <div className="ms-3 me-1 text-muted">
                              {intl.formatMessage({ id: "currency" })} :
                            </div>
                            <div>{downline.currencycode}</div>
                          </div>
                          <div
                            className="fst-italic mt-2 d-flex"
                            style={{ fontSize: "0.75rem" }}
                          >
                            <div className="me-1 text-muted">
                              {intl.formatMessage({ id: "joinDate" })} :
                            </div>
                            <div>{downline.joindate}</div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              ) : (
                <div className="d-flex py-5 align-items-center justify-content-center">
                  <div className="text-muted d-flex align-items-center justify-content-center flex-column">
                    <i className="bi bi-search fs-1 mb-3" />
                    <div>{intl.formatMessage({ id: "noRecordFound" })}</div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          <Skeleton height="600px" className="w-100" />
        </>
      )}
    </>
  );
};
