import { useIntl } from "react-intl";
import { Slide } from "react-slideshow-image";
import Skeleton from "react-loading-skeleton";

export const SlideTab = ({ slideList }) => {
  const intl = useIntl();

  return (
    <div className="h-100 d-flex align-items-center">
      <div className="w-100">
        {slideList.length > 0 ? (
          <Slide
            duration={2000}
            slidesToScroll={1}
            arrows={false}
            cssClass="shadow-lg"
          >
            {slideList.map((slide, index) => (
              <div
                key={index}
                className="login-slide rounded"
                style={{ backgroundImage: `url(${slide.imageurl})` }}
              />
            ))}
          </Slide>
        ) : (
          <Skeleton className="w-100 login-slide rounded" />
        )}

        <h1 className="text-center mt-5 mb-3">
          {intl.formatMessage({ id: "welcomeTo29rc" })}
        </h1>
        <h3 className="text-center">
          {intl.formatMessage({ id: "welcomeDescription" })}
        </h3>
      </div>
    </div>
  );
};
