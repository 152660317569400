import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";
import * as requestToApi from "./MasterLayoutCRUD";
import { masterLayoutSlice } from "./MasterLayoutSlice";

const { actions } = masterLayoutSlice;

export const getWalletInfo = () => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));
  return requestToApi
    .fetchWalletInfo()
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.walletInfoFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchWalletInfo");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const updateLanguage = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .changeLanguage(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.editLanguage({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: changeLanguage");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
