/* eslint-disable jsx-a11y/anchor-is-valid */
import { Navigate, Route, Routes } from "react-router-dom";
import { createContext } from "react";
import { Setting } from "../../modules/setting/component/Setting";
import { SettingNavTitle } from "../../constants/SettingNavConst";

const SettingContext = createContext();

const SettingWrapper = () => {
  const value = {};

  return (
    <SettingContext.Provider value={value}>
      <Routes>
        <Route
          path="/profile"
          element={<Setting selectedTab={SettingNavTitle.PROFILE} />}
        />
        <Route
          path="/termsAndCondition"
          element={<Setting selectedTab={SettingNavTitle.TERMSANDCONDITION} />}
        />
        <Route
          path="/deposit"
          element={<Setting selectedTab={SettingNavTitle.DEPOSIT} />}
        />
        <Route
          path="/withdraw"
          element={<Setting selectedTab={SettingNavTitle.WITHDRAW} />}
        />
        <Route
          path="/gameTransfer"
          element={<Setting selectedTab={SettingNavTitle.TRANSFER} />}
        />
        <Route
          path="/gameReport"
          element={<Setting selectedTab={SettingNavTitle.GAME_REPORT} />}
        />
        <Route
          path="/memberTransfer"
          element={<Setting selectedTab={SettingNavTitle.MEMBER_TRANSFER} />}
        />
        <Route
          path="/transactionReport"
          element={<Setting selectedTab={SettingNavTitle.TRANSACTION_REPORT} />}
        />
        <Route
          path="/gameReport"
          element={<Setting selectedTab={SettingNavTitle.GAME_REPORT} />}
        />
        <Route
          path="/downline"
          element={<Setting selectedTab={SettingNavTitle.DOWNLINE} />}
        />
        <Route
          path="/pointShop"
          element={<Setting selectedTab={SettingNavTitle.POINT_SHOP} />}
        />
        <Route
          path="/bonus"
          element={<Setting selectedTab={SettingNavTitle.BONUS} />}
        />
        <Route
          path="/exchange"
          element={<Setting selectedTab={SettingNavTitle.EXCHANGE} />}
        />
        <Route
          path="/inbox"
          element={<Setting selectedTab={SettingNavTitle.INBOX} />}
        />
        <Route path="/*" element={<Navigate to="profile" />} />
      </Routes>
    </SettingContext.Provider>
  );
};

export { SettingWrapper };
