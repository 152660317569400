import { useEffect, useState } from "react";
import { Img } from "react-image";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { GameCategoryConst } from "../../../constants/GameCategory";

const Image = ({ imageUrl, name, className, onClick }) => {
  return (
    <div style={{ position: "relative", height: "139px" }} onClick={onClick}>
      <Img
        src={imageUrl}
        unloader={<img className={className} alt={name} src={imageUrl} />}
        className={className}
        alt={name}
        loading="lazy"
        loader={<Skeleton className="w-100 h-100" />}
      />
    </div>
  );
};

const GameItem = ({ game, handlePlayGame, searchName }) => {
  const intl = useIntl();
  const [isShowPlay, setIsShowPlay] = useState(false);

  return (
    <div className="mb-4" style={{ width: "20%" }}>
      <div
        className="rounded company-active position-relative"
        onMouseEnter={() => setIsShowPlay(true)}
        onMouseLeave={() => setIsShowPlay(false)}
      >
        <div className="p-2">
          <Image
            imageUrl={game.imageurl}
            alt={game.gamename}
            className="w-100 mb-2"
          />
          <div className="text-center text-truncate px-2">{game.gamename}</div>
        </div>
        {isShowPlay && (
          <div
            className="position-absolute w-100 top-0 bottom-0 rounded d-flex flex-column align-items-center justify-content-center"
            style={{ background: "rgba(0,0,0,0.5)", zIndex: "2" }}
          >
            <div className="text-center mb-3 px-2">{game.gamename}</div>
            <button
              type="button"
              className="btn btn-info fw-bold"
              onClick={() => handlePlayGame(game.gamecode, searchName)}
            >
              {intl.formatMessage({ id: "play" })}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export const GameListing = ({
  pageUI,
  gameList,
  gameCategoryList,
  setCategory,
  category,
  companyCode,
  setCompanyCode,
  handlePlayGame,
  companyOffset = 0,
  setCompanyOffset,
  initialSearchName,
  isShowBtn = true,
}) => {
  const [gameName, setGamename] = useState(initialSearchName ?? "");
  const [offset, setOffset] = useState(companyOffset);
  const [isShowScrollTop, setIsShowScrollTop] = useState(false);
  const intl = useIntl();

  const backToTop = () => {
    document.body.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setCompanyOffset(offset);
  }, [offset]);

  useEffect(() => {
    document.body.addEventListener("scroll", () => {
      if (document.body.scrollTop > 300) {
        setIsShowScrollTop(true);
      } else {
        setIsShowScrollTop(false);
      }
    });
  }, []);

  return (
    <div>
      {isShowScrollTop && (
        <button
          type="button"
          className="btn btn-prim position-absolute border-0 p-0 d-flex align-items-center justify-content-center"
          style={{
            right: "2.3rem",
            bottom: "5.5rem",
            width: "50px",
            height: "50px",
          }}
          onClick={() => backToTop()}
        >
          <i className="bi bi-arrow-up-short fs-1 mb-2" />
        </button>
      )}

      <div className="container py-4 game-list">
        {pageUI && gameList ? (
          <>
            <CompanyList
              companyList={pageUI.companyLists}
              setCompanyCode={setCompanyCode}
              setCategory={setCategory}
              offset={offset}
              companyCode={companyCode}
              setOffset={setOffset}
              isShowBtn={isShowBtn}
            />
            <div className="card">
              <div className="card-body d-flex flex-column">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <div className="d-flex flex-fill scroll-x me-4 py-1">
                    {gameCategoryList.map((gameCategory, index) => (
                      <div
                        className={`px-4 py-1 text-center ${
                          index + 1 === gameCategoryList.length ? "" : "me-4"
                        } cursor-pointer rounded-pill ${
                          category === gameCategory.category
                            ? "category-active fw-bold"
                            : ""
                        }`}
                        onClick={() => setCategory(gameCategory.category)}
                        key={index}
                      >
                        {gameCategory.name}
                      </div>
                    ))}
                  </div>
                  <div className="col-3">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={intl.formatMessage({ id: "search" })}
                        aria-describedby="basic-addon1"
                        value={gameName}
                        onChange={(e) => {
                          setGamename(e.target.value);
                        }}
                      />
                      <span className="input-group-text" id="basic-addon1">
                        <i className="fa fa-search" />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="w-100 flex-fill row">
                  {gameList.filter((game) =>
                    game.gamename.toLowerCase().includes(gameName.toLowerCase())
                  ).length > 0 ? (
                    gameList
                      .filter((game) =>
                        game.gamename
                          .toLowerCase()
                          .includes(gameName.toLowerCase())
                      )
                      .map((game, index) => (
                        <GameItem
                          game={game}
                          searchName={gameName}
                          key={index}
                          handlePlayGame={handlePlayGame}
                        />
                      ))
                  ) : (
                    <div className="d-flex py-5 align-items-center justify-content-center">
                      <div className="text-muted d-flex align-items-center justify-content-center flex-column">
                        <i className="bi bi-search fs-1 mb-3" />
                        <div>{intl.formatMessage({ id: "noRecordFound" })}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <Skeleton className="w-100 mb-5 mt-5" height="100px" />
            <Skeleton className="w-100" height="600px" />
          </>
        )}
      </div>
    </div>
  );
};

export const CompanyList = ({
  companyList,
  setCompanyCode,
  offset,
  setOffset,
  companyCode,
  isShowBtn,
  setCategory,
}) => {
  return companyList ? (
    <div className="d-flex mb-5 align-items-center">
      {isShowBtn && (
        <i
          className="fa fa-caret-left cursor-pointer fs-3"
          onClick={() => {
            setOffset((prev) =>
              prev === 0 ? companyList.length - 1 : prev - 1
            );
          }}
        />
      )}
      <div
        className={`d-flex align-items-center ws-nowrap flex-fill overflow-hidden ${
          isShowBtn ? "mx-4" : ""
        }`}
      >
        {companyList
          .slice(offset)
          .concat(companyList.slice(0, offset))
          .map((company, index) => (
            <div className="col-2 ps-1 cursor-pointer" key={index}>
              <Image
                imageUrl={company.imageurl}
                name={company.companyname}
                className={`w-100 rounded ${
                  companyCode === company.companycode ? "company-active" : ""
                }`}
                onClick={() => {
                  if (setCategory) {
                    setCategory(GameCategoryConst.ALL);
                  }
                  setCompanyCode(company.companycode);
                }}
              />
            </div>
          ))}
      </div>
      {isShowBtn && (
        <i
          className="fa fa-caret-right cursor-pointer fs-3"
          onClick={() => {
            setOffset((prev) => (prev !== companyList.length ? prev + 1 : 1));
          }}
        />
      )}
    </div>
  ) : (
    <Skeleton className="w-100 mb-5 mt-5" height="100px" />
  );
};
