import { useState } from "react";
import { useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import SweetAlert2 from "react-sweetalert2";
import { useEffect } from "react";
import * as actions from "../../../redux/SettingAction";
import * as masterActions from "../../../../common/redux/MasterLayoutAction";
import { Link } from "react-router-dom";
import { WithdrawForm } from "./WithdrawForm";
import {
  CheckIsNull,
  ResolveArrayNull,
} from "../../../../../utils/CommonHelper";
import Skeleton from "react-loading-skeleton";
import { BankAccountDialog } from "./bankAccountDialog/BankAccountListDialog";
import { ApiStatusTypeEnum } from "../../../../../enumeration/ApiStatusTypeEnum";

export const WithdrawDialog = () => {
  const [selectedBank, setSelectedBank] = useState(0);
  const [swalProps, setSwalProps] = useState({});
  const intl = useIntl();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const settingState = useSelector(({ setting }) => setting, shallowEqual);

  const saveValues = (queryParams) => {
    const newQuery = {
      ...queryParams,
      bankitemid: settingState.withdrawUI.bankAccountLists[selectedBank].itemid,
    };

    dispatch(actions.submitWithdrawRequest(newQuery)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "withdrawRequestSendSuccessfully" }),
          onResolve: () => {
            setSwalProps({});
            formikRef.current.resetForm();
            dispatch(actions.getWithdrawUI());
            dispatch(masterActions.getWalletInfo());
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  useEffect(() => {
    dispatch(actions.getWithdrawUI());
  }, []);

  useEffect(() => {
    if (
      settingState.withdrawUI &&
      !CheckIsNull(settingState.withdrawUI.lastrejectreason)
    ) {
      setSwalProps({
        show: true,
        icon: "error",
        title: intl.formatMessage({ id: "lastRequestRejected" }),
        html: `<div class="text-prim fw-bold mb-4">${settingState.withdrawUI.lastrequestdate}</div><div>${settingState.withdrawUI.lastrejectreason}</div>`,
      });
    }
  }, [settingState.withdrawUI]);

  return (
    <>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      {settingState.withdrawUI ? (
        <>
          <BankAccountDialog
            selectedBank={selectedBank}
            setSelectedBank={setSelectedBank}
            fullname={settingState.withdrawUI.fullname}
            bankList={settingState.withdrawUI.bankAccountLists}
          />
          {settingState.withdrawUI.bankAccountLists.length > 0 ? (
            <div
              className="rounded custom-border p-3 d-flex justify-content-between align-items-center cursor-pointer"
              style={{ background: "rgba(0,0,0,0.2)" }}
              data-bs-toggle="modal"
              data-bs-target="#bankAccountModal"
            >
              <div>
                <div className="text-muted small">
                  {intl.formatMessage({ id: "bankName" })}
                </div>
                <div className="mb-2  fw-bold">
                  {
                    settingState.withdrawUI.bankAccountLists[selectedBank]
                      .bankname
                  }
                </div>
                <div className="text-muted small">
                  {intl.formatMessage({ id: "fullName" })}
                </div>
                <div className="mb-2  fw-bold">
                  {settingState.withdrawUI.fullname}
                </div>
                <div className="text-muted small">
                  {intl.formatMessage({ id: "accountNumber" })}
                </div>
                <div className="fw-bold">
                  {
                    settingState.withdrawUI.bankAccountLists[selectedBank]
                      .accountnum
                  }
                </div>
              </div>
              <div>
                <i className="fa fa-angle-right fs-1 me-4" />
              </div>
            </div>
          ) : (
            <div
              className="rounded custom-border p-3 text-center"
              style={{ background: "rgba(0,0,0,0.2)" }}
            >
              <div className="mb-4">
                {intl.formatMessage({ id: "noWithdrawalAccount" })}
              </div>
              <Link to="/setting/profile">
                {intl.formatMessage({ id: "goToProfile" })}
              </Link>
            </div>
          )}
          <div className="mt-4">
            <WithdrawForm
              formikRef={formikRef}
              saveValues={saveValues}
              mainWallet={settingState.withdrawUI.balance}
              floatingBalance={settingState.withdrawUI.withdrawlock}
            />
          </div>
          <button
            type="button"
            disabled={
              settingState.actionsLoading ||
              ResolveArrayNull(settingState.withdrawUI?.bankAccountLists)
                .length <= 0
            }
            className="btn btn-prim fw-bold px-3 w-100 my-4"
            onClick={() => formikRef.current.submitForm()}
          >
            <div className="d-flex align-items-center justify-content-center">
              {settingState.actionsLoading && (
                <div
                  className="spinner-border text-dark"
                  role="status"
                  style={{ scale: "0.75" }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
              {intl.formatMessage({ id: "submit" })}
            </div>
          </button>
          <div className="custom-border-bottom mb-4" />
          <div className="text-muted">
            <div className="fw-bold mb-2">
              {intl.formatMessage({ id: "withdrawalDescriptionTitle" })} :
            </div>
            <div className="fw-semibold mb-1">
              {intl.formatMessage({ id: "withdrawalDescriptionTitle_1" })}
            </div>
            <div className="mb-2">
              {intl.formatMessage({ id: "withdrawalDescription_1" })}
            </div>
            <div className="fw-semibold mb-1">
              {intl.formatMessage({ id: "withdrawalDescriptionTitle_2" })}
            </div>
            <div className="mb-2">
              {intl.formatMessage({ id: "withdrawalDescription_2" })}
            </div>
            <div className="fw-semibold mb-1">
              {intl.formatMessage({ id: "withdrawalDescriptionTitle_3" })}
            </div>
            <div className="mb-4">
              {intl.formatMessage({ id: "withdrawalDescription_3" })}
            </div>

            <div className="fw-bold mb-2">
              {intl.formatMessage({ id: "withdrawalNoteTitle" })}
            </div>
            <div className="mb-1 d-flex">
              <i className="bi bi-dot"></i>
              <div>
                {intl.formatMessage({ id: "withdrawalNoteDescription_1" })}
              </div>
            </div>
            <div className="mb-1 d-flex">
              <i className="bi bi-dot"></i>
              <div>
                {intl.formatMessage({ id: "withdrawalNoteDescription_2" })}
              </div>
            </div>
            <div className="mb-1">
              {intl.formatMessage({ id: "withdrawalNoteDescription_3" })}
            </div>
            <div className="mb-1">
              {intl.formatMessage({ id: "withdrawalNoteDescription_4" })}
            </div>
          </div>
        </>
      ) : (
        <>
          <Skeleton className="w-100" height="600px" />
        </>
      )}
    </>
  );
};
