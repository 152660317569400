import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";
import * as requestToApi from "./SettingCRUD";
import { settingSlice } from "./SettingSlice";

const { actions } = settingSlice;

//#region start : profile
export const getProfile = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchProfile(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.profileFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchProfile");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const getCountryBankList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchCountryBankList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.countryBankListFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchCountryBankList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const getCurrencyList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchCurrencyList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.currencyListFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchCurrencyList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const updatePassword = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .changePassword(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.editPassword({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: changePassword");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

export const updateCurrencyCode = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .switchCurrencyCode(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.editCurrencyCode({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: switchCurrencyCode");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

export const updateLanguage = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .changeLanguage(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.editLanguage({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: changeLanguage");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

export const insertBankAccount = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .addBankAccount(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.createBankAccount({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: addBankAccount");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

export const deleteBankAccount = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .removeBankAccount(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.deleteBankAccount({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: removeBankAccount");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
//#endregion end : profile

//#region start : game transfer
export const getGameCompanyTransferUI = () => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchGameCompanyTransferUI()
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(
            actions.gameCompanyTransferUIFetched({ data: response.data })
          );
          return response;
        }
      } else {
        throw new Error("Error api: fetchGameCompanyTransferUI");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const transferGameBalanceToWallet = () => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .transferAllGameBackWallet()
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(
            actions.gameBalanceToWalletTransfered({ data: response.data })
          );
          return response;
        }
      } else {
        throw new Error("Error api: transferAllGameBackWallet");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

export const getCompanyGameBalance = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.DETAIL }));

  return requestToApi
    .fetchCompanyGameBalance(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.companyGameBalanceFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchCompanyGameBalance");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.DETAIL })
      );
      throw error;
    });
};

export const companyGameBalanceTopUp = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .companyGameTopup(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.companyGameDeposit({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: companyGameTopup");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

export const companyGameBalanceWithdraw = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .companyGameWithdraw(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.companyGameWithdraw({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: companyGameWithdraw");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
//#endregion end : game transfer

//#region start : game report

export const getGameReport = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchGameReport(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.gameReportFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchGameReport");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};
//#endregion end : game report

//#region start : downline

export const getDownline = () => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchDownline()
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.downlineFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchDownline");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};
//#endregion end : downline

//#region start : transaction report

export const getTransactionReport = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchTransactionReport(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.transactionReportFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchTransactionReport");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};
//#endregion end : transaction report

//#region start : withdraw

export const getWithdrawUI = () => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchWithdrawUI()
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.withdrawUIFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchWithdrawUI");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const getWithdrawList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchWithdrawList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.withdrawListFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchWithdrawList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const submitWithdrawRequest = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .withdrawRequest(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.withdrawRequestSubmitted({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: withdrawRequest");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

export const cancelWithdrawRequest = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .withdrawCancel(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.withdrawRequestCancelled({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: withdrawCancel");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

//#endregion end : withdraw

//#region start : deposit

export const getAtmDepositUI = () => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchAtmDepositUI()
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.depositAtmUIFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchAtmDepositUI");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const getDepositWalletUI = () => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchDepositWalletUI()
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.depositWalletUIFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchDepositWalletUI");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const getAtmDepositList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchAtmDepositList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.depositAtmListFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchAtmDepositList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const submitAtmDepositRequest = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .atmDepositRequest(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.depositAtmRequestSubmitted({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: atmDepositRequest");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

export const cancelAtmDepositRequest = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .atmDepositCancel(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.depositAtmRequestCancelled({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: atmDepositCancel");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

export const getGatewayDepositUI = () => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchGatewayDepositUI()
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.depositGatewayUIFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchGatewayDepositUI");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const getTelcoDepositUI = () => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchTelcoDepositUI()
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.depositTelcoUIFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchTelcoDepositUI");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const getGatewayDepositList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchGatewayDepositList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.depositGatewayListFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchGatewayDepositList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const getTelcoDepositList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchTelcoDepositList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.depositTelcoListFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchTelcoDepositList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const submitGatewayDepositRequest = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .gatewayDepositRequest(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(
            actions.depositGatewayRequestSubmitted({ data: response.data })
          );
          return response;
        }
      } else {
        throw new Error("Error api: gatewayDepositRequest");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

export const submitTelcoDepositRequest = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .telcoDepositRequest(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(
            actions.depositTelcoRequestSubmitted({ data: response.data })
          );
          return response;
        }
      } else {
        throw new Error("Error api: telcoDepositRequest");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

//#endregion end : deposit

//#region start : member Transfer

export const memberTransfer = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .memTransferMem(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.memberTransfered({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: memTransferMem");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
//#endregion end : member Transfer

//#region start : Point Shop

export const getPointShops = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchPointShops(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.pointShopsFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchPointShops");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const transferShopPointsToBalance = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .shopPointsToBalance(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(
            actions.shopPointsToBalanceTransfered({ data: response.data })
          );
          return response;
        }
      } else {
        throw new Error("Error api: shopPointsToBalance");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

//#endregion end : Point Shop

//#region start : Multiwallet

export const getMultiWalletInfo = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchMultiWalletInfo(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.multiWalletInfoFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchMultiWalletInfo");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const getMultiWalletRateList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchMultiWalletRateList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.multiWalletRateListFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchMultiWalletRateList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const exchangingCurrency = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .exchangeCurrency(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.currencyExchanged({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: exchangeCurrency");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

//#endregion end : Multiwallet

//#region start : Bonus

export const getBonusList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchBonusList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.bonusListFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchBonusList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const transferBonusToBalance = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .bonusToBalance(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.bonusToBalanceTransfered({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: bonusToBalance");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

//#endregion end : Bonus

//#region start : Get Agent Usename

export const getAgentUsename = () => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .fetchAgentUsername()
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.agentUsernameFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchAgentUsername");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

//#endregion
