import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/SettingAction";
import Skeleton from "react-loading-skeleton";
import { CheckIsNull } from "../../../../utils/CommonHelper";

export const GameReport = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const settingState = useSelector(({ setting }) => setting, shallowEqual);

  useEffect(() => {
    dispatch(actions.getGameReport());
  }, []);

  return (
    <>
      <h5 className="mb-4">{intl.formatMessage({ id: "gameReport" })}</h5>
      {settingState.gameReport ? (
        <>
          {settingState.listLoading ? (
            <div className="d-flex align-items-center justify-content-center py-5">
              <div
                className="spinner-border"
                role="status"
                style={{ scale: "1.2" }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div style={{ minHeight: "600px" }}>
              {settingState.gameReport.gameReports.length > 0 ? (
                <div>
                  {settingState.gameReport.gameReports.map((report, index) => (
                    <div className="card mb-3" key={index}>
                      <div
                        className="card-body rounded"
                        style={{ background: "rgba(0,0,0,0.2)" }}
                      >
                        <div className="d-flex justify-content-between">
                          <div>{report.companyname}</div>
                          <div
                            className="p-1 px-2 fw-bold border border-warning rounded text-warning"
                            style={{ fontSize: "11px" }}
                          >
                            {report.gametype}
                          </div>
                        </div>
                        <div className="fw-bold fs-5">{report.gamename}</div>
                        <div className="d-flex">
                          <div className="me-1 text-muted">
                            {intl.formatMessage({ id: "bet" })} :
                          </div>
                          <div>{report.bet}</div>
                          <div className="ms-3 me-1 text-muted">
                            {intl.formatMessage({ id: "win" })} :
                          </div>
                          <div className="text-prim">{report.win}</div>
                        </div>
                        <div
                          className={`mb-2 text-end fw-bold ${
                            CheckIsNull(report.balance)
                              ? ""
                              : parseFloat(report.balance) >= 0
                              ? "text-success"
                              : "text-danger"
                          }`}
                        >
                          {report.balance}
                        </div>
                        <div
                          className="text-muted fst-italic"
                          style={{ fontSize: "0.75rem" }}
                        >
                          {report.date} {report.time}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="d-flex py-5 align-items-center justify-content-center">
                  <div className="text-muted d-flex align-items-center justify-content-center flex-column">
                    <i className="bi bi-search fs-1 mb-3" />
                    <div>{intl.formatMessage({ id: "noRecordFound" })}</div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          <Skeleton height="600px" className="w-100" />
        </>
      )}
    </>
  );
};
