import { authGet, authPost } from "./ApiClient";

const baseUrl = "http://34.87.51.38/ok99memberApi/api";

export function fetchWalletInfo() {
  return authGet(`${baseUrl}/MemberPc2/PcHomePageBalance`, {});
}

export function changeLanguage(params) {
  return authPost(`${baseUrl}/Member2/ChangeLanguage`, params);
}
