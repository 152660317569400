export const LotteryNavTitle = {
  RESULT: "viewResult",
  BET: "betNow",
  PAYOUT: "payout",
  SLIP: "slip",
  REPORT: "report",
};

export const LotteryNavConst = [
  {
    title: LotteryNavTitle.RESULT,
    navigate: "/lottery/result",
  },
  {
    title: LotteryNavTitle.BET,
    navigate: "/lottery/bet",
  },
  {
    title: LotteryNavTitle.SLIP,
    navigate: "/lottery/slip",
  },
  {
    title: LotteryNavTitle.PAYOUT,
    navigate: "/lottery/payout",
  },
  {
    title: LotteryNavTitle.REPORT,
    navigate: "/lottery/report",
  },
];

export const LotteryTypeConst = {
  BASIC_LOTTERY: "MKT",
  HL_LOTTERY: "HL",
};

export const LotteryTypeTitleConst = [
  { title: "basicLottery", imageUrl: "/images/lotteryCompany/gdlotto.png" },
  { title: "specialLottery", imageUrl: "/images/lotteryCompany/gdlotto.png" },
];

export const LotteryBetTypeConst = {
  BIG: "BIG",
  SMALL: "SMALL",
  FOUR_A: "4A",
};
