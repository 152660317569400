import { useEffect, useState } from "react";
import * as actions from "../../redux/SettingAction";
import * as masterActions from "../../../common/redux/MasterLayoutAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { toAbsoluteUrl } from "../../../../utils/CommonHelper";
import { TransferDialog } from "./transferDialog/TransferDialog";
import SweetAlert2 from "react-sweetalert2";
import { Overlay } from "../../../common/components/Overlay";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";

export const GameTransfer = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [isShowGameBalance, setIsShowGameBalance] = useState(false);
  const settingState = useSelector(({ setting }) => setting, shallowEqual);
  const [swalProps, setSwalProps] = useState({});
  const [company, setCompany] = useState();
  const [isUpdateBalance, setIsUpdateBalance] = useState(0);

  useEffect(() => {
    dispatch(actions.getGameCompanyTransferUI());
  }, []);

  const transferAllToWallet = () => {
    setSwalProps({
      show: true,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: intl.formatMessage({
        id: "confirm",
      }),
      denyButtonText: intl.formatMessage({
        id: "cancel",
      }),
      icon: "info",
      title: intl.formatMessage({ id: "alert" }),
      text: intl.formatMessage({
        id: "transferAllToWalletConfirmation",
      }),
      onConfirm: () => {
        dispatch(actions.transferGameBalanceToWallet()).then((resp) => {
          resp = resp.data;

          if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
            setSwalProps({
              show: true,
              icon: "success",
              title: intl.formatMessage({ id: "success" }),
              text: intl.formatMessage({ id: "transferSuccessfully" }),
              onResolve: () => {
                setSwalProps({});
                dispatch(actions.getGameCompanyTransferUI());
                dispatch(masterActions.getWalletInfo());
                if (isShowGameBalance) {
                  setIsUpdateBalance((prev) => prev + 1);
                }
              },
            });
          } else {
            setSwalProps({
              show: true,
              icon: "error",
              title: intl.formatMessage({ id: "error" }),
              text: resp.message,
            });
          }
        });
      },
    });
  };

  const customCardClass =
    "rounded d-flex flex-column align-items-center justify-content-center py-3";

  return (
    <>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      {settingState.actionsLoading && <Overlay />}
      {settingState.gameCompanyTransferUI ? (
        <>
          <TransferDialog
            setSwalProps={setSwalProps}
            setIsUpdateBalance={setIsUpdateBalance}
            company={company}
            mainWallet={settingState.gameCompanyTransferUI.balance}
          />
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div
              className={`col-4 ${customCardClass}`}
              style={{ background: "rgba(0,0,0,0.2)" }}
            >
              <div className="mb-3 text-muted">
                {intl.formatMessage({ id: "walletBalance" })}
              </div>
              <div className="fw-bold">
                {settingState.gameCompanyTransferUI.balance}
              </div>
            </div>
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="btn btn-prim-light fw-semibold px-4 me-4"
                onClick={() => transferAllToWallet()}
              >
                {intl
                  .formatMessage({ id: "transferAllToWallet" })
                  .toUpperCase()}
              </button>
              <i
                className={`bi ${
                  !isShowGameBalance ? "bi bi-eye" : "bi-eye-slash"
                } cursor-pointer fs-3 mb-2 text-prim`}
                onClick={() => {
                  setIsShowGameBalance((prev) => !prev);
                }}
              />
            </div>
          </div>
          <div className="row px-1 mb-4">
            {settingState.gameCompanyTransferUI.companyTransferLists.map(
              (company, index) => (
                <div className="col-md-3 px-2 mb-3" key={index}>
                  <div
                    className={`${customCardClass} cursor-pointer position-relative`}
                    style={{ background: "rgba(0,0,0,0.2)" }}
                    title={intl.formatMessage({ id: "clickTransfer" })}
                    onClick={() => setCompany(company)}
                    data-bs-toggle="modal"
                    data-bs-target="#transferModal"
                  >
                    <img
                      className="position-absolute"
                      alt={intl.formatMessage({ id: "transfer" })}
                      src={toAbsoluteUrl("images/setting/transferIcon.png")}
                      style={{ width: "25px", right: "0.5rem", top: "1rem" }}
                    />
                    <div className="mb-3 text-muted">{company.companyname}</div>
                    <GamaBalance
                      companyCode={company.companycode}
                      isShowGameBalance={isShowGameBalance}
                      isUpdateBalance={isUpdateBalance}
                    />
                  </div>
                </div>
              )
            )}
          </div>
        </>
      ) : (
        <Skeleton className="w-100" height="600px" />
      )}
    </>
  );
};

const GamaBalance = ({ companyCode, isShowGameBalance, isUpdateBalance }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [balance, setBalance] = useState();

  useEffect(() => {
    if (isShowGameBalance) {
      dispatch(
        actions.getCompanyGameBalance({ companycode: companyCode })
      ).then((resp) => {
        resp = resp.data;
        setBalance(resp.balance);
      });
    }
  }, [companyCode, isShowGameBalance, isUpdateBalance]);

  return (
    <div className="fw-bold text-prim">
      {isShowGameBalance ? (
        balance ? (
          balance
        ) : (
          <Skeleton width="100px" height="20px" />
        )
      ) : (
        "*****"
      )}
    </div>
  );
};
