import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/LotteryAction";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";
import SweetAlert2 from "react-sweetalert2";
import { Overlay } from "../../../common/components/Overlay";
import Skeleton from "react-loading-skeleton";
import { DateToDateString, GetBeforeDate } from "../../../../utils/DateHelper";
import { LotteryHistoryDetail } from "./lotteryHistoryDetail/LotteryHistoryDetail";

export const LotteryHistory = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [dateType, setDateType] = useState(DateToDateString(new Date()));
  const [zIndex, setZIndex] = useState(1);
  const [swalProps, setSwalProps] = useState({});
  const [detailId, setDetailId] = useState({
    orderid: undefined,
    refid: undefined,
  });

  const lotteryState = useSelector(({ lottery }) => lottery, shallowEqual);

  useEffect(() => {
    dispatch(actions.getLotteryHistory({ buydate: dateType }));
  }, [dateType]);

  const reorderLottery = (orderid, refid) => {
    setZIndex(9999);
    const queryParams = {
      orderid,
      refid,
    };

    dispatch(actions.reorderLottery(queryParams)).then((resp) => {
      resp = resp.data;
      setZIndex(1);

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "reorderSuccessfully" }),
          onResolve: () => {
            setSwalProps({});
            dispatch(actions.getLotteryHistory({ buydate: dateType }));
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  return (
    <>
      <SweetAlert2
        backdrop={false}
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
        customClass="custom-swal"
      />
      <LotteryHistoryDetail
        dateType={dateType}
        orderid={detailId.orderid}
        refid={detailId.refid}
        setZIndex={setZIndex}
        setSwalProps={setSwalProps}
      />
      {(swalProps.show || lotteryState.actionsLoading) && (
        <Overlay zIndex={zIndex} />
      )}
      <div className="col-3">
        <select
          className="form-select"
          value={dateType}
          onChange={(e) => setDateType(e.target.value)}
        >
          <option value={DateToDateString(new Date())}>
            {DateToDateString(new Date())}
          </option>
          <option value={DateToDateString(GetBeforeDate(1))}>
            {DateToDateString(GetBeforeDate(1))}
          </option>
          <option value={DateToDateString(GetBeforeDate(2))}>
            {DateToDateString(GetBeforeDate(2))}
          </option>
          <option value={DateToDateString(GetBeforeDate(3))}>
            {DateToDateString(GetBeforeDate(3))}
          </option>
          <option value={DateToDateString(GetBeforeDate(4))}>
            {DateToDateString(GetBeforeDate(4))}
          </option>
          <option value={DateToDateString(GetBeforeDate(5))}>
            {DateToDateString(GetBeforeDate(5))}
          </option>
          <option value={DateToDateString(GetBeforeDate(6))}>
            {DateToDateString(GetBeforeDate(6))}
          </option>
        </select>
        <label className="mb-4 ms-1 small text-muted">
          {intl.formatMessage({ id: "date" })}
        </label>
      </div>
      {lotteryState.listLoading || !lotteryState.lotteryHistory ? (
        <Skeleton className="w-100" style={{ height: "500px" }} />
      ) : (
        <>
          {lotteryState.lotteryHistory.vBossHistories.length > 0 ? (
            lotteryState.lotteryHistory.vBossHistories.map(
              (vBossHistory, index) => (
                <div
                  className="card cursor-pointer mb-2"
                  key={index}
                  onClick={() => {
                    setDetailId({
                      refid: vBossHistory.refid,
                      orderid: vBossHistory.orderid,
                    });
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#lotteryHistorySlip"
                >
                  <div
                    className="card-body lottery-slip-item d-flex align-items-center justify-content-between"
                    style={{ background: "rgba(0,0,0,0.2)" }}
                  >
                    <div>
                      <div className="d-flex align-items-center mb-2">
                        <div
                          className="fw-bold fs-5 me-4"
                          style={{ color: "#00ff63" }}
                        >
                          {vBossHistory.totalbet}
                        </div>
                        {vBossHistory.status === "void" ? (
                          <div
                            className="border border-danger p-1 px-2 rounded text-danger fw-bold"
                            style={{ fontSize: "12px" }}
                          >
                            {intl.formatMessage({ id: "void" })}
                          </div>
                        ) : (
                          <div
                            className="border p-1 px-2 rounded fw-bold"
                            style={{ fontSize: "12px" }}
                          >
                            {vBossHistory.status}
                          </div>
                        )}
                      </div>
                      <div className="d-flex">
                        <div className="text-muted me-2">
                          {intl.formatMessage({ id: "date" })} :
                        </div>
                        <div>{vBossHistory.buydate}</div>
                      </div>
                    </div>
                    <i
                      className="fa fa-angle-right fs-1 cursor-pointer"
                      title={intl.formatMessage({ id: "detail" })}
                    />
                  </div>
                </div>
              )
            )
          ) : (
            <div className="d-flex py-5 align-items-center justify-content-center">
              <div className="text-muted d-flex align-items-center justify-content-center flex-column">
                <i className="bi bi-search fs-1 mb-3" />
                <div>{intl.formatMessage({ id: "noRecordFound" })}</div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
