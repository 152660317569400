/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes } from "react-router-dom";
import { PromotionListing } from "../../modules/promotion/component/PromotionListing";
import { createContext } from "react";

const PromotionContext = createContext();

const PromotionWrapper = () => {
  const value = {};

  return (
    <PromotionContext.Provider value={value}>
      <Routes>
        <Route path="" element={<PromotionListing />} />
      </Routes>
    </PromotionContext.Provider>
  );
};

export { PromotionWrapper };
