import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  GameTypeEnumConst,
  GameTypeLandingConst,
} from "../../../constants/GameTypeConst";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/GamePlayAction";
import { CheckIsNull } from "../../../utils/CommonHelper";
import { SettingIcon } from "../../common/components/MasterLayout";
import Marquee from "react-fast-marquee";
import { GameDepositDialog } from "./gameDepositDialog/GameDepositDialog";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";

export const GamePlayListing = () => {
  const {
    gameType,
    companyOffset,
    companyCode,
    scrollPosition,
    gameCode,
    searchName,
  } = useParams();
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();

  const gamePlayState = useSelector(({ gamePlay }) => gamePlay, shallowEqual);

  useEffect(() => {
    document.body.classList.add("pages-bg", "h-100", "scroll-y-default");
    document.querySelector("html").classList.add("h-100", "scroll-y-default");
    document
      .querySelector(".rc279-content")
      .classList.add("flex-fill", "scroll-y-default");
    document.querySelector(".rc279-header").classList.add("d-none");
    document
      .querySelector("#root")
      .classList.add("h-100", "d-flex", "flex-column");
    document.body.style.backgroundImage = ``;

    return () => {
      document.body.classList.remove("pages-bg", "h-100", "scroll-y-default");
      document.body.style.backgroundImage = ``;
      document
        .querySelector("html")
        .classList.remove("h-100", "scroll-y-default");
      document
        .querySelector(".rc279-content")
        .classList.remove("flex-fill", "scroll-y-default");
      document.querySelector(".rc279-header").classList.remove("d-none");
      document
        .querySelector("#root")
        .classList.remove("h-100", "d-flex", "flex-column");
    };
  }, []);

  useEffect(() => {
    const queryParams = {
      companycode: companyCode,
      gamecode: gameCode,
      platform: "pc",
      gametype: GameTypeEnumConst[parseInt(gameType)],
    };

    dispatch(actions.playGame(queryParams));
  }, [companyCode, gameCode]);

  return (
    <div className="d-flex h-100 flex-column">
      <GameDepositDialog companyCode={companyCode} />
      <div
        className="game-header d-flex py-2 px-5 align-items-center justify-content-between"
        style={{ backgroundColor: "#242424" }}
      >
        <i
          className="fa fa-arrow-left cursor-pointer fs-5"
          onClick={() =>
            navigate(GameTypeLandingConst[parseInt(gameType)], {
              state: { companyCode, companyOffset, searchName, scrollPosition },
            })
          }
        />
        {gamePlayState.gamePlay && (
          <div className="d-flex align-items-center flex-fill mx-4">
            <i className="fa fa-bullhorn text-prim me-2" />
            <Marquee direction="right" className="flex-fill">
              {gamePlayState.gamePlay.announcement}
            </Marquee>
          </div>
        )}

        <div data-bs-toggle="modal" data-bs-target="#gameDepositModal">
          <SettingIcon
            url="/setting/deposit"
            icon="images/icon/topup/topup.png"
            iconActive="images/icon/topup/topup_select.png"
            title="deposit"
          />
        </div>
      </div>
      <div className="flex-fill py-4 px-5">
        {!gamePlayState.gamePlay || gamePlayState.actionsLoading ? (
          <div className="h-100 w-100 d-flex align-items-center justify-content-center">
            <div
              className="spinner-border"
              role="status"
              style={{ scale: "1.2" }}
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : CheckIsNull(gamePlayState.gamePlay.gameurl) ||
          parseInt(gamePlayState.gamePlay.code) !==
            ApiStatusTypeEnum.SUCCESS ? (
          <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center text-muted">
            <i className="bi bi-search fs-1 mb-3" />
            <div>
              {parseInt(gamePlayState.gamePlay.code) ===
                ApiStatusTypeEnum.COMMON_ERROR &&
              !CheckIsNull(gamePlayState.gamePlay.message)
                ? gamePlayState.gamePlay.message
                : intl.formatMessage({ id: "gameNotFound" })}
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column w-100 h-100">
            <iframe
              key={Date.now()}
              title={gameCode}
              className="w-100 flex-fill"
              src={gamePlayState.gamePlay.gameurl}
            />
          </div>
        )}
      </div>
    </div>
  );
};
