import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/PromotionAction";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { PromotionDetailModal } from "./promotionDetail/PromotionDetailModal";

export const PromotionListing = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [htmlFilePath, setHtmlFilePath] = useState();
  const [title, setTitle] = useState();

  const promotionState = useSelector(
    ({ promotion }) => promotion,
    shallowEqual
  );

  useEffect(() => {
    document.body.style.backgroundImage = "";
    dispatch(actions.getPromotionList({ platform: "pc" }));
  }, []);

  return (
    <>
      <PromotionDetailModal htmlFilePath={htmlFilePath} title={title} />
      <div className="container my-5">
        <div className="card">
          <div className="card-body">
            {promotionState.listLoading || !promotionState.promotionList ? (
              <Skeleton className="w-100" style={{ height: "500px" }} />
            ) : (
              <>
                {promotionState.promotionList.promotionLists.map(
                  (promotion, index) => (
                    <div className="card mb-3" key={index}>
                      <div
                        className="card-body d-flex"
                        style={{ background: "rgba(0,0,0,0.3)" }}
                      >
                        <div className="col-3 pe-3">
                          <img
                            className="w-100"
                            alt={promotion.title}
                            src={promotion.imageurl}
                          />
                        </div>
                        <div className="flex-fill">
                          <div className="fw-bold mb-2">{promotion.title}</div>
                          <div className="mb-3">{promotion.description}</div>
                          <div className="">
                            <button
                              type="button"
                              className="btn btn-prim p-1 px-4 fw-bold"
                              data-bs-toggle="modal"
                              data-bs-target="#promotionDetailModal"
                              onClick={() => {
                                setHtmlFilePath(promotion.htmlfile);
                                setTitle(promotion.title);
                              }}
                            >
                              {intl.formatMessage({ id: "detail" })}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
