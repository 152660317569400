import { useState } from "react";
import { useIntl } from "react-intl";
import { WithdrawNavTitle } from "../../../../constants/WithdrawNavConst";
import { WithdrawDialog } from "./withdrawDialog/WithdrawDialog";
import { WithdrawHistory } from "./history/WithdrawHistory";

export const Withdraw = () => {
  const [selectedNav, setSelectedNav] = useState(WithdrawNavTitle.WITHDRAW);
  const intl = useIntl();

  return (
    <>
      <div className="d-flex mb-4">
        {Object.entries(WithdrawNavTitle).map(([key, value]) => (
          <h5
            className={`me-4 cursor-pointer ${
              selectedNav !== value ? "text-muted" : ""
            }`}
            onClick={() => setSelectedNav(value)}
            key={key}
          >
            {intl.formatMessage({ id: value })}
          </h5>
        ))}
      </div>
      {selectedNav === WithdrawNavTitle.WITHDRAW ? (
        <WithdrawDialog />
      ) : (
        <WithdrawHistory />
      )}
    </>
  );
};
