import { useIntl } from "react-intl";
import { Modal } from "../../../common/components/Modal";
import { useRef } from "react";
import { DepositDialog } from "../../../setting/component/deposit/depositDialog/DepositDialog";

export const GameDepositDialog = ({ companyCode }) => {
  const intl = useIntl();
  const dismissBtnRef = useRef();

  return (
    <Modal
      modalId="gameDepositModal"
      title={intl.formatMessage({ id: "deposit" })}
      dismissBtnRef={dismissBtnRef}
      modalSize="modal-lg"
    >
      <div className="p-3">
        <DepositDialog
          companyCode={companyCode}
          dismissBtnRef={dismissBtnRef}
        />
      </div>
    </Modal>
  );
};
