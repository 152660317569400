import { useState } from "react";
import { DepositNavTitle } from "../../../../constants/DepositNavConst";
import { useIntl } from "react-intl";
import { DepositHistory } from "./history/DepositHistory";
import { DepositDialog } from "./depositDialog/DepositDialog";

export const Deposit = () => {
  const [selectedNav, setSelectedNav] = useState(DepositNavTitle.DEPOSIT);
  const intl = useIntl();

  return (
    <>
      <div className="d-flex mb-4">
        {Object.entries(DepositNavTitle).map(([key, value]) => (
          <h5
            className={`me-4 cursor-pointer ${
              selectedNav !== value ? "text-muted" : ""
            }`}
            onClick={() => setSelectedNav(value)}
            key={key}
          >
            {intl.formatMessage({ id: value })}
          </h5>
        ))}
      </div>
      {selectedNav === DepositNavTitle.DEPOSIT ? (
        <DepositDialog />
      ) : (
        <DepositHistory />
      )}
    </>
  );
};
