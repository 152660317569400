import React from "react";
import { useIntl } from "react-intl";
import PhoneInput from "react-phone-input-2";
import { CheckIsNull, IsKeyPressNumber } from "../../../../utils/CommonHelper";

export const getFieldCSSClasses = (touched, errors, value, readOnly) => {
  const classes = ["form-control"];

  if (readOnly) {
    classes.push("text-gray-800");
    return classes.join(" ");
  }

  if (touched && (errors || value === "error")) {
    classes.push("is-invalid");
  }

  if (value === "error") {
    classes.push("is-invalid");
  }

  if (
    touched &&
    !errors &&
    value !== null &&
    value !== "" &&
    value !== "error"
  ) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export const Input = ({
  field,
  form: { touched, errors },
  label,
  type = "text",
  readOnly = false,
  placeholder,
  autoComplete,
  isUpperCaseOnly = false,
  onChange,
  onKeyDown,
  innerRef,
  onFocus,
  onBlur,
  onPaste,
}) => {
  const fieldData = {
    ...field,
    value: field.value === "error" ? "" : field.value,
  };

  const inputClassName = getFieldCSSClasses(
    touched[field.name],
    errors[field.name],
    field.value,
    readOnly
  );

  return (
    <>
      {label && <label className="mb-2 text-input-label">{label}</label>}

      <input
        ref={innerRef}
        type={type === "numberString" ? "text" : type}
        className={inputClassName}
        onKeyDown={(e) => {
          if (type === "number" || type === "numberString") {
            if (!IsKeyPressNumber(e)) {
              e.preventDefault();
            }
          }
          if (onKeyDown) {
            onKeyDown(e);
          }
        }}
        onInput={(e) => {
          if (isUpperCaseOnly) {
            e.target.value = ("" + e.target.value).toUpperCase();
          }
          if (onChange) {
            onChange(e);
          }
        }}
        {...fieldData}
        onFocus={onFocus ?? fieldData.onFocus}
        onBlur={onBlur ?? fieldData.onBlur}
        onPaste={onPaste}
        placeholder={placeholder}
        readOnly={readOnly}
        autoComplete={autoComplete}
        accept={type === "file" ? "image/*" : undefined}
      />
      <ErrorContainer
        isShow={touched[field.name] && errors[field.name]}
        errorMsg={errors[field.name]}
      />
    </>
  );
};

export const PhoneInputField = ({
  values,
  setFieldValue,
  setIsShowPhoneNoError,
  isShowPhoneNoError,
  phoneNoErrorDescription,
  setPhoneNoErrorDescription,
  name,
  country = "my",
}) => {
  const intl = useIntl();

  return (
    <>
      <PhoneInput
        country={country}
        value={values[name]}
        countryCodeEditable={false}
        onChange={(phone) => {
          setFieldValue(name, phone);
          if (isShowPhoneNoError && !CheckIsNull(phone)) {
            setIsShowPhoneNoError(false);
          }
        }}
        onBlur={() => {
          if (CheckIsNull(values[name])) {
            setIsShowPhoneNoError(true);
            setPhoneNoErrorDescription(
              intl.formatMessage({ id: "phoneNoRequired" })
            );
          } else {
            setIsShowPhoneNoError(false);
          }
        }}
        inputProps={{
          className: `form-control phone-input w-100 ${
            !isShowPhoneNoError && !CheckIsNull(values[name])
              ? "is-valid"
              : isShowPhoneNoError && !CheckIsNull(phoneNoErrorDescription)
              ? "is-invalid"
              : ""
          }`,
        }}
      />
      <ErrorContainer
        isShow={isShowPhoneNoError && !CheckIsNull(phoneNoErrorDescription)}
        errorMsg={phoneNoErrorDescription}
      />
    </>
  );
};

export const ErrorContainer = ({ errorMsg, isShow, className }) =>
  isShow ? (
    <div
      className={`fv-plugins-message-container text-danger ${
        className ? className : ""
      }`}
    >
      <div className="fv-help-block">
        <span role="alert">{errorMsg}</span>
      </div>
    </div>
  ) : null;
