export const DepositNavTitle = {
  DEPOSIT: "deposit",
  HISTORY: "history",
};

export const SettingNavTitle = {
  PROFILE: "profile",
  DEPOSIT: "deposit",
  WITHDRAW: "withdraw",
  TRANSFER: "transfer",
  MEMBER_TRANSFER: "memberTransfer",
  POINT_SHOP: "pointShop",
  EXCHANGE: "exchange",
  TRANSACTION_REPORT: "transactionReport",
  GAME_REPORT: "gameReport",
  INBOX: "inbox",
  BONUS: "bonus",
};

export const PaymentMethod = {
  ATM: "atm",
  PAYMENT_GATEWAY: "paymentGateway",
  TELCO: "telco",
};

export const PaymentMethodNavConst = [
  {
    title: PaymentMethod.ATM,
    image: "images/setting/deposit/atm.png",
  },
  {
    title: PaymentMethod.PAYMENT_GATEWAY,
    image: "images/setting/deposit/paymentGateway.png",
  },
  {
    title: PaymentMethod.TELCO,
    image: "images/setting/deposit/telco.png",
  },
];
