import * as Yup from "yup";

export const lotterySchema = (intl) => {
  return Yup.object().shape({
    number: Yup.string()
      .nullable(false)
      .length(4, intl.formatMessage({ id: "numberMin4" }))
      .required(intl.formatMessage({ id: "numberRequired" })),
    big: Yup.number().test("sum", " ", function (value) {
      const { small = 0, fourA = 0 } = this.parent;
      const sum = value + small + fourA;
      return sum >= 1;
    }),
    small: Yup.number().test("sum", " ", function (value) {
      const { big = 0, fourA = 0 } = this.parent;
      const sum = value + big + fourA;
      return sum >= 1;
    }),
    fourA: Yup.number().test("sum", " ", function (value) {
      const { small = 0, big = 0 } = this.parent;
      const sum = value + small + big;
      return sum >= 1;
    }),
  });
};
