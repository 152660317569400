export const NavBarConst = {
  home: "/home",
  slot: "/slot",
  live: "/liveCasino",
  fishing: "/fishing",
  appsGame: "/appsGame",
  sport: "/sport",
  lottery: "/lottery",
  promotion: "/promotion",
};
