import { authPost } from "../../common/redux/ApiClient";

const baseUrl = "http://34.87.51.38/ok99memberApi/api";

export function fetchLiveCasinoPageUI(params) {
  return authPost(`${baseUrl}/MemberPc1/PcLivePageUI`, params);
}

export function fetchLiveCasinoGameList(params) {
  return authPost(`${baseUrl}/MemberPc1/PcLiveGameList`, params);
}

export function companyPlayGame(params) {
  return authPost(`${baseUrl}/Member3/CompanyPlayGame`, params);
}
