import { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import * as actions from "../../../../redux/SettingAction";
import { CheckIsNull } from "../../../../../../utils/CommonHelper";
import Skeleton from "react-loading-skeleton";
import { ApiStatusTypeEnum } from "../../../../../../enumeration/ApiStatusTypeEnum";

export const TelcoDepositDialog = ({
  setSwalProps,
  companyCode,
  dismissBtnRef,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [depositToLastGame, setDepositToLastGame] = useState(false);
  const [selectedGateway, setSelectedGateway] = useState(0);
  const [selectedTelco, setSelectedTelco] = useState(0);

  const settingState = useSelector(({ setting }) => setting, shallowEqual);

  useEffect(() => {
    dispatch(actions.getTelcoDepositUI());
    dispatch(actions.getDepositWalletUI()).then((resp) => {
      resp = resp.data;
      setDepositToLastGame(!CheckIsNull(resp.companycode));
    });
  }, []);

  const saveValues = () => {
    setSwalProps({
      show: true,
      showCancelButton: true,
      showConfirmButton: true,
      icon: "info",
      title: intl.formatMessage({ id: "alert" }),
      html: intl.formatMessage({
        id: "telcoDescription",
      }),
      confirmButtonText: intl.formatMessage({
        id: "confirm",
      }),
      denyButtonText: intl.formatMessage({
        id: "cancel",
      }),
      onConfirm: () => {
        setSwalProps({});
        handleSubmitDeposit();
      },
    });
  };

  const handleSubmitDeposit = () => {
    const pickedGateway =
      settingState.depositTelcoUI.telcoLists[selectedGateway];

    const newQueryParams = {
      telcocode: pickedGateway.telcocode,
      code: pickedGateway.telcoSupport[selectedTelco].code,
      type: pickedGateway.telcoSupport[selectedTelco].type,
      platform: "pc",
      companycode: !CheckIsNull(companyCode)
        ? companyCode
        : depositToLastGame &&
          !CheckIsNull(settingState.depositWalletUI.companycode)
        ? settingState.depositWalletUI.companycode
        : "",
    };

    dispatch(actions.submitTelcoDepositRequest(newQueryParams)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        window.open(`${resp.paymentlink}&imageurl=${resp.imageurl}`);
        if (dismissBtnRef) {
          dismissBtnRef.current.click();
        }
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  return (
    <>
      {settingState.depositTelcoUI && settingState.depositWalletUI ? (
        <>
          <div className="text-input-label mb-2">
            {intl.formatMessage({ id: "gateway" })}
          </div>
          <div className="d-flex mb-2">
            {settingState.depositTelcoUI.telcoLists.map((telco, index) => (
              <div key={telco.telcocode} className="card cursor-pointer me-4">
                <div
                  className="card-body"
                  style={{ background: "rgba(0,0,0,0.2)" }}
                  onClick={() => {
                    setSelectedGateway(index);
                  }}
                >
                  <div
                    className={`d-flex flex-column align-items-center menu-item ${
                      selectedGateway === index ? "show" : ""
                    }`}
                  >
                    <div className="ws-nowrap">{telco.telconame}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="text-input-label mb-2">
            {intl.formatMessage({ id: "chooseATelco" })}
          </div>
          <div className="row mb-4">
            {settingState.depositTelcoUI.telcoLists[
              selectedGateway
            ].telcoSupport.map((telco, index) => (
              <div key={telco.code} className="col-md-3 mb-2">
                <div
                  className={`card cursor-pointer ${
                    selectedTelco === index ? "bank-active" : ""
                  }`}
                >
                  <div
                    className="card-body"
                    style={{ background: "rgba(0,0,0,0.2)" }}
                    onClick={() => {
                      setSelectedTelco(index);
                    }}
                  >
                    <div
                      className={`d-flex flex-column align-items-center menu-item ${
                        selectedTelco === index ? "show fw-bold" : ""
                      }`}
                    >
                      <img
                        src={telco.imageurl}
                        alt={telco.name}
                        style={{ width: "120px" }}
                      />
                      <div
                        className="d-flex align-items-center justify-content-center small"
                        style={{ height: "42px" }}
                      >
                        <div className="text-center">{telco.name}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {settingState.depositWalletUI &&
            CheckIsNull(companyCode) &&
            !CheckIsNull(settingState.depositWalletUI.content) && (
              <div className="form-check">
                <input
                  className="form-check-input"
                  id="depositWalletUI"
                  type="checkbox"
                  checked={depositToLastGame}
                  onChange={(e) => {
                    setDepositToLastGame(e.target.checked);
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="depositWalletUI"
                  dangerouslySetInnerHTML={{
                    __html: settingState.depositWalletUI.content.replace(
                      /\*(.*?)\*/g,
                      "<span class='text-danger fw-bold'>$1</span>"
                    ),
                  }}
                />
              </div>
            )}
          <button
            type="button"
            disabled={settingState.actionsLoading}
            className="btn btn-prim fw-bold px-3 w-100 mb-4 mt-2"
            onClick={() => saveValues()}
          >
            <div className="d-flex align-items-center justify-content-center">
              {settingState.actionsLoading && (
                <div
                  className="spinner-border text-dark"
                  role="status"
                  style={{ scale: "0.75" }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
              {intl.formatMessage({ id: "submit" })}
            </div>
          </button>
        </>
      ) : (
        <Skeleton className="w-100" height="500px" />
      )}
    </>
  );
};
