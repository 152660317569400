import { useIntl } from "react-intl";
import { LanguageTypeConst } from "../../../constants/LanguageLocale";
import { toAbsoluteUrl } from "../../../utils/CommonHelper";
import { setLanguage } from "../../../i18n/Metronici18n";

export const LanguageList = () => {
  const intl = useIntl();

  return (
    <div className="d-flex align-items-center justify-content-center mb-4">
      {Object.entries(LanguageTypeConst).map(([key, value]) => (
        <img
          className="login-language cursor-pointer"
          title={intl.formatMessage({ id: value })}
          key={key}
          alt={value}
          src={toAbsoluteUrl(`images/language/${value}.png`)}
          onClick={() => setLanguage(value)}
        />
      ))}
    </div>
  );
};
