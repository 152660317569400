import { Field, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
  LotteryHLCompanyConst,
  LotteryMKTCompanyConst,
} from "../../../../constants/LotteryCompanyConst";
import { LotteryTypeConst } from "../../../../constants/LotteryNavConst";
import { CheckIsNull, toAbsoluteUrl } from "../../../../utils/CommonHelper";
import { Input } from "../../../common/components/control/InputField";
import { lotterySchema } from "../../yupSchema/lotterySchema";

const BetTypeConst = ["Box", "IBox"];

export const LotteryBetForm = ({ saveValues, lotteryType }) => {
  const intl = useIntl();
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [betType, setBetType] = useState("");
  const bigRef = useRef();

  useEffect(() => {
    setSelectedCompany([]);
  }, [lotteryType]);

  const PermAlone = (str) => {
    let arr = str.split("");
    let reg = /(.)\1+/; //regexp for repeating characters
    let perms = 0;
    function swap(indexFrom, indexTo, arr) {
      if (indexTo === arr.length - 1) {
        let enterStr = arr.join("");
        if (!reg.test(enterStr)) perms++;
      } else {
        let newArr = []; //newArr(copy of arr) is needed so that not to modify the original one, as next calls of swap() need it
        for (let i = 0; i < arr.length; i++) {
          newArr.push(arr[i]);
        }
        let temp = newArr[indexFrom];
        newArr[indexFrom] = newArr[indexTo];
        newArr[indexTo] = temp;

        for (let i = indexTo + 1; i < arr.length; i++) {
          swap(i, indexTo + 1, newArr);
        }
      }
    }
    //start recursion
    for (let i = 0; i < arr.length; i++) {
      swap(i, 0, arr);
    }

    return perms;
  };

  const GetNumDiffer = (numDiffers) => {
    let countDiffer = 0;
    for (const numDiffer in numDiffers) {
      if (numDiffers.hasOwnProperty(numDiffer)) {
        ++countDiffer;
      }
    }
    return countDiffer;
  };

  const BoxMultiply = (value) => {
    const arr = !CheckIsNull(value) ? value.toString().split("") : [];
    let numDiffers = {};
    let totalBox = 0;

    for (let i = 0; i < arr.length; i++) {
      numDiffers[arr[i]] = 1 + (numDiffers[arr[i]] || 0);
    }
    let countDiffer = GetNumDiffer(numDiffers);
    if (countDiffer === 2) {
      totalBox = PermAlone(value.toString());
      if (totalBox === 0) totalBox = 4;
      if (totalBox === 8) totalBox = 6;
    } else {
      if (countDiffer === 1) totalBox = 1;
      else if (countDiffer === 3) totalBox = 12;
      else totalBox = 24;
    }

    return totalBox;
  };

  return (
    <>
      <Formik
        initialValues={{
          number: "",
          big: 0,
          small: 0,
          fourA: 0,
        }}
        validationSchema={lotterySchema(intl)}
        enableReinitialize={true}
        onSubmit={(values, formikHelpers) => {
          if (selectedCompany.length > 0) {
            const subTotal =
              ((values.big ? values.big : 0) +
                (values.small ? values.small : 0) +
                (values.fourA ? values.fourA : 0)) *
              (betType === BetTypeConst[0] ? BoxMultiply(values.number) : 1) *
              selectedCompany.length;

            const companyList = selectedCompany
              .map((company) => company.lotteryKeyCode)
              .join("");

            const longCode = {
              key: Date.now(),
              companyList: selectedCompany,
              number: values.number,
              big: values.big,
              small: values.small,
              fourA: values.fourA,
              betType,
              subTotal,
              code: `#${companyList}|${
                betType === BetTypeConst[0]
                  ? "*"
                  : betType === BetTypeConst[1]
                  ? "**"
                  : ""
              }${values.number}#${values.big}#${values.small}#${values.fourA}`,
            };

            saveValues(longCode);
            setBetType("");
            formikHelpers.resetForm();
          }
        }}
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          touched,
          errors,
          setFieldError,
          setFieldTouched,
        }) => (
          <Form>
            <div className="mb-2">
              <label className="mb-2 text-input-label">
                {intl.formatMessage({ id: "chooseCompany" })}
              </label>
              <div className="d-flex align-items-center w-100">
                {(lotteryType === LotteryTypeConst.HL_LOTTERY
                  ? LotteryHLCompanyConst
                  : LotteryMKTCompanyConst
                ).map((lotteryCompany, index) => (
                  <div
                    className={`d-flex flex-column align-items-center justify-content-center ${
                      index === 0 ? "me-2" : "mx-2"
                    } p-2 rounded cursor-pointer ${
                      selectedCompany.filter(
                        (company) =>
                          company.lotteryCode === lotteryCompany.lotteryCode
                      ).length > 0
                        ? "text-dark"
                        : ""
                    }`}
                    key={index}
                    style={{
                      width: "100px",
                      userSelect: "none",
                      background:
                        selectedCompany.filter(
                          (company) =>
                            company.lotteryCode === lotteryCompany.lotteryCode
                        ).length > 0
                          ? "#d1ffbd"
                          : "rgba(0, 0, 0, 0.2)",
                    }}
                    onClick={() => {
                      if (
                        selectedCompany.filter(
                          (company) =>
                            company.lotteryCode === lotteryCompany.lotteryCode
                        ).length > 0
                      ) {
                        setSelectedCompany(
                          selectedCompany.filter(
                            (company) =>
                              company.lotteryCode !== lotteryCompany.lotteryCode
                          )
                        );
                      } else {
                        setSelectedCompany([
                          ...selectedCompany,
                          lotteryCompany,
                        ]);
                      }
                    }}
                  >
                    <img
                      src={toAbsoluteUrl(lotteryCompany.lotteryUrl)}
                      alt={lotteryCompany.lotteryCode}
                      className="w-100"
                    />
                    <div className="mt-2 fw-bold">
                      {lotteryCompany.lotteryCode}
                    </div>
                  </div>
                ))}
              </div>
              {touched.number && selectedCompany.length <= 0 && (
                <div className="fv-plugins-message-container text-danger mt-1">
                  <div className="fv-help-block">
                    <span role="alert">
                      {intl.formatMessage({ id: "companyRequired" })}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="d-flex">
              <div className="col-6 me-3">
                <div className="mb-2">
                  <Field
                    name="number"
                    component={Input}
                    placeholder={intl.formatMessage({ id: "number" })}
                    label={intl.formatMessage({ id: "number" })}
                    autoComplete="off"
                    type="numberString"
                    onPaste={(e) => {
                      e.preventDefault();
                      const pastedText =
                        e.clipboardData
                          .getData("text")
                          ?.replace(/\D/g, "")
                          ?.substring(0, 4) ?? "";
                      setFieldValue("number", pastedText);
                    }}
                    onKeyDown={(e) => {
                      if (
                        e.target.value.length >= 4 &&
                        !e.ctrlKey &&
                        !e.shiftKey &&
                        e.key !== "Backspace"
                      ) {
                        bigRef.current.focus();
                      }
                    }}
                  />
                </div>
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ gap: "0.5rem" }}
                >
                  <div className="flex-fill">
                    <Field
                      name="big"
                      component={Input}
                      placeholder={intl.formatMessage({ id: "big" })}
                      label={intl.formatMessage({ id: "big" })}
                      autoComplete="off"
                      type="number"
                      innerRef={bigRef}
                      onBlur={() => {
                        setFieldTouched("big", true);
                        setFieldTouched("small", true);
                        setFieldTouched("fourA", true);
                      }}
                      onKeyDown={(e) => {
                        if (
                          parseInt(values.big) === 0 &&
                          !e.ctrlKey &&
                          !e.shiftKey &&
                          e.key !== "Backspace"
                        ) {
                          setFieldValue("big", "");
                        }
                      }}
                    />
                  </div>
                  <div className="flex-fill">
                    <Field
                      name="small"
                      component={Input}
                      placeholder={intl.formatMessage({ id: "small" })}
                      label={intl.formatMessage({ id: "small" })}
                      autoComplete="off"
                      type="number"
                      onBlur={() => {
                        setFieldTouched("big", true);
                        setFieldTouched("small", true);
                        setFieldTouched("fourA", true);
                      }}
                      onKeyDown={(e) => {
                        if (
                          parseInt(values.small) === 0 &&
                          !e.ctrlKey &&
                          !e.shiftKey &&
                          e.key !== "Backspace"
                        ) {
                          setFieldValue("small", "");
                        }
                      }}
                    />
                  </div>
                  <div className="flex-fill">
                    <Field
                      name="fourA"
                      component={Input}
                      placeholder={intl.formatMessage({ id: "4a" })}
                      label={intl.formatMessage({ id: "4a" })}
                      autoComplete="off"
                      type="number"
                      onBlur={() => {
                        setFieldTouched("big", true);
                        setFieldTouched("small", true);
                        setFieldTouched("fourA", true);
                      }}
                      onKeyDown={(e) => {
                        if (
                          parseInt(values.fourA) === 0 &&
                          !e.ctrlKey &&
                          !e.shiftKey &&
                          e.key !== "Backspace"
                        ) {
                          setFieldValue("fourA", "");
                        }
                      }}
                    />
                  </div>
                </div>
                {(touched.big || touched.small || touched.fourA) &&
                  (values.big ? values.big : 0) +
                    (values.small ? values.small : 0) +
                    (values.fourA ? values.fourA : 0) <=
                    0 && (
                    <div className="fv-plugins-message-container text-danger ">
                      <div className="fv-help-block">
                        <span role="alert">
                          {intl.formatMessage({ id: "sumMin1" })}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
              <div className="col-3 me-3">
                <label className="mb-2 text-input-label">
                  {intl.formatMessage({ id: "betType" })}
                </label>
                {BetTypeConst.map((bet, index) => (
                  <div
                    className={`cursor-pointer mb-2 rounded text-center ${
                      betType === bet
                        ? "bg-light-success text-dark fw-bold"
                        : "bg-hover"
                    }`}
                    key={index}
                    style={{
                      padding: "0.95rem",
                    }}
                    onClick={() => {
                      if (betType === bet) {
                        setBetType("");
                      } else {
                        setBetType(bet);
                      }
                    }}
                  >
                    {bet}
                  </div>
                ))}
              </div>
              <div className="flex-fill d-flex flex-column">
                <div
                  className="p-2 text-end w-100 mt-4"
                  style={{ background: "rgba(0,0,0,0.2)" }}
                >
                  <label className="mb-2 text-input-label">
                    {intl.formatMessage({ id: "estimateAmount" })}
                  </label>
                  <div className="fw-bold">
                    {CheckIsNull(values.number) ||
                    values.number.toString().length < 4
                      ? "0.00"
                      : parseFloat(
                          ((values.big ? values.big : 0) +
                            (values.small ? values.small : 0) +
                            (values.fourA ? values.fourA : 0)) *
                            (betType === BetTypeConst[0]
                              ? BoxMultiply(values.number)
                              : 1) *
                            selectedCompany.length
                        ).toFixed(2)}
                  </div>
                </div>
                <button type="submit" className="btn btn-prim w-100 mt-2">
                  {intl.formatMessage({ id: "add" })}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
