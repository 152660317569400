import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as masterActions from "../../../common/redux/MasterLayoutAction";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../../redux/SettingAction";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";
import { PointShopTypeConst } from "../../../../constants/PointShopTypeConst";
import { Overlay } from "../../../common/components/Overlay";
import { useAuth } from "../../../auth/components/AuthInit";

export const PointShop = () => {
  const [swalProps, setSwalProps] = useState({});
  const { auth } = useAuth();
  const intl = useIntl();
  const dispatch = useDispatch();
  const settingState = useSelector(({ setting }) => setting, shallowEqual);

  const saveValues = (itemid) => {
    dispatch(actions.transferShopPointsToBalance({ selitemid: itemid })).then(
      (resp) => {
        resp = resp.data;

        if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
          dispatch(actions.getPointShops({ platform: "pc" }));
          dispatch(masterActions.getWalletInfo());

          setSwalProps({
            show: true,
            icon: "success",
            title: intl.formatMessage({ id: "success" }),
            text: intl.formatMessage({ id: "purchaseSuccessfully" }),
            onResolve: () => {
              setSwalProps({});
            },
          });
        } else {
          setSwalProps({
            show: true,
            icon: "error",
            title: intl.formatMessage({ id: "error" }),
            text: resp.message,
          });
        }
      }
    );
  };

  useEffect(() => {
    dispatch(actions.getPointShops({ platform: "pc" }));
  }, []);

  return (
    <>
      {settingState.actionsLoading && <Overlay />}
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h5 className="p-0 m-0">{intl.formatMessage({ id: "pointShop" })}</h5>
        <div className="d-flex justify-content-between align-items-center">
          <div className="me-4">
            {settingState.pointShops ? (
              <div className="d-flex justify-content-between border-prim rounded py-1 px-2">
                <div className="text-muted me-2">
                  {intl.formatMessage({ id: "point" })} :
                </div>
                <div>{settingState.pointShops.points}</div>
              </div>
            ) : (
              <Skeleton className="w-100" height="50px" />
            )}
          </div>
          <div>
            {settingState.pointShops ? (
              <div className="d-flex justify-content-between border-prim rounded py-1 px-2">
                <div className="text-muted me-2">
                  {intl.formatMessage({ id: "balance" })} :
                </div>
                <div>{settingState.pointShops.balance}</div>
              </div>
            ) : (
              <Skeleton className="w-100" height="50px" />
            )}
          </div>
        </div>
      </div>
      {settingState.pointShops ? (
        <div style={{ minHeight: "600px" }}>
          <div className="mt-4 row">
            {settingState.pointShops.pointShopsLists.map((pointShop, index) => (
              <div className="col-4 px-2" key={index}>
                <div
                  className="rounded point-shop-item d-flex flex-column align-items-center position-relative"
                  style={{ background: "rgba(0,0,0,0.2)" }}
                >
                  <div
                    className="mt-2 w-100 px-3 mb-2 d-flex flex-column align-items-center justify-content-center"
                    style={{ height: "50px" }}
                  >
                    {pointShop.type === PointShopTypeConst.DATE_RANGE ? (
                      <>
                        <div className="text-center fw-semibold">
                          {intl.formatMessage({ id: "duration" })}
                        </div>
                        <div className="text-center">
                          {pointShop.fromdate} - {pointShop.todate}
                        </div>
                      </>
                    ) : pointShop.type === PointShopTypeConst.UNLIMITED ? (
                      <div className="text-center fw-semibold">
                        {intl.formatMessage({ id: "unlimitedClaim" })}
                      </div>
                    ) : (
                      <div className="text-center fw-semibold">
                        {intl.formatMessage({ id: "limitedClaim" })}
                      </div>
                    )}
                  </div>
                  <img
                    alt={pointShop.points}
                    src={pointShop.imageurl}
                    style={{ height: "100px" }}
                    className="mb-2"
                  />
                  {pointShop.type === PointShopTypeConst.AVAILABLE_COUNT && (
                    <div className="position-absolute top-0 end-0 small pt-2 me-2">
                      {intl.formatMessage({ id: "available" })} :{" "}
                      {pointShop.available}
                    </div>
                  )}
                  <div className="text-center mb-3 fw-bold">
                    {auth.currencycode} {pointShop.balance}
                  </div>
                  <button
                    type="button"
                    className="btn btn-prim w-100"
                    onClick={() => saveValues(pointShop.itemid)}
                  >
                    {pointShop.points}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Skeleton className="w-100" height="600px" />
      )}
    </>
  );
};
