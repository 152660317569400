import { useState } from "react";
import { useIntl } from "react-intl";
import {
  HistoryPaymentGatewayStatusConst,
  HistoryStatusColorConst,
  HistoryStatusConst,
  historyDurationList,
} from "../../../../../constants/HistoryConst";
import * as actions from "../../../redux/SettingAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";

export const HistoryPaymentGateway = () => {
  const intl = useIntl();
  const [dayType, setDayType] = useState(1);
  const [status, setStatus] = useState(
    HistoryPaymentGatewayStatusConst.PENDING
  );
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();

  const settingState = useSelector(({ setting }) => setting, shallowEqual);

  useEffect(() => {
    dispatch(actions.getGatewayDepositList({ days: dayType }));
  }, [dayType]);

  return (
    <>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div className="col-3">
        <select
          className="form-select"
          value={dayType}
          onChange={(e) => setDayType(e.target.value)}
        >
          {historyDurationList.map((day, index) => (
            <option key={index} value={day}>
              {day} {intl.formatMessage({ id: "days" })}
            </option>
          ))}
        </select>
        <label className="mb-4 ms-1 small text-muted">
          {intl.formatMessage({ id: "duration" })}
        </label>
      </div>
      <div className="d-flex mb-4">
        {Object.entries(HistoryPaymentGatewayStatusConst).map(
          ([key, value]) => (
            <div
              className={`me-3 cursor-pointer switch px-3 rounded fw-bold py-2 custom-border ${
                status === value ? "active" : ""
              }`}
              onClick={() => setStatus(value)}
              key={key}
            >
              {intl.formatMessage({
                id:
                  value === HistoryPaymentGatewayStatusConst.APPROVE
                    ? "success"
                    : value === HistoryPaymentGatewayStatusConst.REJECT
                    ? "fail"
                    : value,
              })}
            </div>
          )
        )}
      </div>
      {settingState.depositGatewayList ? (
        <>
          {settingState.listLoading ? (
            <div className="d-flex align-items-center justify-content-center py-5">
              <div
                className="spinner-border"
                role="status"
                style={{ scale: "1.2" }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div style={{ minHeight: "600px" }}>
              {settingState.depositGatewayList.topupGatewayLists.filter(
                (deposit) =>
                  deposit.gatewaystatus.toLowerCase() === status.toLowerCase()
              ).length > 0 ? (
                settingState.depositGatewayList.topupGatewayLists
                  .filter(
                    (deposit) =>
                      deposit.gatewaystatus.toLowerCase() ===
                      status.toLowerCase()
                  )
                  .map((deposit, index) => (
                    <div className="card mb-2" key={index}>
                      <div
                        className="card-body rounded"
                        style={{
                          background: "rgba(0,0,0,0.2)",
                        }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="fw-bold fs-5">
                            {deposit.topupamount}
                          </div>
                          <div
                            className={`py-1 px-2 rounded small fw-bold text-dark`}
                            style={{
                              fontSize: "11px",
                              background:
                                HistoryStatusColorConst[deposit.gatewaystatus],
                            }}
                          >
                            {deposit.gatewaystatus}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            <div className="small">
                              {intl.formatMessage({ id: "gatewayName" })} :{" "}
                              {deposit.gatewayname}
                            </div>
                            <div className="small">
                              {intl.formatMessage({ id: "bankName" })} :{" "}
                              {deposit.bankname}
                            </div>
                            <div className="text-muted fst-italic small">
                              {intl.formatMessage({ id: "requestedDate" })} :{" "}
                              {deposit.requestdate}
                            </div>
                            {(deposit.gatewaystatus.toLowerCase() ===
                              HistoryStatusConst.APPROVE.toLowerCase() ||
                              deposit.gatewaystatus.toLowerCase() ===
                                HistoryStatusConst.REJECT.toLowerCase()) && (
                              <div className="text-muted fst-italic small">
                                {intl.formatMessage({ id: "respondDate" })} :{" "}
                                {deposit.responsedate}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
              ) : (
                <div className="d-flex py-5 align-items-center justify-content-center">
                  <div className="text-muted d-flex align-items-center justify-content-center flex-column">
                    <i className="bi bi-search fs-1 mb-3" />
                    <div>{intl.formatMessage({ id: "noRecordFound" })}</div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <Skeleton className="w-100" height="600px" />
      )}
    </>
  );
};
