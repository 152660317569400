import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/LotteryAction";
import { ApiStatusTypeEnum } from "../../../../../enumeration/ApiStatusTypeEnum";
import { Modal } from "../../../../common/components/Modal";
import Skeleton from "react-loading-skeleton";

export const LotteryDetailConst = {
  BASIC: "basic",
  ADVANCE: "advance",
};

export const LotteryHistoryDetail = ({
  setSwalProps,
  refid,
  orderid,
  dateType,
  setZIndex,
}) => {
  const intl = useIntl();
  const [lotteryDetailMode, setLotteryDetailMode] = useState(
    LotteryDetailConst.BASIC
  );
  const dismissBtnRef = useRef();
  const lotteryState = useSelector(({ lottery }) => lottery, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    if (refid && orderid) {
      const queryParams = {
        refid,
        orderid,
      };

      dispatch(actions.getLotteryHistoryDetail(queryParams));
      setLotteryDetailMode(LotteryDetailConst.BASIC);
    }
  }, [refid, orderid]);

  const reorderLottery = () => {
    setZIndex(9999);
    const queryParams = {
      orderid,
      refid,
    };

    dispatch(actions.reorderLottery(queryParams)).then((resp) => {
      resp = resp.data;
      setZIndex(1);

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "reorderSuccessfully" }),
          onResolve: () => {
            setSwalProps({});
            dispatch(actions.getLotteryList({ buydate: dateType }));
            dismissBtnRef.current.click();
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  return (
    <Modal
      modalId="lotteryHistorySlip"
      title={intl.formatMessage({ id: "detail" })}
      dismissBtnRef={dismissBtnRef}
      modalSize="modal-lg"
    >
      <div className="p-3">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="d-flex">
            {Object.entries(LotteryDetailConst).map(([key, value], index) => (
              <div
                key={index}
                className={`me-3 cursor-pointer p-2 rounded px-4 ${
                  value === lotteryDetailMode ? "text-dark fw-bold" : ""
                }`}
                style={{
                  background:
                    value === lotteryDetailMode ? "#ffa500" : "rgba(0,0,0,0.2)",
                }}
                onClick={() => setLotteryDetailMode(value)}
              >
                {intl.formatMessage({ id: value })}
              </div>
            ))}
          </div>
          {lotteryState.lotteryHistoryDetail ? (
            lotteryState.lotteryHistoryDetail.status === "void" ? (
              <div className="border border-danger p-1 px-2 rounded text-danger text-center fw-bold">
                {intl.formatMessage({ id: "void" })}
              </div>
            ) : (
              <div className="border p-1 px-2 rounded fw-bold w-100">
                {lotteryState.lotteryHistoryDetail.status}
              </div>
            )
          ) : null}
        </div>
        {lotteryState.actionsLoading || !lotteryState.lotteryHistoryDetail ? (
          <Skeleton style={{ height: "350px" }} className="w-100" />
        ) : (
          <div>
            <div>
              {lotteryDetailMode === LotteryDetailConst.BASIC ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      lotteryState.lotteryHistoryDetail.receipt.replaceAll(
                        "\n",
                        "<br/>"
                      ),
                  }}
                />
              ) : (
                <>
                  <table className="w-100">
                    <thead>
                      <tr className="border-bottom">
                        <th className="p-2">No.</th>
                        <th>
                          {intl.formatMessage({
                            id: "date",
                          })}
                        </th>
                        <th>
                          {intl.formatMessage({
                            id: "company",
                          })}
                        </th>
                        <th>
                          {intl.formatMessage({
                            id: "number",
                          })}
                        </th>
                        <th>
                          {intl.formatMessage({
                            id: "big",
                          })}
                        </th>
                        <th>
                          {intl.formatMessage({
                            id: "small",
                          })}
                        </th>
                        <th>
                          {intl.formatMessage({
                            id: "4a",
                          })}
                        </th>
                        <th>
                          {intl.formatMessage({
                            id: "strikeBig",
                          })}
                        </th>
                        <th>
                          {intl.formatMessage({
                            id: "strikeSmall",
                          })}
                        </th>
                        <th>
                          {intl.formatMessage({
                            id: "strike4a",
                          })}
                        </th>
                        <th>
                          {intl.formatMessage({
                            id: "status",
                          })}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {lotteryState.lotteryHistoryDetail.vBossHistoryDetail.map(
                        (vBossHistory, index) => (
                          <tr
                            key={index}
                            style={{
                              background:
                                vBossHistory.status === "void"
                                  ? "#8f0000"
                                  : "transparent",
                            }}
                          >
                            <td className="p-2">{index + 1}</td>
                            <td>{vBossHistory.drawdate}</td>
                            <td>
                              <div className="d-flex align-items-center">
                                <img
                                  src={vBossHistory.iconurl}
                                  style={{ width: "20px" }}
                                  alt={vBossHistory.drawtype}
                                  className="me-2"
                                />
                                {vBossHistory.drawtype}
                              </div>
                            </td>
                            <td>{vBossHistory.betnumber}</td>
                            <td>{vBossHistory.betbig}</td>
                            <td>{vBossHistory.betsmall}</td>
                            <td>{vBossHistory.beta}</td>
                            <td>{vBossHistory.strikebig}</td>
                            <td>{vBossHistory.strikesmall}</td>
                            <td>{vBossHistory.strikea}</td>
                            <td>{vBossHistory.status}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </>
              )}
            </div>
            <div className="mt-4 d-flex">
              <div
                className="me-2 d-flex align-items-center justify-content-between p-2 rounded flex-fill"
                style={{ background: "rgba(0,0,0,0.2)" }}
              >
                <div>
                  {intl.formatMessage({
                    id: "totalBet",
                  })}
                </div>
                <div>{lotteryState.lotteryHistoryDetail.totalbet}</div>
              </div>
              <div
                className="ms-2 d-flex align-items-center justify-content-between p-2 rounded flex-fill"
                style={{ background: "rgba(0,0,0,0.2)" }}
              >
                <div>
                  {intl.formatMessage({
                    id: "totalWin",
                  })}
                </div>
                <div>{lotteryState.lotteryHistoryDetail.totalwin}</div>
              </div>
            </div>

            <button
              type="button"
              className="btn btn-custom-light p-2 fw-bold col-3 mt-4"
              onClick={() => {
                setZIndex(1);
                setSwalProps({
                  show: true,
                  showCancelButton: true,
                  showConfirmButton: true,
                  confirmButtonText: intl.formatMessage({
                    id: "confirm",
                  }),
                  denyButtonText: intl.formatMessage({
                    id: "cancel",
                  }),
                  icon: "info",
                  title: intl.formatMessage({ id: "alert" }),
                  text: intl.formatMessage({
                    id: "confirmReorder",
                  }),
                  onConfirm: () => {
                    setSwalProps({});
                    reorderLottery();
                  },
                });
              }}
            >
              <div className="d-flex align-items-center justify-content-center">
                <i className="bi bi-pen me-2 mb-1" />
                <div>{intl.formatMessage({ id: "reorder" })}</div>
              </div>
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};
