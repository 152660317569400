import { Field, Form, Formik } from "formik";
import { useIntl } from "react-intl";
import { Input } from "../../../common/components/control/InputField";
import { exchangeSchema } from "../yupSchema/exchangeSchema";
import { CheckIsNull } from "../../../../utils/CommonHelper";

export const ExchangeCurrencyForm = ({
  formikRef,
  saveValues,
  mainWallet,
  currencyCode,
  setAmount,
}) => {
  const intl = useIntl();

  return (
    <Formik
      initialValues={{
        amount: "",
      }}
      innerRef={formikRef}
      enableReinitialize={true}
      onSubmit={(values, formikHelpers) => {
        if (!CheckIsNull(values.amount) && parseInt(values.amount) >= 5) {
          const queryParams = {
            amount: values.amount,
          };

          saveValues(queryParams);
        } else {
          formikHelpers.setFieldError(
            "amount",
            intl.formatMessage({ id: "amountMustMoreThan5" })
          );
        }
      }}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        touched,
        errors,
        setFieldError,
      }) => (
        <Form>
          <div className="mb-2 position-relative">
            <Field
              name="amount"
              component={Input}
              placeholder={intl.formatMessage({ id: "amount" })}
              autoComplete="off"
              type="number"
              onChange={(e) => {
                setAmount(
                  CheckIsNull(e.target.value) ? 0 : parseInt(e.target.value)
                );
              }}
            />
            <div
              className="position-absolute text-prim cursor-pointer"
              style={{ right: "35px", top: "7px" }}
              onClick={() => {
                const [mainBal] = mainWallet.split(".");

                setFieldValue("amount", mainBal.replaceAll(",", ""));
              }}
            >
              {intl.formatMessage({ id: "all" })}
            </div>
          </div>
          <div className="small d-flex justify-content-start align-items-center">
            <div className="text-muted me-2">
              {intl.formatMessage({ id: "available" })} :
            </div>
            <div className="fw-semibold">
              {currencyCode} {mainWallet}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
