import { useRef, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as masterActions from "../../../common/redux/MasterLayoutAction";
import SweetAlert2 from "react-sweetalert2";
import { MemberTransferForm } from "./MemberTransferForm";
import * as actions from "../../redux/SettingAction";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";

export const MemberTransfer = () => {
  const [swalProps, setSwalProps] = useState({});
  const intl = useIntl();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const settingState = useSelector(({ setting }) => setting, shallowEqual);

  const saveValues = (queryParams) => {
    dispatch(actions.memberTransfer(queryParams)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "transferSuccessfully" }),
          onResolve: () => {
            setSwalProps({});
            formikRef.current.resetForm();
            dispatch(actions.getProfile());
            dispatch(masterActions.getWalletInfo());
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  useEffect(() => {
    dispatch(actions.getProfile());
  }, []);

  return (
    <>
      <h5 className="mb-4">{intl.formatMessage({ id: "memberTransfer" })}</h5>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      {settingState.profile ? (
        <div style={{ minHeight: "600px" }}>
          <div className="mt-4">
            <MemberTransferForm
              formikRef={formikRef}
              saveValues={saveValues}
              mainWallet={settingState.profile.balance}
            />
          </div>
          <button
            type="button"
            disabled={settingState.actionsLoading}
            className="btn btn-prim fw-bold px-3 w-100 my-4"
            onClick={() => formikRef.current.submitForm()}
          >
            {intl.formatMessage({ id: "submit" })}
          </button>
          <div className="custom-border-bottom mb-4" />
          <div className="text-muted">
            <div className="fw-bold mb-4">
              {intl.formatMessage({ id: "attention" })} :
            </div>
            <div className="mb-3">
              {intl.formatMessage({ id: "memberTransferDscription_1" })}
            </div>
            <div className="mb-1">
              {intl.formatMessage({ id: "memberTransferDscription_2" })}
            </div>
          </div>
        </div>
      ) : (
        <Skeleton className="w-100" height="600px" />
      )}
    </>
  );
};
