export const Modal = ({
  modalId,
  children,
  title,
  dismissBtnRef,
  modalSize = "",
}) => {
  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className={`modal-dialog modal-dialog-centered ${modalSize}`}>
        <div className="modal-content">
          <div className="d-flex align-items-center justify-content-between custom-border-bottom p-3">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              {title}
            </h1>
            <i
              className="bi bi-x modal-close cursor-pointer fs-3"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={dismissBtnRef}
            />
          </div>
          <div className="modal-body p-0">{children}</div>
        </div>
      </div>
    </div>
  );
};
