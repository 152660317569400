export const LotteryMKTCompanyConst = [
  {
    lotteryUrl: "/images/lotteryCompany/magnum.png",
    lotteryCode: "M",
    lotteryKeyCode: "1",
  },
  {
    lotteryUrl: "/images/lotteryCompany/damacai.png",
    lotteryCode: "P",
    lotteryKeyCode: "2",
  },
  {
    lotteryUrl: "/images/lotteryCompany/toto.png",
    lotteryCode: "T",
    lotteryKeyCode: "3",
  },
  {
    lotteryUrl: "/images/lotteryCompany/singapore.png",
    lotteryCode: "S",
    lotteryKeyCode: "4",
  },
  {
    lotteryUrl: "/images/lotteryCompany/sabah88.png",
    lotteryCode: "B",
    lotteryKeyCode: "5",
  },
  {
    lotteryUrl: "/images/lotteryCompany/cashweep.png",
    lotteryCode: "W",
    lotteryKeyCode: "6",
  },
  {
    lotteryUrl: "/images/lotteryCompany/sandakan.png",
    lotteryCode: "K",
    lotteryKeyCode: "7",
  },
];

export const LotteryHLCompanyConst = [
  {
    lotteryUrl: "/images/lotteryCompany/gdlotto.png",
    lotteryCode: "H",
    lotteryKeyCode: "8",
  },
  // {
  //   lotteryUrl: "/images/lotteryCompany/nineLotto.png",
  //   lotteryCode: "N",
  //   lotteryKeyCode: "9",
  // },
];
