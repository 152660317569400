import { useIntl } from "react-intl";
import { LoginTab } from "../../enum/LoginTab";
import { LanguageList } from "../../../common/components/LanguageList";
import { toAbsoluteUrl } from "../../../../utils/CommonHelper";

export const RegisterMethodTab = ({ setLoginTab }) => {
  const intl = useIntl();

  return (
    <>
      <h3 className="mb-5 text-center">
        {intl.formatMessage({ id: "registerMethod" })}
      </h3>
      <button
        type="button"
        className="btn btn-custom-light position-relative fw-bold mt-5 w-100"
        onClick={() => setLoginTab(LoginTab.GOOGLE_REGISTER)}
      >
        <img
          src={toAbsoluteUrl("images/button/google.png")}
          alt="google"
          className="position-absolute top-50 translate-middle"
          style={{ width: "20px", height: "20px", left: "2rem" }}
        />
        {intl.formatMessage({ id: "registerWithGoogle" })}
      </button>
      <div className="divider my-4 w-100">
        {intl.formatMessage({ id: "orRegisterWith" })}
      </div>
      <button
        type="button"
        className="btn btn-custom-light position-relative fw-bold w-100 mb-4"
        onClick={() => setLoginTab(LoginTab.REGISTER_PART_1)}
      >
        <i
          className="position-absolute top-50 translate-middle fa fa-phone fs-4"
          style={{ width: "20px", height: "20px", left: "2rem" }}
        />
        {intl.formatMessage({ id: "registerWithPhoneNumber" })}
      </button>
      <LanguageList />
      <div className="fw-bold d-flex justify-content-center mt-4">
        <div className="me-2">
          {intl.formatMessage({ id: "haveAccount" })} ?
        </div>
        <div
          className="text-decoration-none text-prim cursor-pointer"
          onClick={() => {
            setLoginTab(LoginTab.LOGIN);
          }}
        >
          {intl.formatMessage({ id: "signIn" })}
        </div>
      </div>
    </>
  );
};
