import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/LotteryAction";
import SweetAlert2 from "react-sweetalert2";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";
import { Overlay } from "../../../common/components/Overlay";
import Skeleton from "react-loading-skeleton";
import { DateToDateString, GetBeforeDate } from "../../../../utils/DateHelper";
import { LotteryDetail } from "./lotteryDetail/LotteryDetail";

export const LotteryList = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [dateType, setDateType] = useState(DateToDateString(new Date()));
  const [zIndex, setZIndex] = useState(1);
  const [swalProps, setSwalProps] = useState({});
  const [detailId, setDetailId] = useState({
    orderid: undefined,
    refid: undefined,
  });

  const lotteryState = useSelector(({ lottery }) => lottery, shallowEqual);

  useEffect(() => {
    dispatch(actions.getLotteryList({ buydate: dateType }));
  }, [dateType]);

  return (
    <>
      <LotteryDetail
        dateType={dateType}
        orderid={detailId.orderid}
        refid={detailId.refid}
        setZIndex={setZIndex}
        setSwalProps={setSwalProps}
      />
      <SweetAlert2
        backdrop={false}
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
        customClass="custom-swal"
      />
      {(swalProps.show || lotteryState.actionsLoading) && (
        <Overlay zIndex={zIndex} />
      )}
      <div className="col-3">
        <select
          className="form-select"
          value={dateType}
          onChange={(e) => setDateType(e.target.value)}
        >
          <option value={DateToDateString(new Date())}>
            {DateToDateString(new Date())}
          </option>
          <option value={DateToDateString(GetBeforeDate(1))}>
            {DateToDateString(GetBeforeDate(1))}
          </option>
          <option value={DateToDateString(GetBeforeDate(2))}>
            {DateToDateString(GetBeforeDate(2))}
          </option>
          <option value={DateToDateString(GetBeforeDate(3))}>
            {DateToDateString(GetBeforeDate(3))}
          </option>
          <option value={DateToDateString(GetBeforeDate(4))}>
            {DateToDateString(GetBeforeDate(4))}
          </option>
          <option value={DateToDateString(GetBeforeDate(5))}>
            {DateToDateString(GetBeforeDate(5))}
          </option>
          <option value={DateToDateString(GetBeforeDate(6))}>
            {DateToDateString(GetBeforeDate(6))}
          </option>
        </select>
        <label className="mb-4 ms-1 small text-muted">
          {intl.formatMessage({ id: "date" })}
        </label>
      </div>
      {lotteryState.listLoading || !lotteryState.lotteryList ? (
        <Skeleton className="w-100" style={{ height: "500px" }} />
      ) : (
        <>
          {lotteryState.lotteryList.vBossSlipLists.length > 0 ? (
            lotteryState.lotteryList.vBossSlipLists.map((vBossSlip, index) => (
              <div
                className="card cursor-pointer mb-2"
                key={index}
                onClick={() => {
                  setDetailId({
                    refid: vBossSlip.refid,
                    orderid: vBossSlip.orderid,
                  });
                }}
                data-bs-toggle="modal"
                data-bs-target="#lotterySlip"
              >
                <div
                  className="card-body d-flex align-items-center justify-content-between lottery-slip-item"
                  style={{ background: "rgba(0,0,0,0.2)" }}
                >
                  <div>
                    <div className="text-muted">
                      {intl.formatMessage({ id: "orderId" })} :
                    </div>
                    <div
                      className="fw-bold fs-5 mb-2"
                      style={{ color: "#00ff63" }}
                    >
                      {vBossSlip.orderid}
                    </div>
                    <div className="d-flex">
                      <div className="me-3 d-flex">
                        <div className="text-muted me-2">
                          {intl.formatMessage({ id: "totalBet" })} :
                        </div>
                        <div>{vBossSlip.totalbet}</div>
                      </div>
                      <div className="d-flex">
                        <div className="text-muted me-2">
                          {intl.formatMessage({ id: "referenceId" })} :
                        </div>
                        <div>{vBossSlip.refid}</div>
                      </div>
                    </div>
                  </div>
                  <i
                    className="fa fa-angle-right fs-1 cursor-pointer"
                    title={intl.formatMessage({ id: "detail" })}
                  />
                </div>
              </div>
            ))
          ) : (
            <div className="d-flex py-5 align-items-center justify-content-center">
              <div className="text-muted d-flex align-items-center justify-content-center flex-column">
                <i className="bi bi-search fs-1 mb-3" />
                <div>{intl.formatMessage({ id: "noRecordFound" })}</div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
