import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import SweetAlert2 from "react-sweetalert2";
import {
  PaymentMethod,
  PaymentMethodNavConst,
} from "../../../../../constants/DepositNavConst";
import { toAbsoluteUrl } from "../../../../../utils/CommonHelper";
import { DepositAtmDialog } from "./atmDeposit/DepositAtmDialog";
import { DepositGatewayDialog } from "./gatewayDeposit/DepositGatewayDialog";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Overlay } from "../../../../common/components/Overlay";
import Skeleton from "react-loading-skeleton";
import * as actions from "../../../redux/SettingAction";
import { ApiStatusTypeEnum } from "../../../../../enumeration/ApiStatusTypeEnum";
import { TelcoDepositDialog } from "./telcoDeposit/TelcoDepositDialog";

export const DepositDialog = ({ companyCode, dismissBtnRef }) => {
  const [swalProps, setSwalProps] = useState({});
  const [paymentMethod, setPaymentMethod] = useState(PaymentMethod.ATM);
  const intl = useIntl();
  const dispatch = useDispatch();

  const settingState = useSelector(({ setting }) => setting, shallowEqual);

  useEffect(() => {
    dispatch(actions.getDepositWalletUI()).then((res) => {
      const data = res.data;
      if (parseInt(data.code) === ApiStatusTypeEnum.SUCCESS) {
        setPaymentMethod(
          data.atmshow === "true"
            ? PaymentMethod.ATM
            : PaymentMethod.PAYMENT_GATEWAY
        );
      }
    });
  }, []);

  return (
    <>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      {settingState.actionsLoading && <Overlay />}
      <div className="text-input-label mb-2">
        {intl.formatMessage({ id: "paymentMethod" })}
      </div>
      <div className="d-flex mb-4">
        {PaymentMethodNavConst.map((nav, index) =>
          settingState.depositWalletUI &&
          ((nav.title === PaymentMethod.ATM &&
            settingState.depositWalletUI.atmshow === "true") ||
            (nav.title === PaymentMethod.PAYMENT_GATEWAY &&
              settingState.depositWalletUI.gatewayshow === "true") ||
            (nav.title === PaymentMethod.TELCO &&
              settingState.depositWalletUI.telcoshow === "true")) ? (
            <div key={nav.title} className="card cursor-pointer me-4">
              <div
                className="card-body"
                style={{ background: "rgba(0,0,0,0.2)", width: "200px" }}
                onClick={() => {
                  setPaymentMethod(nav.title);
                }}
              >
                <div
                  className={`d-flex flex-column align-items-center menu-item ${
                    paymentMethod === nav.title ? "show" : ""
                  }`}
                >
                  {nav.title === PaymentMethod.TELCO ? (
                    <img
                      src={toAbsoluteUrl(nav.image)}
                      style={{ width: "40px", height: "40px" }}
                    />
                  ) : (
                    <span
                      className="menu-icon main-menu-image"
                      style={{
                        WebkitMaskImage: `url(${toAbsoluteUrl(nav.image)})`,
                        maskImage: `url(${toAbsoluteUrl(nav.image)})`,
                      }}
                    >
                      <div className="lg" />
                    </span>
                  )}

                  <div className="ws-nowrap mt-1">
                    {intl.formatMessage({ id: nav.title })}
                  </div>
                </div>
              </div>
            </div>
          ) : null
        )}
      </div>
      {settingState.depositWalletUI ? (
        paymentMethod === PaymentMethod.ATM ? (
          <DepositAtmDialog
            setSwalProps={setSwalProps}
            companyCode={companyCode}
            dismissBtnRef={dismissBtnRef}
          />
        ) : paymentMethod === PaymentMethod.TELCO ? (
          <TelcoDepositDialog
            setSwalProps={setSwalProps}
            companyCode={companyCode}
            dismissBtnRef={dismissBtnRef}
          />
        ) : (
          <DepositGatewayDialog
            setSwalProps={setSwalProps}
            swalProps={swalProps}
            dismissBtnRef={dismissBtnRef}
          />
        )
      ) : (
        <Skeleton className="w-100" style={{ height: "500px" }} />
      )}
    </>
  );
};
