import { Field, Form, Formik } from "formik";
import { editPasswordSchema } from "../../yupSchema/editPasswordSchema";
import { useIntl } from "react-intl";
import { Input } from "../../../common/components/control/InputField";
import { IsAlphaNumeric } from "../../../../utils/CommonHelper";

export const EditPasswordForm = ({ formikRef, saveValues }) => {
  const intl = useIntl();

  return (
    <Formik
      initialValues={{
        newPassword: "",
        confirmPassword: "",
      }}
      innerRef={formikRef}
      validationSchema={editPasswordSchema(intl)}
      enableReinitialize={true}
      onSubmit={(values, formikHelpers) => {
        if (values.newPassword === values.confirmPassword) {
          let queryParams = {
            newpassword: values.newPassword,
          };
          saveValues(queryParams);
        } else {
          formikHelpers.setFieldError(
            "confirmPassword",
            intl.formatMessage({ id: "invalidConfirmPassword" })
          );
        }
      }}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        touched,
        errors,
        setFieldError,
      }) => (
        <Form>
          <div className="mb-2">
            <Field
              name="newPassword"
              component={Input}
              placeholder={intl.formatMessage({ id: "newPassword" })}
              label={intl.formatMessage({ id: "newPassword" })}
              autoComplete="off"
              type="password"
              onKeyDown={(e) => {
                if (!IsAlphaNumeric(e)) {
                  e.preventDefault();
                }
              }}
            />
          </div>
          <div className="mb-2">
            <Field
              name="confirmPassword"
              component={Input}
              placeholder={intl.formatMessage({ id: "confirmPassword" })}
              label={intl.formatMessage({ id: "confirmPassword" })}
              autoComplete="off"
              type="password"
              onKeyDown={(e) => {
                if (!IsAlphaNumeric(e)) {
                  e.preventDefault();
                }
              }}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
