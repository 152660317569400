import { createSlice } from "@reduxjs/toolkit";
import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";

const lotteryState = {
  lotteryResultUI: null,
  lotteryList: null,
  companyGameBalance: null,
  lotteryDetail: null,
  lotteryHistory: null,
  lotteryHistoryDetail: null,
  lotteryPrizeUI: null,
  manageResponse: null,
  error: null,
  listLoading: false,
  actionsLoading: false,
  searchLoading: false,
  isRefresh: false,
};

export const lotterySlice = createSlice({
  name: "lottery",
  initialState: lotteryState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = false;
      }
    },
    throwError: (state, action) => {
      state.error = action.payload.data.error;
    },
    startCall: (state, action) => {
      state.isRefresh = false;
      state.error = null;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = true;
      }
    },
    clearError: (state) => {
      state.error = null;
    },
    LotteryResultUIFetched: (state, action) => {
      state.listLoading = false;
      state.lotteryResultUI = action.payload.data ?? {};
    },
    companyGameBalanceFetched: (state, action) => {
      state.searchLoading = false;
      state.companyGameBalance = action.payload.data ?? {};
    },
    LotteryPrizeUIFetched: (state, action) => {
      state.listLoading = false;
      state.lotteryPrizeUI = action.payload.data ?? {};
    },
    LotteryUIFetched: (state, action) => {
      state.listLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    LotteryListFetched: (state, action) => {
      state.listLoading = false;
      state.lotteryList = action.payload.data ?? {};
    },
    LotteryDetailFetched: (state, action) => {
      state.actionsLoading = false;
      state.lotteryDetail = action.payload.data ?? {};
    },
    LotteryHistoryFetched: (state, action) => {
      state.listLoading = false;
      state.lotteryHistory = action.payload.data ?? {};
    },
    LotteryHistoryDetailFetched: (state, action) => {
      state.actionsLoading = false;
      state.lotteryHistoryDetail = action.payload.data ?? {};
    },
    LotteryBought: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    LotteryVoided: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    LotteryReordered: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
  },
});
