import { useIntl } from "react-intl";
import { LoginTab } from "../../enum/LoginTab";
import { Field, Form, Formik } from "formik";
import {
  ErrorContainer,
  Input,
} from "../../../common/components/control/InputField";
import { useEffect, useRef, useState } from "react";
import {
  fetchCurrencyList,
  fetchIPAddress,
  getCurrentCountryCode,
  googleRegister,
} from "../../redux/LoginCRUD";
import { GoogleRegisterSchema } from "../../yupSchema/registerSchema";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { firebaseConfig } from "../../../../setup/FirebaseConfig";
import { useAuth } from "../AuthInit";
import { toAbsoluteUrl } from "../../../../utils/CommonHelper";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

export const GoogleRegisterTab = ({ setLoginTab, setSwalProps, loginTab }) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingCurrency, setIsFetchingCurrency] = useState(true);
  const [isShowCurrencyError, setIsShowCurrencyError] = useState(false);
  const [currencyLists, setCurrencyLists] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const { saveAuth, setCurrentUser } = useAuth();
  const formikRef = useRef();
  const longitude = useRef("");
  const latitude = useRef("");
  const ipAddress = useRef("");
  const { refferalCode } = useParams();

  useEffect(() => {
    fetchCurrencyList({ language: intl.locale }).then((resp) => {
      setCurrencyLists(resp.currencyLists);
      fetchIPAddress().then((res) => {
        ipAddress.current = res.ip;
        getCurrentCountryCode(res.ip).then((re) => {
          const currencyCode = resp.currencyLists.find(
            (currency) => currency.currencycode === re.currency
          );
          if (currencyCode) {
            setSelectedCurrency(currencyCode);
          } else {
            setSelectedCurrency(resp.currencyLists[0]);
          }
          setIsFetchingCurrency(false);
          setIsLoading(false);
        });
      });
    });
  }, []);

  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(function (position) {
  //     longitude.current = position.coords.longitude;
  //     latitude.current = position.coords.latitude;
  //   });
  // }, []);

  return (
    <>
      <h3 className="mb-5 d-flex align-items-center justify-content-center">
        <img
          src={toAbsoluteUrl("images/button/google.png")}
          alt="google"
          className="me-2 mt-1"
          style={{ width: "20px", height: "20px" }}
        />
        {intl.formatMessage({ id: "registerWithGoogle" })}
      </h3>
      <Formik
        initialValues={{
          referralCode: refferalCode ?? "",
        }}
        innerRef={formikRef}
        validationSchema={GoogleRegisterSchema(intl)}
        enableReinitialize={true}
        onSubmit={(values, formikHelpers) => {
          if (selectedCurrency) {
            setIsShowCurrencyError(false);
            setIsLoading(true);
            const app = initializeApp(firebaseConfig);
            const auth = getAuth(app);
            const provider = new GoogleAuthProvider();
            provider.addScope(
              "https://www.googleapis.com/auth/userinfo.profile"
            );
            signInWithPopup(auth, provider)
              .then((result) => {
                const user = result.user;

                let queryParams = {
                  email: user.email,
                  referrerid: values.referralCode,
                  mediaplatform: "gmail",
                  currencycode: selectedCurrency.currencycode,
                  language: intl.locale,
                  uid: user.uid,
                  fcmnotificationid: "",
                  latitude: latitude.current,
                  longitude: longitude.current,
                  ipaddress: ipAddress.current,
                };

                googleRegister(queryParams).then((resp) => {
                  setIsLoading(false);
                  if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
                    setSwalProps({
                      show: true,
                      icon: "success",
                      title: intl.formatMessage({ id: "success" }),
                      html: intl
                        .formatMessage(
                          { id: "emailRegisterSuccess" },
                          { x: user.email }
                        )
                        .replaceAll("{x}", user.email),
                      onConfirm: () => {
                        saveAuth(resp);
                        setCurrentUser(resp);
                        setLoginTab(LoginTab.LOGIN);
                      },
                    });
                  } else {
                    setSwalProps({
                      show: true,
                      icon: "error",
                      title: intl.formatMessage({ id: "error" }),
                      text: resp.message,
                    });
                  }
                });
              })
              .catch((error) => {
                setIsLoading(false);
                setSwalProps({
                  show: true,
                  icon: "error",
                  title: intl.formatMessage({ id: "error" }),
                  text: intl.formatMessage({ id: "failToRegister" }),
                });
              });
          } else {
            setIsShowCurrencyError(true);
          }
        }}
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          touched,
          errors,
          setFieldError,
        }) => (
          <Form>
            <div className="mb-2">
              <Field
                name="referralCode"
                component={Input}
                placeholder={intl.formatMessage({ id: "referralCode" })}
                label={intl.formatMessage({ id: "referralCode" })}
                readOnly={refferalCode ? true : false}
                autoComplete="off"
              />
            </div>
            <div className="mb-4">
              <label className="mb-2 text-input-label">
                {intl.formatMessage({ id: "currency" })}
              </label>
              {!isFetchingCurrency ? (
                <>
                  <div className="dropdown">
                    <button
                      className={`form-control text-start d-flex align-items-center ${
                        selectedCurrency ? "" : "text-placeholder"
                      }`}
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-text={intl.formatMessage({ id: "currency" })}
                    >
                      {selectedCurrency ? (
                        <>
                          <img
                            src={selectedCurrency.imageurl}
                            alt={selectedCurrency.currencycode}
                            className="me-4 currency-icon"
                          />
                          <div>{selectedCurrency.currencyname}</div>
                        </>
                      ) : (
                        intl.formatMessage({ id: "currency" })
                      )}
                    </button>
                    <div className="dropdown-menu w-100">
                      {currencyLists.map((currency) => (
                        <div
                          className={`dropdown-item d-flex align-items-center py-2 cursor-pointer ${
                            selectedCurrency &&
                            selectedCurrency.currencycode ===
                              currency.currencycode
                              ? "active"
                              : ""
                          }`}
                          key={currency.currencycode}
                          onClick={() => {
                            setSelectedCurrency(currency);
                            document
                              .querySelector(".dropdown-menu")
                              .classList.remove("show");
                          }}
                        >
                          <img
                            src={currency.imageurl}
                            alt={currency.currencycode}
                            className="me-4 currency-icon"
                          />
                          <div>{currency.currencyname}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <ErrorContainer
                    isShow={isShowCurrencyError}
                    errorMsg={intl.formatMessage({ id: "currencyRequired" })}
                  />
                </>
              ) : (
                <Skeleton className="w-100" height="35px" />
              )}
            </div>
            <div className="mt-4 mb-2">
              <button
                type="submit"
                disabled={isLoading}
                className="btn btn-prim fw-bold w-100"
              >
                <div className="d-flex align-items-center justify-content-center">
                  {isLoading && (
                    <div
                      className="spinner-border text-dark"
                      role="status"
                      style={{ scale: "0.75" }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                  {intl.formatMessage({ id: "submit" })}
                </div>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
