/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes } from "react-router-dom";
import { createContext } from "react";
import { GamePlayListing } from "../../modules/gamePlay/component/GamePlayListing";

const GamePlayContext = createContext();

const GamePlayWrapper = () => {
  const value = {};

  return (
    <GamePlayContext.Provider value={value}>
      <Routes>
        <Route
          path="/:companyCode/:gameCode/:companyOffset/:scrollPosition/:gameType/:searchName"
          element={<GamePlayListing />}
        />
        <Route
          path="/:companyCode/:gameCode/:companyOffset/:scrollPosition/:gameType"
          element={<GamePlayListing />}
        />
      </Routes>
    </GamePlayContext.Provider>
  );
};

export { GamePlayWrapper };
